import ChangeSubscription from "@meetin/components/src/subscription/ChangeSubscription";
import { CircularProgress, Stack, Typography } from "@meetin/uicore";
import { AppContext } from "modules/app/AppProvider";
import { useContext } from "react";

const Pricing = () => {
  const { supabaseClient, user } = useContext(AppContext);

  return (
    <Stack>
      <Typography variant="h4">Pricing</Typography>

      {!supabaseClient ? (
        <CircularProgress />
      ) : user ? (
        <ChangeSubscription />
      ) : (
        <Typography>Please wait...</Typography>
      )}
    </Stack>
  );
};

export default Pricing;
