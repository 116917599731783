import { SVGProps } from "react";

const RadioSelected = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="16" height="16" rx="8" fill="#ED1B06" />
    <circle cx="8" cy="8" r="4" fill="white" />
  </svg>
);

export default RadioSelected;
