import { SupabaseClientType } from "@meetin/shared";
import { Database } from "@meetin/supabase";
import { createClient, SupabaseClientOptions } from "@supabase/supabase-js";
import { clientLogger } from "../logger";

type InitProps = {
  supabaseUrl: string;
  key: string;
  options?: SupabaseClientOptions<"public">;
  access_token: string | null;
};
type Props = {
  client: SupabaseClientType | null;
  initializeClient: (props: InitProps) => SupabaseClientType;
  getSupabaseClient: () => NonNullable<SupabaseClientType>;
  destroy: () => void;
};

/**
 * Helper utility to store singleton supabase instance
 */
export const SupabaseClientHelper: Props = {
  client: null,
  initializeClient: ({
    supabaseUrl,
    key,
    options,
    access_token,
  }: InitProps) => {
    if (SupabaseClientHelper.client) {
      clientLogger.info("client already initialized");
      return SupabaseClientHelper.client;
    }

    const client = createClient<Database>(supabaseUrl, key, options);
    client.realtime.setAuth(access_token);

    SupabaseClientHelper.client = client;
    return client;
  },
  destroy: () => {
    SupabaseClientHelper.client = null;
  },
  getSupabaseClient: () => {
    if (SupabaseClientHelper.client) {
      return SupabaseClientHelper.client;
    }

    throw new Error("Supabase client not initialized");
  },
};
