import { ListItem, ListItemText, styled } from "@meetin/uicore";
import { useCallback, useEffect } from "react";
import { highlightText } from "../../ai/askLayer/utils";
import { KeyPointNumberIcon } from "./KeyPointNumberIcon";
import { trackEvent, AnalyticsEvents } from "../../analytics";
import { isSidePanel, sendMessageToCurrentTab } from "../../app";
import { Tooltip, ZIndex } from "../../common";

type Props = {
  text: string;
  index: number;
  showIndex?: boolean;
  disableDefaultHighlight?: boolean;
  disableIndex?: boolean;
};

const HightlightItem = styled(ListItem)`
  position: relative;
  cursor: pointer;
  &:hover {
    color: rgba(21, 111, 238, 1);
  }

  mark {
    background-color: initial;
  }
  .MuiListItemText-secondary {
    position: absolute;
    color: rgba(0, 5, 15, 0.85);
    border: 1px solid rgba(0, 5, 15, 0.85);
    border-radius: 50%;
    left: 0;
    top: 6px;
    width: 1rem;
    text-align: center;
  }
`;
const KeyPointRow = ({
  text,
  index,
  showIndex,
  disableDefaultHighlight,
  disableIndex,
}: Props): JSX.Element => {
  const triggerHighlight = useCallback(() => {
    if (!text) {
      return;
    }
    if (isSidePanel()) {
      sendMessageToCurrentTab({
        type: "HIGHLIGHT_TEXT",
        message: {
          text,
        },
        from: 0,
      });
    } else {
      highlightText({ text });
    }
  }, [text]);

  const onClick = () => {
    triggerHighlight();
    trackEvent(AnalyticsEvents.SOURCE_CLICKED);
  };

  useEffect(() => {
    if (!disableDefaultHighlight) {
      // NO need for auto highlight to avoid unwanted scrolling
      // triggerHighlight();
    }
  }, [disableDefaultHighlight, triggerHighlight]);

  return (
    <HightlightItem
      onClick={onClick}
      sx={{ "& mark": {}, display: showIndex ? "list-item" : "flex" }}
    >
      {showIndex ? (
        <Tooltip title={text} zIndex={ZIndex + 2}>
          <ListItemText primary={<KeyPointNumberIcon number={index + 1} />} />
        </Tooltip>
      ) : (
        <ListItemText
          primaryTypographyProps={{
            sx: {
              whiteSpace: "pre-wrap",
              fontSize: "16px",
              lineHeight: 1.4,
              fontWeight: 400,
            },
          }}
          primary={
            <>
              &nbsp;&bull;&nbsp; {text}{" "}
              {!disableIndex ? <KeyPointNumberIcon number={index + 1} /> : null}
            </>
          }
        />
      )}
    </HightlightItem>
  );
};

export default KeyPointRow;
