import { isExtension } from "../common/utils";
import { AnalyticsEvents } from "./events";

export const trackEvent = (
  eventType: AnalyticsEvents,
  properties?: Record<string, string>
) => {
  if (!isExtension()) {
    return;
  }
  chrome.runtime?.sendMessage({
    type: "ANALYTICS_TRACK_EVENT",
    message: {
      eventType,
      properties: {
        ...properties,
        current_url: window.location.href,
        current_url_hostname: window.location.hostname,
        current_url_pathname: window.location.pathname,
      },
    },
  });
};

export const reset = () => {
  if (!isExtension()) {
    return;
  }
  chrome.runtime?.sendMessage({
    type: "ANALYTICS_RESET",
  });
};
