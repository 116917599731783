import { yupResolver } from "@hookform/resolvers/yup";
import { Post, rtkApi } from "@meetin/shared";
import {
  StyleMentionInput,
  LoadingButton,
  showErrorNotification,
  Box,
  Button,
} from "@meetin/uicore";
import * as Yup from "yup";
import { ReplyIcon } from "@meetin/uicore/icons";
import { KeyboardEvent as ReactKeyboardEvent, useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import { MentionsInput, Mention } from "react-mentions";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";
import { clientLogger } from "../../logger";
import { selectMembers, selectChannels } from "../../slack";
import { SupabaseClientHelper } from "../../supabase";
import { formatTags, hasChannelTag, hasUserTag } from "../utils";
import { trackEvent, AnalyticsEvents } from "../../analytics";
import { ComponentsContext } from "../../common";

interface Props {
  postId: string;
  showReplyForm: boolean;
  setShowReplyForm: (show: boolean) => void;
}

type NewReplyInputs = {
  comment: string;
};

const ReplyBox = styled(Box)({
  padding: "10px",
  backgroundColor: "rgba(0, 10, 20, 0.02)",
  borderRadius: "8px",
  boxShadow: " inset 0px 0px 8px rgba(0, 0, 0, 0.1)",
  border: ".5px solid rgba(0, 0, 0, 0.01)",
});

const schema = Yup.object({
  comment: Yup.string().required("Reply text is needed"),
}).required();

const ReplyForm = ({
  showReplyForm,
  setShowReplyForm,
  postId,
}: Props): JSX.Element | null => {
  const { subscriptionsEnabled } = useContext(ComponentsContext);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid },
  } = useForm<NewReplyInputs>({
    defaultValues: { comment: "" },
    resolver: yupResolver(schema),
  });

  const members = useSelector(selectMembers);
  const channels = useSelector(selectChannels);

  const onAdd = (id: string | number, display: string) => {
    clientLogger.info(`[replybutton] on mention select`, { id, display });
  };
  const onFormSubmit = async (formData: NewReplyInputs) => {
    try {
      const supabaseClient = SupabaseClientHelper.getSupabaseClient();

      const data = {
        parent: postId,
        type: "Comment",
        text: formatTags(formData.comment),
      };

      const { data: replyData, error } = await supabaseClient.functions.invoke<{
        post: Post;
      }>("create-post", {
        body: data,
      });

      if (error) {
        throw new Error(error.message);
      }
      if (!replyData) {
        throw new Error("Invalid response");
      }

      trackEvent(AnalyticsEvents.COMMENT_REPLY_ADDED, {
        parent: `${replyData.post.parent}`,
      });
      if (!subscriptionsEnabled) {
        dispatch(rtkApi.util.invalidateTags([{ type: "Replies", id: postId }]));
      }
      if (hasChannelTag(replyData.post.text)) {
        trackEvent(AnalyticsEvents.TAGGED_CHANNEL_COMMENT_REPLY_ADDED, {
          parent: `${replyData.post.parent}`,
        });
      }
      if (hasUserTag(replyData.post.text)) {
        trackEvent(AnalyticsEvents.TAGGED_USER_COMMENT_REPLY_ADDED, {
          parent: `${replyData.post.parent}`,
        });
      }

      reset();
      setShowReplyForm(false);
    } catch (err) {
      clientLogger.error("reply error", { postId }, err as Error);
      showErrorNotification({ message: `${(err as Error).message}` });
    }
  };

  const onKeyDown = (
    e:
      | ReactKeyboardEvent<HTMLInputElement>
      | ReactKeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === "Enter") {
      if (!e.shiftKey) {
        e.preventDefault();
        handleSubmit((data) => onFormSubmit(data))();
      }
    }
  };

  if (!showReplyForm) {
    return null;
  }

  return (
    <ReplyBox>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Controller
          name="comment"
          control={control}
          render={({ field }) => (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <MentionsInput
              {...field}
              style={{
                ...StyleMentionInput,
                minHeight: "40px",
                marginBottom: "10px",
              }}
              placeholder="Type your reply here..."
              className="new-post"
              onKeyDown={onKeyDown}
            >
              <Mention
                displayTransform={(id, display) => `@${display}`}
                trigger="@"
                data={Object.values(members)}
                appendSpaceOnAdd
                renderSuggestion={(
                  suggestion,
                  search,
                  highlightedDisplay,
                  index,
                  focused
                ) => (
                  <div className={`user ${focused ? "focused" : ""}`}>
                    {highlightedDisplay}
                  </div>
                )}
                onAdd={onAdd}
              />
              <Mention
                appendSpaceOnAdd
                displayTransform={(id, display) => `#${display}`}
                markup="#[__display__](__id__)"
                trigger="#"
                data={Object.values(channels)}
                onAdd={onAdd}
                style={{ backgroundColor: "#d1c4e9" }}
              />
            </MentionsInput>
          )}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            variant="text"
            onClick={() => setShowReplyForm(false)}
            sx={{ mr: 1 }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isSubmitting}
            type="submit"
            size="small"
            variant="contained"
            disabled={!isValid}
            endIcon={<ReplyIcon style={{ width: "14px", opacity: "60%" }} />}
          >
            Send
          </LoadingButton>
        </Box>
      </form>
    </ReplyBox>
  );
};

export default ReplyForm;
