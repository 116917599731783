import { Typography, Box } from "@meetin/uicore";
import { useMemo, ComponentProps } from "react";
import { useSelector } from "react-redux";
import { selectChannels, selectMembers } from "../slack";

type Props = {
  content?: string | null;
  overrides?: Partial<ComponentProps<typeof Typography>>;
};

const PostContent = ({ content, overrides }: Props): JSX.Element => {
  const members = useSelector(selectMembers);
  const channels = useSelector(selectChannels);

  const formattedContent = useMemo(() => {
    const tags = content?.match(/(?<=<)(.*?)(?=>)/gs);
    let str = content;
    tags?.forEach((tag) => {
      if (tag.startsWith("@")) {
        const member = members[tag.replace("@", "")];
        if (member) {
          str = str?.replace(`<${tag}>`, `@${member.name || member.id}`);
        }
      }
      if (tag.startsWith("#")) {
        const channel = channels[tag.replace("#", "")];
        if (channel) {
          str = str?.replace(`<${tag}>`, `#${channel.name || channel.id}`);
        }
      }
    });
    return str;
  }, [content, channels, members]);

  const { sx, ...rest } = overrides || {};

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{ whiteSpace: "pre-wrap", ...sx }}
        {...rest}
      >
        {formattedContent}
      </Typography>
    </Box>
  );
};

export default PostContent;
