import { ConversationsListResponse, UsersListResponse } from "@slack/web-api";
import { rtkApi, SlackChannel, SlackMember } from "@meetin/shared";
import { slackPostRequest } from "../api";
import { clientLogger } from "../../logger";

type SlackDataLoaderQueryProps = {
  token: string | null;
  apiUrl: string;
};
const slackApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getSlackMembers: builder.query<
      SlackMember[] | null,
      SlackDataLoaderQueryProps
    >({
      queryFn: async ({ token, apiUrl }: SlackDataLoaderQueryProps) => {
        if (!token || !apiUrl) {
          return { data: null };
        }

        clientLogger.info("loading slack members");
        const res: UsersListResponse = await slackPostRequest(apiUrl, token, {
          paths: "users.list",
          query: { limit: 1000 },
        });
        if (!res.members) {
          return { data: null };
        }
        const filteredMembers = res.members
          .filter((member) => member.id && !member.deleted && !member.is_bot)
          .map((member) => ({ ...member, display: member.real_name }));
        return { data: filteredMembers as SlackMember[] };
      },
    }),
    getSlackChannels: builder.query<
      SlackChannel[] | null,
      SlackDataLoaderQueryProps
    >({
      queryFn: async ({ token, apiUrl }: SlackDataLoaderQueryProps) => {
        if (!token || !apiUrl) {
          return { data: null };
        }

        clientLogger.info("loading slack channels");
        const res: ConversationsListResponse = await slackPostRequest(
          apiUrl,
          token,
          {
            paths: "conversations.list",
            query: {
              exclude_archived: true,
              types: "public_channel,private_channel",
              limit: 1000,
            },
          }
        );
        if (!res.channels) {
          return { data: null };
        }
        const filteredChannels = res.channels
          .filter((channel) => channel.id)
          .map((channel) => ({ ...channel, display: channel.name }));
        return { data: filteredChannels as SlackChannel[] };
      },
    }),
  }),
});

export const {
  useLazyGetSlackChannelsQuery,
  useGetSlackMembersQuery,
  useLazyGetSlackMembersQuery,
  useGetSlackChannelsQuery,
} = slackApi;
