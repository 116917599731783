import { PageOGDetails } from "@meetin/shared";
import getFavIcon from "./getFavIcon";
import { getUrlWithoutHash } from "../utils";

export const getOGData = (): Partial<PageOGDetails> => {
  const ogTags = document.querySelectorAll('meta[property^="og:"]');

  const data: Partial<PageOGDetails> = {
    favIcon: getFavIcon(document),
    title: document.querySelector("title")?.innerText || "",
  };
  ogTags.forEach((tag) => {
    const property = tag.getAttribute("property");
    const content = tag.getAttribute("content");

    if (property && content) {
      // @ts-expect-error valid string
      data[property.replace("og:", "")] = content;
    }
  });

  data.url = getUrlWithoutHash(document.location.href);

  return data;
};

export const requestOGData = async (
  activeTabId: number
): Promise<Partial<PageOGDetails>> => {
  const response = await chrome.tabs.sendMessage(activeTabId, {
    type: "SIDEPANEL_REQUEST_OG_DATA",
    from: 0,
  });

  return response?.ogData || {};
};

export const getOGDataFromTab = async () => {
  // if this is called from content script
  if (!chrome.tabs) {
    return getOGData();
  }

  // if called from sidepanel
  const tabs = await chrome.tabs.query({ active: true, currentWindow: true });
  const activeTab = tabs[0];

  if (activeTab?.id) {
    return requestOGData(activeTab.id);
  }

  return {};
};
