import { useMemo } from "react";
import { Box, Grid } from "@meetin/uicore";
import { PostWithCollections } from "@meetin/shared";
import PostComponent from "../PostComponent";
import usePostsSubscriptions from "../hooks/usePostsSubscriptions";

type Props = {
  posts?: PostWithCollections[] | null;
} & Omit<Parameters<typeof PostComponent>["0"], "post">;

const PostsList = ({ posts, ...rest }: Props): JSX.Element | null => {
  const postIds = useMemo(
    () =>
      posts
        ?.map((p) => (p ? p.id : null))
        .filter((p) => p !== null)
        .join(",") || "",
    [posts]
  );
  usePostsSubscriptions(postIds);
  if (!posts?.length) {
    return <Box>Nothing to show!</Box>;
  }

  return (
    <Grid
      container
      sx={{
        overflow: "auto",
        flex: 1,
        gap: "10px",
        flexDirection: "column",
        flexWrap: "nowrap",
      }}
    >
      {posts.map((post) => {
        if (!post) {
          return null;
        }
        return <PostComponent post={post} key={post.id} hideShadow {...rest} />;
      })}
    </Grid>
  );
};

export default PostsList;
