import { AskLayerQueryType } from "../ai/askLayer/types";

export enum Tabs {
  PAGE_INSIGHTS = 0,
  NOTEBOOK = 1,
}

export interface UserPreferences {
  postsState?: boolean;
  activeCollectionId?: string;
  searchAllPages?: boolean;
  activeTab?: Tabs;
  askLayerSearchType?:
    | AskLayerQueryType.AI_SEARCH
    | AskLayerQueryType.AI_INITIAL_FINDINGS;
}

export type UserPreference = keyof UserPreferences;
