import { Post } from "@meetin/shared";
import { ListItem, styled, Stack } from "@meetin/uicore";
import { PostContent, PostAuthorInfo } from ".";

type StyledListItemProps = {
  isSelectedComment: boolean;
};
const ReplyListItem = styled(ListItem)<StyledListItemProps>(
  ({ theme, isSelectedComment }) => ({
    backgroundColor: isSelectedComment ? "rgba(255, 255, 255, 0.08)" : "",
    borderRadius: isSelectedComment ? 2 : 0,
    padding: theme.spacing(0.75, 0.75, 1.5),
    marginBottom: theme.spacing(0.75),
  })
);

type Props = {
  reply: Post;
  selectedCommentId?: Post["id"];
};

const Reply = ({ reply, selectedCommentId }: Props): JSX.Element => {
  const isSelectedComment = selectedCommentId === reply.id.toString();
  return (
    <ReplyListItem isSelectedComment={isSelectedComment}>
      <Stack spacing="6px" direction="column">
        <PostAuthorInfo post={reply} />
        <PostContent content={reply.text} overrides={{ ml: "30px" }} />
      </Stack>
    </ReplyListItem>
  );
};

export default Reply;
