import { useContext } from "react";
import { useSelector } from "react-redux";
import {
  selectAllowedQuestionsPerDay,
  selectAllowedQuestionsPerWeek,
  selectUserSubscription,
} from "./subscriptionSelectors";
import {
  useGetFeaturePerWeekUsageQuery,
  useGetFeatureUsageQuery,
  useGetServerDateQuery,
} from "./rtkSubscription";
import { ComponentsContext } from "../common";

const useSubscriptionLimits = () => {
  const allowedQuestionsLimit = useSelector(selectAllowedQuestionsPerDay);
  const allowedQuestionsPerWeekLimit = useSelector(
    selectAllowedQuestionsPerWeek
  );
  const userSubscription = useSelector(selectUserSubscription);
  const isQuestionsLimitByWeek =
    userSubscription.data?.subscription?.plan.settings.questions_per_week !==
    undefined;
  const { user } = useContext(ComponentsContext);
  const { data: currentDate } = useGetServerDateQuery(undefined, {
    skip: !user,
  });

  const { data } = useGetFeatureUsageQuery(
    { date: currentDate || "" },
    {
      skip: !user?.user_id || !currentDate,
    }
  );

  const { data: currentWeekUsageData } = useGetFeaturePerWeekUsageQuery(
    { date: currentDate || "" },
    {
      skip: !isQuestionsLimitByWeek || !user?.user_id || !currentDate,
    }
  );

  const usedQuestionsThisWeek =
    currentWeekUsageData?.reduce(
      (acc, curr) => acc + curr.questions_per_day,
      0
    ) || 0;
  const usedQuestionsToday = data?.questions_per_day || 0;

  const usedQuestions = isQuestionsLimitByWeek
    ? usedQuestionsThisWeek
    : usedQuestionsToday;

  const isQuestionsLimitReached = () => {
    if (isQuestionsLimitByWeek) {
      if (allowedQuestionsPerWeekLimit === -1) {
        return false;
      }
      return usedQuestionsThisWeek >= allowedQuestionsPerWeekLimit;
    }

    if (allowedQuestionsLimit === -1) {
      return false;
    }
    return allowedQuestionsLimit <= usedQuestionsToday;
  };

  return {
    isQuestionsLimitReached,
    isQuestionsLimitByWeek,
    allowedQuestionsLimit: isQuestionsLimitByWeek
      ? allowedQuestionsPerWeekLimit
      : allowedQuestionsLimit,
    usedQuestions,
  };
};

export default useSubscriptionLimits;
