import { Button } from "@meetin/uicore";

const ExtensionButton = (): JSX.Element => {
  return (
    <Button
      href="https://chrome.google.com/webstore/detail/layer/jpmmikeljmfjclheklkpdkaginppampj"
      target="_blank"
      rel="noreferrer"
      color="secondary"
      variant="contained"
      sx={{
        backgroundColor: "#1A1C20",
        display: "inline-block",
      }}
    >
      Download Layer
    </Button>
  );
};

export default ExtensionButton;
