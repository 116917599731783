import { showErrorNotification } from "@meetin/uicore";
import { useDispatch } from "react-redux";
import { clientLogger } from "../../logger";
import { useLazyGetRelevantLinksQuery } from "../rtkLinks";
import { setMessages } from "../../ai";

export const useRelevantLinks = () => {
  const dispatch = useDispatch();
  const [getLinks] = useLazyGetRelevantLinksQuery();

  const getRelevantLinks = async (
    requestId: string,
    topic: string,
    objectives: string
  ) => {
    const response = await getLinks({ topic, objectives });

    if ("error" in response) {
      clientLogger.error("[getRelevantLinks] error ", {
        error: response.error,
      });
      showErrorNotification({
        message: "Unable to get relevant links",
      });
      return;
    }

    if (!response.data) {
      clientLogger.error("[getRelevantLinks] missing data", {
        response,
      });
      showErrorNotification({
        message: "Unable to get relevant links",
      });
      return;
    }

    dispatch(
      setMessages({
        requestId,
        links: response.data.result,
      })
    );
  };

  return {
    getRelevantLinks,
  };
};
