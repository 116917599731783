import { CircularProgress } from "@meetin/uicore";
import { useContext } from "react";
import { AppContext } from "../app/AppProvider";
import { UnsortedPosts } from "@meetin/components";
import { useAppDispatch } from "store/hooks";

const UnsortedPostsView = () => {
  const { user } = useContext(AppContext);
  const dispatch = useAppDispatch();

  if (!user?.user_id) {
    return <CircularProgress size={20} />;
  }
  return (
    <UnsortedPosts userId={user.user_id} dispatch={dispatch} ignorePostClick />
  );
};

export default UnsortedPostsView;
