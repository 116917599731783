import { rtkApi } from "@meetin/shared";
import {
  postReducer,
  slackStateReducer,
  featureFlagsReducer,
  ReduxStoreHelper,
  askLayerReducer,
} from "@meetin/components";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { appReducer } from "../modules/app/redux/appSlice";

export const createRootReducer = () =>
  combineReducers({
    app: appReducer,
    post: postReducer,
    slack: slackStateReducer,
    featureFlags: featureFlagsReducer,
    askLayer: askLayerReducer,
    [rtkApi.reducerPath]: rtkApi.reducer,
  });

export const store = configureStore({
  reducer: createRootReducer(),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: true,
      serializableCheck: true,
    }).concat([rtkApi.middleware]),
});

// @ts-expect-error valid type
ReduxStoreHelper.initializeStore({ store });
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
