import { Collection, Profile } from "@meetin/shared";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  createFilterOptions,
  showErrorNotification,
  Box,
  Popper,
  PopperProps,
} from "@meetin/uicore";
import {
  HTMLAttributes,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { RxSlash } from "react-icons/rx";
import { ImStack } from "react-icons/im";
import { CollectionsIcon } from "@meetin/uicore/icons";
import { useCollections, useCreateCollectionMutation } from "../hooks";
import { clientLogger } from "../../logger";
import { trackActionStart, Actions, trackActionEnd } from "../../monitoring";
import { ZIndex } from "../../common";
import { getPortalContainer } from "../../common/utils";
import { AnalyticsEvents, trackEvent } from "../../analytics";

type Props = {
  collections: Collection[];
  onCollectionSelect: (selectedCollections: Collection[]) => void;
  open?: boolean;
  onClose?: () => void;
  userId: Profile["user_id"];
  projectMode?: boolean;
};

type AutocompleteCollectionType = { newCollection?: string } & Collection;

const ExtensionPopper = (props: PopperProps) => (
  <Popper
    {...props}
    container={getPortalContainer()}
    sx={{
      "&.MuiAutocomplete-popper": {
        width: "auto !important",
        background: "#fff",
        minWidth: 180,
        zIndex: ZIndex + 2,
      },
      "&.MuiAutocomplete-popper .MuiAutocomplete-paper": {
        padding: "0px",
      },
      "&.MuiAutocomplete-popper .MuiAutocomplete-option": {
        whiteSpace: "nowrap",
      },
    }}
  />
);
const AddToCollections = ({
  collections,
  onCollectionSelect,
  open,
  onClose,
  userId,
  projectMode,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useState(open);
  const [selectedCollections, setSelectedCollections] = useState<Collection[]>(
    [...collections] || []
  );

  const { collections: myCollections } = useCollections({ userId });

  const [createCollection] = useCreateCollectionMutation();

  const filter = createFilterOptions<AutocompleteCollectionType>();

  // Close the autocomplete popup if clicked outside
  const handleBodyOnclick = useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  useEffect(() => {
    document.body.addEventListener("click", handleBodyOnclick);

    return () => {
      document.body.removeEventListener("click", handleBodyOnclick);
    };
  }, [handleBodyOnclick]);

  // Stop propogation to avoid conflict with document click
  const handleOnClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const handleCreateCollection = async (newCollectionTitle: string) => {
    trackActionStart(Actions.COLLECTION_CREATE);
    clientLogger.info(`creating new collection: ${newCollectionTitle}`);
    const response = await createCollection({
      title: newCollectionTitle,
      userId,
      objective: null,
    });

    // typeguarding response
    if ("error" in response) {
      clientLogger.error(
        "Unable to create new collection",
        undefined,
        response.error as Error
      );
      showErrorNotification({ message: (response.error as Error).message });
      trackActionEnd(
        Actions.COLLECTION_CREATE,
        Actions.COLLECTION_CREATE_FAIL,
        {
          error: (response.error as Error).message,
        }
      );
      return null;
    }

    trackActionEnd(
      Actions.COLLECTION_CREATE,
      Actions.COLLECTION_CREATE_SUCCESS,
      {
        collection: response.data.id,
      }
    );

    trackEvent(AnalyticsEvents.COLLECTION_CREATED, {
      collection_name: newCollectionTitle,
    });

    return response.data;
  };

  const onChange = async (
    event: SyntheticEvent,
    collection: Collection | string | null
  ) => {
    clientLogger.info("selected collection", { newValue: collection });
    const lastItem =
      typeof collection !== "string"
        ? collection
        : { newCollection: collection, title: collection };

    // If last item in list has newCollection, then create new collection and add to state variable
    if (lastItem && "newCollection" in lastItem) {
      const newCollection = await handleCreateCollection(
        lastItem?.newCollection ? lastItem.newCollection : `${collection}`
      );
      if (newCollection) {
        setSelectedCollections((allCollections) => [
          ...allCollections,
          newCollection,
        ]);
        onCollectionSelect([...selectedCollections, newCollection]);
        return;
      }
    }

    const filteredWithoutNewCollection = lastItem
      ? [lastItem as Collection]
      : [];
    onCollectionSelect(filteredWithoutNewCollection);
    setSelectedCollections(filteredWithoutNewCollection);
  };

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: Collection
  ) => {
    const isSelected = selectedCollections.find(
      (collection) => collection.title === option.title
    );

    return (
      <Box
        component="li"
        {...props}
        key={option.id}
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          color: "#333",
        }}
      >
        <ImStack
          style={{
            stroke: isSelected ? "#007AFF" : "#333",
          }}
          size="12px"
        />
        {option.title}
      </Box>
    );
  };
  return (
    <Box onClick={handleOnClick}>
      <Autocomplete
        open={isOpen}
        size="small"
        sx={{
          padding: "0px",
          "&": {
            minWidth: 180,
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
          "& .MuiInputBase-input": {
            fontSize: "14px",
          },
          "& .MuiInputAdornment-root": {
            margin: 0,
          },
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input":
            {
              py: 0,
            },
        }}
        options={myCollections || []}
        PopperComponent={ExtensionPopper}
        value={selectedCollections[0]}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option?.title
        }
        onChange={onChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.title
          );
          if (inputValue !== "" && !isExisting) {
            return [
              {
                newCollection: inputValue,
                title: `Add "${inputValue}"`,
              } as AutocompleteCollectionType,
              ...filtered,
            ];
          }

          return filtered;
        }}
        selectOnFocus
        handleHomeEndKeys
        freeSolo
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={
              projectMode ? "Select or create a project" : "Add to a collection"
            }
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <CollectionsIcon />
                  {params.InputProps.startAdornment}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <RxSlash size="12px" />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

export default AddToCollections;
