import { PostgrestFilterBuilder } from "@supabase/postgrest-js";
import { Database } from "@meetin/supabase";
import {
  rtkApi,
  Collection,
  Post,
  PostWithCollections,
  Profile,
  SlackChannel,
  SlackMember,
} from "@meetin/shared";
import { SupabaseClientHelper } from "../../supabase";
import { clientLogger } from "../../logger";
import { handleAPIError } from "../../user/session";

type PostByIdQueryProps = {
  postId: Post["id"];
};

type PostByCollectionQueryProps = {
  collectionId?: Collection["id"];
};

type PostsInPageByUserIdQueryProps = {
  url: string;
  userId: Profile["user_id"];
  // In dashboard page, get all posts
  ignoreUrl?: boolean;
};

type PostsInPageByChannelsQueryProps = {
  url: string;
  channels: (SlackChannel["id"] | SlackMember["id"])[];
};

type UnsortedPostsQueryProps = {
  userId: Profile["user_id"];
};

const addFilters = (
  query: PostgrestFilterBuilder<
    Database["public"],
    | Post
    | Database["public"]["Tables"]["channels_info"]["Row"]
    | Database["public"]["Tables"]["post_id_collection_id"]["Row"],
    unknown
  >,
  url = "",
  prefix = ""
) => {
  [
    ["type", "eq", "Post"],
    ["status", "neq", "Archived"],
  ].forEach((filter) =>
    query.filter(`${prefix}${filter[0]}`, filter[1], filter[2])
  );

  if (url) {
    query.eq(`${prefix}page_url`, url);
  }

  return query;
};

type PostsInPageByCollectionsQueryProps = {
  url: string;
  collectionIds?: Collection["id"][];
};

export const postsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getReplies: builder.query<PostWithCollections[] | null, PostByIdQueryProps>(
      {
        providesTags: (result, error, arg) => [
          { type: "Replies", id: arg.postId },
        ],
        queryFn: async ({ postId }: PostByIdQueryProps) => {
          const supabaseClient = SupabaseClientHelper.getSupabaseClient();
          if (!postId) {
            return { data: null };
          }
          clientLogger.debug(`loading replies ${postId}`);
          const query = supabaseClient.from("posts").select("*");
          query.eq("parent", postId);
          const { count, data, error, status, statusText } = await query;
          clientLogger.debug(`loaded replies for ${postId}`, {
            count,
            data: data?.length,
            error,
            status,
            statusText,
          });

          if (error) {
            clientLogger.error(`error loading replies for post: ${postId}`, {
              error: error.message,
            });
            throw new Error(error.message);
          }

          return { data };
        },
      }
    ),
    // get post data by post id
    getPostById: builder.query<PostWithCollections | null, PostByIdQueryProps>({
      queryFn: async ({ postId }: PostByIdQueryProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!postId) {
          return { data: null };
        }
        const { data, error } = await supabaseClient
          .from("posts")
          .select("*,post_id_collection_id(collections(*))")
          .eq("id", postId)
          .single<PostWithCollections>();

        if (error) {
          clientLogger.error(`error loading post: ${postId}`, {
            error: error.message,
          });
          throw new Error(error.message);
        }

        return { data };
      },
    }),
    // get all posts in page specific for user
    getPostsInPageByUserId: builder.query<
      PostWithCollections[] | null,
      PostsInPageByUserIdQueryProps
    >({
      providesTags: ["getPostsInPageByUserId"],
      queryFn: async ({
        url,
        userId,
        ignoreUrl,
      }: PostsInPageByUserIdQueryProps) => {
        try {
          const supabaseClient = SupabaseClientHelper.getSupabaseClient();
          if ((!ignoreUrl && !url) || !userId) {
            return { data: null };
          }

          clientLogger.debug(`querying for posts by userid`, { url, userId });

          const postsByPostedByQuery = supabaseClient
            .from("posts")
            .select("*, post_id_collection_id(collections(*))")
            .eq("posted_by", userId)
            // sort by time added to collection
            .order("created_at", { ascending: false });

          addFilters(postsByPostedByQuery, url);

          const myPostsResponse = await postsByPostedByQuery;
          clientLogger.debug("loaded Posts", {
            posts: myPostsResponse.data?.map((p) => p.id),
            error: myPostsResponse.error,
          });

          if (myPostsResponse.error) {
            clientLogger.error("[getPostsInPageByUserId] error", {
              error: myPostsResponse.error.message,
            });
          }

          if (!myPostsResponse.data) {
            return { data: null };
          }

          // converting inner joined collections to single collection
          // one post can have only one relation with one collection - but supabase shows type as collections[] instead of collection
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return { data: myPostsResponse.data as PostWithCollections[] };
        } catch (err) {
          clientLogger.error(
            "getPostsInPageByUserId",
            { url, userId, ignoreUrl },
            err as Error
          );
          return { data: null };
        }
      },
    }),
    // get posts in page from all collections specific for user
    getPostsInPageByCollections: builder.query<
      PostWithCollections[] | null,
      PostsInPageByCollectionsQueryProps
    >({
      providesTags: ["getPostsInPageByCollections"],
      queryFn: async ({
        url,
        collectionIds,
      }: PostsInPageByCollectionsQueryProps) => {
        try {
          const supabaseClient = SupabaseClientHelper.getSupabaseClient();
          if (!url || !collectionIds?.length) {
            return { data: null };
          }

          clientLogger.debug(`querying for posts by collections`, {
            url,
            collectionIds,
          });

          const postsByCollectionsQuery = supabaseClient
            .from("post_id_collection_id")
            .select(`posts(*,post_id_collection_id(collections(*)))`)
            .in("collection_id", collectionIds)
            // sort by time added to collection
            .order("created_at", { ascending: false });

          addFilters(postsByCollectionsQuery, url, "posts.");

          const postsInCollectionsResponse = await postsByCollectionsQuery;
          clientLogger.debug("loaded Posts by collections", {
            postsInCollectionsResponse,
          });

          if (postsInCollectionsResponse.error) {
            clientLogger.error("[getPostsInPageByCollections] error", {
              error: postsInCollectionsResponse.error.message,
            });
          }

          if (!postsInCollectionsResponse.data) {
            return { data: null };
          }

          const postsInMyCollections = postsInCollectionsResponse.data?.flatMap(
            (c) => c.posts
          );

          return {
            // converting inner joined collections to single collection
            // one post can have only one relation with one collection - but supabase shows type as collections[] instead of collection
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data: postsInMyCollections as PostWithCollections[],
          };
        } catch (err) {
          clientLogger.error(
            "getPostsInPageByCollections",
            { url, collectionIds },
            err as Error
          );
          return { data: null };
        }
      },
    }),
    // get all posts in page by channels: ex: slack
    getPostsInPageByChannels: builder.query<
      PostWithCollections[] | null,
      PostsInPageByChannelsQueryProps
    >({
      providesTags: ["getPostsInPageByChannels"],
      queryFn: async ({ url, channels }: PostsInPageByChannelsQueryProps) => {
        try {
          const supabaseClient = SupabaseClientHelper.getSupabaseClient();
          if (!url || !channels.length) {
            return { data: null };
          }

          clientLogger.debug(`querying for posts by channels`, {
            url,
            channels,
          });

          // Load collections info with posts
          const postsByChannelsQuery = supabaseClient
            .from("channels_info")
            .select("channel,posts(*,post_id_collection_id(collections(*)))");

          // Filter by channels in which current user is part of
          postsByChannelsQuery.in("channel", channels);

          addFilters(postsByChannelsQuery, url, "posts.");

          const myChannelsResponse = await postsByChannelsQuery;
          clientLogger.debug("loaded Posts by channel", {
            myChannelsResponse,
          });

          if (myChannelsResponse.error) {
            clientLogger.error("[getPostsInPageByChannels] error", {
              error: myChannelsResponse.error.message,
            });
            handleAPIError(myChannelsResponse.error.message);
          }

          if (!myChannelsResponse.data) {
            return { data: null };
          }

          const myChannelsPosts = myChannelsResponse.data?.flatMap(
            (c) => c.posts
          );

          return {
            // converting inner joined collections to single collection
            // one post can have only one relation with one collection - but supabase shows type as collections[] instead of collection
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data: myChannelsPosts as PostWithCollections[],
          };
        } catch (err) {
          clientLogger.error(
            "getPostsInPageByChannels",
            { url, channels },
            err as Error
          );
          return { data: null };
        }
      },
    }),
    getPostsInCollection: builder.query<
      Post[] | null,
      PostByCollectionQueryProps
    >({
      providesTags: (result, error, arg) => [
        { type: "getPostsInCollection", id: arg.collectionId },
      ],
      queryFn: async ({ collectionId }: PostByCollectionQueryProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!collectionId) {
          return { data: null };
        }
        clientLogger.info(`fetching posts in collection: ${collectionId}`);

        const query = supabaseClient
          .from("post_id_collection_id")
          .select(
            `created_at,post_id (*,post_id_collection_id(collections(*)))`
          )
          .eq("collection_id", collectionId)
          // sort by time added to collection
          .order("created_at", { ascending: false });
        addFilters(query, "", "post_id.");

        const { data, error } = await query;

        if (error) {
          clientLogger.error(
            `Unable to get posts in collection: ${collectionId}`,
            {
              error: error.message,
            }
          );
          throw new Error(error.message);
        }
        const posts = data.flatMap((p) => p.post_id as PostWithCollections[]);

        return { data: posts };
      },
    }),
    // get all posts which are not added in any collection
    getUnsortedPosts: builder.query<
      PostWithCollections[] | null,
      UnsortedPostsQueryProps
    >({
      providesTags: ["getUnsortedPosts"],
      queryFn: async ({ userId }: UnsortedPostsQueryProps) => {
        try {
          const supabaseClient = SupabaseClientHelper.getSupabaseClient();
          if (!userId) {
            return { data: null };
          }

          clientLogger.info(`querying for unsorted posts`, { userId });

          // query all posts by this user which is not part of any collection
          const unsortedPostsQuery = supabaseClient.rpc("get_unsorted_posts", {
            user_id: userId,
          });

          addFilters(unsortedPostsQuery, "", "");

          const unsortedPostsResponse = await unsortedPostsQuery;
          clientLogger.info("loaded unsorted posts", {
            unsortedPostsResponse,
          });

          if (!unsortedPostsResponse.data) {
            return { data: null };
          }

          return {
            data: unsortedPostsResponse.data as PostWithCollections[],
          };
        } catch (err) {
          clientLogger.error("getUnsortedPosts", undefined, err as Error);
          return { data: null };
        }
      },
    }),
  }),
});

export const {
  useGetRepliesQuery,
  useLazyGetRepliesQuery,
  useGetPostByIdQuery,
  useLazyGetPostByIdQuery,
  useGetPostsInCollectionQuery,
  useGetPostsInPageByChannelsQuery,
  useLazyGetPostsInPageByChannelsQuery,
  useGetPostsInPageByUserIdQuery,
  useLazyGetPostsInPageByUserIdQuery,
  useGetUnsortedPostsQuery,
  useGetPostsInPageByCollectionsQuery,
} = postsApi;
