import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SlackMember, SlackChannel } from "@meetin/shared";

export type InitialState = {
  members: Record<string, SlackMember>;
  channels: Record<string, SlackChannel>;
};
const initialState = {
  members: {},
  channels: {},
} as InitialState;

const slackStateSlice = createSlice({
  name: "slackState",
  initialState,
  reducers: {
    setMembers: (state, action: PayloadAction<SlackMember[]>) => {
      action.payload.forEach((member) => {
        state.members[member.id] = member;
      });
    },
    setChannels: (state, action: PayloadAction<SlackChannel[]>) => {
      action.payload.forEach((channel) => {
        state.channels[channel.id] = channel;
      });
    },
    resetSlack: () => initialState,
  },
});

export const { setMembers, setChannels, resetSlack } = slackStateSlice.actions;

export const slackStateReducer = slackStateSlice.reducer;
