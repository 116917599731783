import { Collection, Post, Profile } from "@meetin/shared";
import { Box } from "@meetin/uicore";
import { clientLogger } from "../../logger";
import { useAddCollectionsToPostMutation } from "../hooks";
import AddToCollections from "./AddToCollections";
import CollectionChip from "./CollectionChip";
import { AnalyticsEvents, trackEvent } from "../../analytics";

type Props = {
  selectedCollections: Collection[];
  postId?: Post["id"];
  userId: Profile["user_id"];
  onCollectionSelect?: (collections: Collection[]) => void;
  onChipDelete?: () => void;
  isPostOwner: boolean;
};

/**
 * This component renders the collections attached with the posts as MUI chips
 * Also shows a + button so user can add more collections to the post
 */
const CollectionsInPost = ({
  postId,
  userId,
  selectedCollections,
  onCollectionSelect,
  onChipDelete,
  isPostOwner,
}: Props): JSX.Element | null => {
  const [addCollections] = useAddCollectionsToPostMutation();

  const onSelect = (newCollections: Collection[]) => {
    clientLogger.info(`collections selected for post: ${postId}`, {
      collections: newCollections,
    });
    if (!postId) {
      onCollectionSelect?.(newCollections);
      return;
    }
    addCollections({
      post: postId,
      collections: newCollections.map((c) => c.id),
    });

    trackEvent(AnalyticsEvents.COLLECTION_ADDED_COMMENT, {
      collections: newCollections.map((c) => c.id).join(", "),
    });
  };

  const onClose = () => {};
  if (!userId) {
    return null;
  }

  return (
    <Box>
      {selectedCollections.length ? (
        <CollectionChip
          collection={selectedCollections[0]}
          onDelete={onChipDelete}
          isPostOwner={isPostOwner}
          postId={postId}
        />
      ) : (
        <AddToCollections
          collections={selectedCollections}
          onCollectionSelect={onSelect}
          userId={userId}
          onClose={onClose}
        />
      )}
    </Box>
  );
};

export default CollectionsInPost;
