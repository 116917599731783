import { Typography, Box } from "@meetin/uicore";
import { BlankStateSearchInput } from "./BlankStateSearchInput";
import UsedQuestions from "../../ai/askLayer/components/UsedQuestions";

interface Props {
  setHasAnyPendingGeneration: (b: boolean) => void;
  generateInitialFindings: () => Promise<void>;
}

export const InsightsBlankState = ({
  generateInitialFindings,
  setHasAnyPendingGeneration,
}: Props) => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
    alignItems="center"
    justifyContent="center"
    textAlign="center"
    padding="16px"
  >
    <Typography
      variant="h1"
      sx={{
        fontWeight: "400",
        fontSize: "22px",
        letterSpacing: "-0.45px",
        lineHeight: "25px",
        marginBottom: "32px",
      }}
    >
      What are you looking for?
    </Typography>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="98%"
      marginBottom="40%"
    >
      <BlankStateSearchInput
        generateInitialFindings={generateInitialFindings}
        setHasAnyPendingGeneration={setHasAnyPendingGeneration}
      />
      <Box display="flex" justifyContent="flex-end" width="100%">
        <UsedQuestions hideIcon hideLeftText />
      </Box>
    </Box>
    <Box
      sx={{
        position: "absolute",
        bottom: "12px",
        display: "flex",
        width: "100%",
        justifyContent: "space-evenly",
      }}
    >
      <Typography
        variant="caption"
        component="a"
        href="https://www.loom.com/share/f5caab1d88474c43ad8943d68ede24f5?sid=8b64a79e-60dd-49eb-a99a-75809de64ff7"
        target="_blank"
        sx={{
          fontSize: "13px",
          textDecoration: "none",
          color: "#00050F",
        }}
      >
        Learn more
      </Typography>

      <Typography
        variant="caption"
        component="a"
        href="https://layer.app/support"
        target="_blank"
        sx={{
          fontSize: "13px",
          textDecoration: "none",
          color: "#00050F",
        }}
      >
        Need help?
      </Typography>
    </Box>
  </Box>
);
