import { Box, CircularProgress } from "@meetin/uicore";

type Props = {
  loading: boolean;
};

/** shows loading icon in switch input */
const SwitchIcon = ({ loading }: Props): JSX.Element => (
  <Box
    className="MuiSwitch-thumb"
    sx={{
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      backgroundColor: "currentColor",
      width: 20,
      height: 20,
      borderRadius: "50%",
    }}
  >
    {loading && <CircularProgress size={20} color="primary" thickness={3} />}
  </Box>
);

export default SwitchIcon;
