import { Box, CircularProgress } from "@meetin/uicore";
import { AppDispatch, Collection, Profile } from "@meetin/shared";
import { usePostsInCollection } from "./hooks";
import { clientLogger } from "../logger";
import PostsList from "./components/PostsList";

type Props = {
  userId: Profile["user_id"];
  collection: Collection;
  ignorePostClick?: boolean;
  dispatch: AppDispatch;
};

const PostsInCollection = ({
  userId,
  collection,
  dispatch,
  ignorePostClick,
}: Props): JSX.Element => {
  const { data: posts, isFetching } = usePostsInCollection({
    collectionId: collection.id,
  });

  clientLogger.info("[PostsInCollection]", { collection: collection.id });

  return (
    <Box sx={{ position: "relative", overflowY: "auto" }}>
      {isFetching ? (
        <CircularProgress size={20} sx={{ position: "absolute" }} />
      ) : null}
      <PostsList
        posts={posts}
        dispatch={dispatch}
        userId={userId}
        ignorePostClick={ignorePostClick}
      />
    </Box>
  );
};

export default PostsInCollection;
