import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { AppDispatch, PostWithCollections, Profile } from "@meetin/shared";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
  Skeleton,
} from "@meetin/uicore";
import pluralize from "pluralize";
import {
  MarkAsDoneButton,
  PostContent,
  Replies,
  ReplyButton,
  ReplyForm,
  selectCurrentCollectionId,
  selectIsSelectedPost,
  selectSelectedPostId,
  setSelectedPostId,
  useReplies,
} from ".";
import { UserAvatar, UserName } from "../user";
import PageDetails from "./components/PageDetails";
import { clientLogger } from "../logger";
import { timeAgo } from "./utils";
import MinimizePostButton from "./components/MinimizePostButton";
import { CollectionsInPost } from "../collections";
import { AnalyticsEvents, trackEvent } from "../analytics";
import CopyLinkButton from "./components/CopyLinkButton";

type Props = {
  post: PostWithCollections;
  userId: Profile["user_id"];
  dispatch: AppDispatch;
  onMinimize?: () => void;
  onMarkAsDone?: () => void;
  hidePageDetails?: boolean;
  showMinimizeButton?: boolean;
  hideShadow?: boolean;
  ignorePostClick?: boolean;
  isPending?: boolean;
};

type StyledPostCardProps = {
  isActivePost: boolean;
};

const PostCard = styled(Card)<StyledPostCardProps>(({ isActivePost }) => ({
  position: "relative",
  background: isActivePost ? "#E7F2FE" : "transparent",
  border: "1px solid",
  borderColor: isActivePost ? "#D5E0EC !important" : "transparent",
  cursor: "pointer",
  textAlign: "left",
  "&:hover": {
    background: isActivePost ? "#DAECFF" : "rgba(0, 5, 20, 0.04) ",
    borderColor: "rgba(0, 5, 20, 0.07) !important",
  },
}));

const SkeletonWrapper = ({
  children,
  showSkeleton,
}: {
  children: JSX.Element | null | (JSX.Element | null)[];
  showSkeleton?: boolean;
}) =>
  showSkeleton ? <Skeleton>{children}</Skeleton> : <span>{children}</span>;

const PostComponent = ({
  post,
  onMinimize,
  userId,
  dispatch,
  hidePageDetails,
  showMinimizeButton,
  onMarkAsDone,
  hideShadow,
  ignorePostClick,
  isPending,
}: Props): JSX.Element => {
  const { replies } = useReplies(post.id);
  const [showReplies, setShowReplies] = useState(false);
  const postRef = useRef<HTMLDivElement | null>(null);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const isActivePost = useSelector(selectIsSelectedPost(post.id));
  const collectionId = useSelector(selectCurrentCollectionId);
  const selectedPostId = useSelector(selectSelectedPostId);

  useEffect(() => {
    if (selectedPostId !== post.id || !postRef.current) {
      return;
    }
    setTimeout(() => {
      if (postRef.current) {
        postRef.current.scrollIntoView({ block: "center", behavior: "smooth" });
      }
    }, 500);
  }, [post.id, selectedPostId]);

  const toggleReplies = () => {
    if (!replies?.length) {
      return;
    }

    // toggling from visible to hidden
    if (showReplies) {
      trackEvent(AnalyticsEvents.COMMENT_REPLIES_HIDE_CLICKED);
    } else {
      trackEvent(AnalyticsEvents.COMMENT_REPLIES_SHOW_CLICKED);
    }

    setShowReplies((s) => !s);
  };

  const handleClick = () => {
    if (ignorePostClick) {
      clientLogger.debug(`ignoring clicked post ${post.id}`);
      return;
    }
    clientLogger.info(`clicked post ${post.id}`);
    dispatch(setSelectedPostId(null));
    window.dispatchEvent(
      new CustomEvent("store-post", {
        detail: {
          postId: post.id,
          commentId: null,
          url: post.page_url,
          menuBarCollectionId: collectionId,
        },
      })
    );
  };

  const onMarkedAsDone = () => {
    onMarkAsDone?.();
  };

  return (
    <Grid xs={12} mt={1}>
      <PostCard
        ref={postRef}
        onClick={handleClick}
        isActivePost={isActivePost}
        sx={{ boxShadow: hideShadow ? "none" : undefined }}
      >
        {!hidePageDetails ? <PageDetails pageUrl={post.page_url} /> : null}
        <CardHeader
          avatar={<UserAvatar userId={post.posted_by} size={22} />}
          title={
            <Stack direction="row" alignItems="center">
              <UserName userId={post.posted_by} />
              <Typography variant="body1" ml="8px" color="text.secondary">
                {" "}
                {timeAgo(post.created_at)}{" "}
              </Typography>
            </Stack>
          }
          action={
            <SkeletonWrapper showSkeleton={isPending}>
              <CopyLinkButton postId={post.id} />
              {userId ? (
                <MarkAsDoneButton postId={post.id} onSuccess={onMarkedAsDone} />
              ) : null}
              {showMinimizeButton ? (
                <MinimizePostButton onMinimize={onMinimize} />
              ) : null}
            </SkeletonWrapper>
          }
          sx={{
            minHeight: 32,
            p: 0,
          }}
        />
        <CardContent sx={{ p: "6px !important" }}>
          <Stack spacing="8px">
            <PostContent content={post.text} overrides={{ ml: "30px" }} />

            {/* Show reply button */}
            {replies?.length ? (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="text"
                  size="small"
                  color="primary"
                  onClick={toggleReplies}
                  sx={{ p: "0" }}
                >
                  {showReplies
                    ? "Hide replies"
                    : `+ ${pluralize("reply", replies?.length || 0, true)}`}
                </Button>
                {showReplies ? <Divider sx={{ width: "70%" }} /> : null}
              </Stack>
            ) : null}

            {/* Replies */}
            {showReplies ? (
              <Replies key={`${post.id}-replies`} postId={post.id} />
            ) : null}

            <ReplyForm
              postId={post.id}
              showReplyForm={showReplyForm}
              setShowReplyForm={setShowReplyForm}
            />
          </Stack>
        </CardContent>
        <SkeletonWrapper showSkeleton={isPending}>
          {!showReplyForm ? (
            <CardActions sx={{ justifyContent: "space-between" }}>
              <CollectionsInPost
                selectedCollections={
                  post.post_id_collection_id?.map((c) => c.collections) || []
                }
                postId={post.id}
                userId={userId}
                isPostOwner={post.posted_by === userId}
              />
              {userId ? (
                <ReplyButton
                  showReplyForm={showReplyForm}
                  setShowReplyForm={setShowReplyForm}
                />
              ) : null}
            </CardActions>
          ) : null}
        </SkeletonWrapper>
      </PostCard>
    </Grid>
  );
};

export default PostComponent;
