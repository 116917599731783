import { ReactElement } from "react";
import { StarsIcon } from "@meetin/uicore/icons";
import { Typography } from "@meetin/uicore";
import useSubscriptionLimits from "../../../subscription/useSubscriptionLimits";
import { Tooltip } from "../../../common";

interface UsedQuestionsProps {
  hideIcon?: boolean;
  hideLeftText?: boolean;
  color?: string;
  style?: React.CSSProperties;
}

const UsedQuestions = ({
  hideIcon = false,
  hideLeftText = false,
  color = "inherit",
  style,
}: UsedQuestionsProps): ReactElement | null => {
  const { allowedQuestionsLimit, usedQuestions, isQuestionsLimitByWeek } =
    useSubscriptionLimits();

  if (allowedQuestionsLimit === -1) {
    return null;
  }

  return (
    <Tooltip
      title={`${
        allowedQuestionsLimit - usedQuestions
      } questions left. Reloads every ${
        isQuestionsLimitByWeek ? "week" : "day"
      }`}
    >
      <Typography
        variant="body1"
        sx={{
          float: "right",
          lineHeight: 2.5,
          color,
          fontWeight: 400,
          ...style,
        }}
      >
        {hideIcon && <StarsIcon />} {allowedQuestionsLimit - usedQuestions}/
        {allowedQuestionsLimit} {hideLeftText ? "left" : ""}
      </Typography>
    </Tooltip>
  );
};

export default UsedQuestions;
