import { Collection, Post } from "@meetin/shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PostInitialState = {
  selectedPostId: Post["id"] | null;
  currentCollectionId: Collection["id"] | null;
};
const postSlice = createSlice({
  name: "postState",
  initialState: {
    selectedPostId: null,
    currentCollectionId: null,
  } as PostInitialState,
  reducers: {
    setSelectedPostId: (state, action: PayloadAction<Post["id"] | null>) => {
      state.selectedPostId = action.payload;
    },
    setCurrentCollectionId: (
      state,
      action: PayloadAction<PostInitialState["currentCollectionId"]>
    ) => {
      state.currentCollectionId = action.payload;
    },
  },
});

export const { setSelectedPostId, setCurrentCollectionId } = postSlice.actions;

export const postReducer = postSlice.reducer;
