import {
  IconButton,
  CircularProgress,
  Stack,
  RightArrowIcon,
} from "@meetin/uicore";
import UsedQuestions from "./UsedQuestions";

type Props = {
  isLoading: boolean;
  isDisabled: boolean;
};

const AskLayerSubmitButton = ({
  isLoading,
  isDisabled,
}: Props): JSX.Element => (
  // const handleChange = async (e: SelectChangeEvent) => {
  //   const selectedValue = e.target
  //     .value as unknown as Props["selectedAskLayerType"];
  //   await chrome.runtime.sendMessage({
  //     type: "UPDATE_USER_PREFERENCE",
  //     message: { askLayerSearchType: selectedValue },
  //   });
  // };

  <Stack
    direction="row"
    sx={{
      borderRadius: 12,
      p: 0.5,
      // "&:hover": {
      //   background: "rgba(34, 83, 231, 0.30)",
      // },
    }}
  >
    <IconButton
      // disable if text is empty
      disabled={isDisabled || isLoading}
      type="submit"
      sx={{
        "&, &:hover": {
          borderRadius: 20,
          py: 0.1,
          mr: 0.5,
          "svg path": { fill: "#fff" },
          backgroundColor: isDisabled
            ? "rgba(34, 83, 231, 0.5) !important"
            : "rgba(34, 83, 231, 1) !important",
        },
      }}
    >
      {isLoading ? (
        <CircularProgress size={18} />
      ) : (
        <>
          <UsedQuestions
            hideIcon={false}
            hideLeftText={false}
            color="#FFF"
            style={{ fontSize: "13px" }}
          />
          <RightArrowIcon />
        </>
      )}
    </IconButton>

    {/* <Tooltip title="Search options" zIndex={ZIndex + 1}>
        <Select
          disabled={isLoading}
          value={`${selectedAskLayerType}`}
          onChange={handleChange}
          IconComponent={ChevronUpIcon}
          MenuProps={{
            container: getPortalContainer(),
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
            sx: {
              zIndex: ZIndex + 1,
              "& .MuiMenu-paper": {
                px: 0,
              },
              "& .Mui-selected": {
                backgroundColor: "#BDCBF8 !important",
              },
            },
          }}
          sx={{
            "& .MuiSelect-select": {
              opacity: 0,
              width: "0.25em",
              boxSizing: "border-box",
              padding: 0,
              pr: "24px !important",
            },
            "&:hover": {
              background: "rgba(34, 83, 231, 0.30)",
              borderRadius: 999,
            },
          }}
        >
          {isLoading
            ? null
            : Object.keys(AskLayerTypes).map((key) => (
                <MenuItem value={key} sx={{ position: "relative", pl: 3.75 }}>
                  {`${selectedAskLayerType}` === key ? (
                    <Check style={{ position: "absolute", left: 10 }} />
                  ) : null}
                  {
                    AskLayerTypes[
                      parseInt(key, 10) as keyof typeof AskLayerTypes
                    ].icon
                  }{" "}
                  <RightArrowIcon style={{ marginRight: 8 }} />{" "}
                  {
                    AskLayerTypes[
                      parseInt(key, 10) as keyof typeof AskLayerTypes
                    ].label
                  }
                </MenuItem>
              ))}
        </Select>
      </Tooltip> */}
  </Stack>
);
export default AskLayerSubmitButton;
