import { RealtimeChannel } from "@supabase/supabase-js";
import { useRef } from "react";
import { clientLogger } from "../logger";
import { SupabaseSubscriberHelper } from "./supabaseSubscriberHelper";
import { SubscriptionArgs } from "./types";

// common hook to track supabase real time subscriptions
const useSupabaseSubscriber = () => {
  const ref = useRef<Record<string, RealtimeChannel>>({});

  const subscribe = async ({ channel, filter, callback }: SubscriptionArgs) => {
    try {
      if (ref.current[channel]) {
        return;
      }

      // Setting this to avoid subscribing multiple times
      ref.current[channel] = {} as RealtimeChannel;
      clientLogger.info(`subscribing ${channel}`);
      ref.current[channel] = await SupabaseSubscriberHelper.subscribe({
        channel,
        filter,
        callback,
      });
    } catch (err) {
      clientLogger.error(
        `Unable to subscribe ${channel}`,
        undefined,
        err as Error
      );
    }
  };

  const unsubscribe = (channel: string) => {
    try {
      if (ref.current[channel]) {
        clientLogger.debug(`unsubscribing from ${channel}`);
        SupabaseSubscriberHelper.unsubscribe({ channel });
        delete ref.current[channel];
      }
    } catch (err) {
      clientLogger.error(
        `Unable to unsubscribe ${channel}`,
        undefined,
        err as Error
      );
    }
  };

  return { subscribe, unsubscribe };
};

export default useSupabaseSubscriber;
