// eslint-disable-next-line check-file/filename-naming-convention
import { useCallback } from "react";
import { FormHelperText } from "@meetin/uicore";
import { useSelector } from "react-redux";
import { selectIsAnonymous } from "../../../user";
import { selectAnonymousUsage } from "../redux/askLayerSelectors";
import { AnonymousSettings } from "../../../anonymous/settings";

const useAnonymousChatUsage = () => {
  const isAnonymousUser = useSelector(selectIsAnonymous);
  const currentUsage = useSelector(selectAnonymousUsage);

  const getAnonymousUsageText = useCallback(() => {
    if (!isAnonymousUser) {
      return null;
    }
    return (
      <FormHelperText>
        {currentUsage}/{AnonymousSettings.CHAT}
      </FormHelperText>
    );
  }, [isAnonymousUser, currentUsage]);

  return {
    getAnonymousUsageText,
    isAnonymousLimtExhausted: currentUsage >= AnonymousSettings.CHAT,
  };
};

export default useAnonymousChatUsage;
