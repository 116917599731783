import { Database } from "@meetin/supabase";

export type ProfileExtended = Database["public"]["Tables"]["profiles"]["Row"];

export type Profile = Omit<ProfileExtended, "slack_access_token">;

export const ProfileFields =
  "name,image,user_id,slack_user_id,slack_team_id,created_at";

export type OAuthQueryResponse = {
  access_token: string;
  expires_in: string;
  provider_token: string;
  refresh_token: string;
  token_type: string;
};

export type SupabaseUser = {
  id: string;
  email: string;
};
