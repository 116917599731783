/* eslint-disable check-file/filename-naming-convention */
import {
  Button,
  Typography,
  showErrorNotification,
  showInfoNotification,
} from "@meetin/uicore";
import { useCallback, useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetPageMarkdownQuery } from "./rtkAskLayerQueries";
import useCollectionRelavancy from "./useCollectionRelavancy";
import {
  selectActiveCollectionId,
  selectIsPdfProcessing,
  selectMessageByRequestId,
} from "./redux/askLayerSelectors";
import { removeMessage, setMessages } from "./redux/askLayerSlice";
import { AiMessage, AskLayerQueryType, SearchOperationStatus } from "./types";
import useEmbedding from "./useEmbedding";
import { useCollections } from "../../collections";
import { trackEvent, AnalyticsEvents } from "../../analytics";
import { ComponentsContext } from "../../common";
import { clientLogger } from "../../logger";
import {
  ConnectionErrorMessage,
  getSupabaseErrorMessage,
} from "../../supabase";
import useSubscriptionLimits from "../../subscription/useSubscriptionLimits";
import useAICompletion from "./components/useAICompletion";
import { isSidePanel } from "../../app";
import { useRelevantLinks } from "../../links/hooks/useRelevantLinks";

export const useCheckPageRelevancy = () => {
  const activeCollectionId = useSelector(selectActiveCollectionId);
  const { currentPageUrl, user } = useContext(ComponentsContext);
  const userId = user?.user_id;
  const { selectedCollection: collection } = useCollections({
    userId,
    collectionId: activeCollectionId ?? undefined,
  });

  // Summary
  const query = collection
    ? `
  I am conducting research on "${collection.title}", with the following objectives: "${collection.objective}".
  - Generate a JSON response with the following:
  1. Contain answer with precise, dense, 110 words summary, then specify how it is / or not related to my research topic and/or objectives. Spotlight hidden insights, emphasize stats. No paraphrasing. The summary should be designed for professionals and avoid mentioning the absence of specific content or insights.
  2. Contain sources that help understand the context better. Provide 4-6 sources. they should be exactly as they appear in the context, word for word, without any substitutions, alterations, or changes in formatting. Ensure passages are chosen from different sections of the document, and avoid using titles. Each passage should be 40 words at minimum.`
    : `For the "answer" JSON field:
  1. Deliver a precise, dense, 110 words summary. Spotlight hidden insights, emphasize stats.No paraphrasing. Designed for professionals.
  2. Contain sources that help understand the context better. Provide 4-6 sources. they should be exactly as they appear in the context, word for word, without any substitutions, alterations, or changes in formatting. Ensure passages are chosen from different sections of the document, and avoid using titles. Each passage should be 40 words at minimum.
  `;

  // Initial findings, we generally assume that a collection is set
  const initialFindingsQuery = collection
    ? `I am conducting research on "${collection.title}". Provide a summary aboout what you know of this subject. Avoid mentioning your knowledge cutoff because it is unnecessary and is common sense.`
    : "";

  const [isLoading, setIsLoading] = useState(false);
  const isPdfProcessing = useSelector(selectIsPdfProcessing);
  const [getPageMarkdown, { isLoading: loadingPageMarkdown }] =
    useLazyGetPageMarkdownQuery();
  const { isQuestionsLimitReached } = useSubscriptionLimits();
  const dispatch = useDispatch();
  const { onSubmit, answer, isStreaming, isFetching } = useAICompletion();
  const { getRelevantLinks } = useRelevantLinks();
  const requestId = useRef("");
  const currentMessage = useSelector(
    selectMessageByRequestId(requestId.current)
  );

  useEffect(() => {
    if (!answer || !currentMessage) {
      return;
    }
    dispatch(
      setMessages({
        answer: [
          {
            index: 0,
            message: {
              role: "assistant",
              content: answer,
            },
            finish_reason: "stop",
          },
        ],
        sources: answer.sources,
        requestId: requestId.current,
        // setting as completed, so streamed response will be rendered in UI
        status: SearchOperationStatus.STREAMING,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [answer, collection?.id, currentPageUrl, dispatch]);

  const { upsertEmbedding } = useEmbedding();

  const { getKeyPoints } = useCollectionRelavancy({ collection });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkAndLoadKeyPoints = useCallback(
    (markdown: string, newInsights: AiMessage) =>
      getKeyPoints(markdown, newInsights),
    [getKeyPoints]
  );

  const incrementInsightsCount = async () => {
    if (!isSidePanel()) {
      return;
    }

    const currentCount =
      (await chrome.storage.local.get(["insights_count"])).insights_count || 0;

    await chrome.storage.local.set({ insights_count: currentCount + 1 });
    clientLogger.info("current count for survey", { currentCount });

    if (currentCount !== 0 && (currentCount === 3 || currentCount % 20 === 0)) {
      setTimeout(() => {
        // @ts-expect-error valid type
        bridge("show_form", "Survey");
      }, 5000);
    }
  };

  const handleRefresh = () => {
    if (isSidePanel()) {
      clientLogger.info("reloading page from side panel");
      chrome.tabs.query({ active: true, currentWindow: true }, (tabs) => {
        if (tabs[0].id) {
          clientLogger.info("reloading tab from side panel", {
            tab: tabs[0].url,
          });
          chrome.tabs.reload(tabs[0].id);
        }
      });
      return;
    }

    window.location.reload();
  };
  // might need some renaming - it's also used for generating initial findings
  const checkRelevancy = useCallback(
    async (
      type:
        | AskLayerQueryType.AI_INSIGHTS
        | AskLayerQueryType.AI_INSIGHTS_ALL
        | AskLayerQueryType.AI_INITIAL_FINDINGS = AskLayerQueryType.AI_INSIGHTS,
      parentRequestId?: AiMessage["requestId"]
    ) => {
      requestId.current = `${Date.now()}`;
      if (isLoading) {
        return;
      }

      dispatch(
        setMessages({
          question:
            // eslint-disable-next-line no-nested-ternary
            type === AskLayerQueryType.AI_INITIAL_FINDINGS
              ? collection?.title
              : type === AskLayerQueryType.AI_INSIGHTS_ALL
              ? "Key Insights in this page (full page)"
              : "Key Insights in this page",
          answer: [],
          type,
          requestId: requestId.current,
          sources: [],
          url: currentPageUrl,
          collectionId: collection?.id,
          status: SearchOperationStatus.RUNNING,
          parentRequestId,
          links:
            // empty array shows the loading indicator in the links panel
            type === AskLayerQueryType.AI_INITIAL_FINDINGS ? [] : undefined,
        })
      );

      if (isPdfProcessing) {
        showInfoNotification({
          message: "I am reading this pdf, hang tight...",
        });
        dispatch(removeMessage(requestId.current));
        return;
      }
      if (isQuestionsLimitReached()) {
        clientLogger.debug("Limit exhausted");
        dispatch(removeMessage(requestId.current));
        return;
      }
      setIsLoading(true);
      clientLogger.info(`timer: insights: ${requestId.current} start`, {
        currentPageUrl,
      });
      const { data: markdown, error } = await getPageMarkdown(currentPageUrl);
      if (!markdown || error) {
        clientLogger.error(
          "[CollectionRelavancyChecker] not able to get tab main html",
          error
        );

        trackEvent(AnalyticsEvents.NEW_INSIGHT_GENERATED, {
          success: "false",
          withinProject: `${Boolean(collection?.id)}`,
          failureReason: "not able to get tab main html",
        });

        setIsLoading(false);
        dispatch(removeMessage(requestId.current));
        const message =
          getSupabaseErrorMessage(error) ||
          "Unable to get page content. Please refresh the tab and try again.";

        if (message === ConnectionErrorMessage) {
          showInfoNotification({
            message: (
              <span>
                <Typography>{message}</Typography>
                <Button
                  sx={{ mt: 1, float: "right" }}
                  variant="contained"
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              </span>
            ),
          });
          return;
        }
        showErrorNotification({
          message,
        });
        return;
      }

      clientLogger.info("[CollectionRelavancyChecker] checking relavancy", {
        collection: collection?.id,
        objective: collection?.objective,
        url: currentPageUrl,
      });
      // replace all links with their text - we don't want to check relavancy of links
      const regex = /\[([\s\n]*.*?)\]\(.*?\)/g;

      const markdownWithoutLinks = markdown.replace(regex, "$1");
      clientLogger.info(
        `timer: insights: ${requestId.current} embedding start`
      );
      // Embedding is not needed for insights, so making it async
      upsertEmbedding({
        markdown,
        url: currentPageUrl,
      });

      if (type === AskLayerQueryType.AI_INITIAL_FINDINGS && collection) {
        getRelevantLinks(
          requestId.current,
          collection.title,
          collection.objective ?? ""
        );
      }

      clientLogger.info(
        `timer: insights: ${requestId.current} completion start`
      );
      onSubmit({
        query:
          type === AskLayerQueryType.AI_INITIAL_FINDINGS
            ? initialFindingsQuery
            : query,
        markdown: markdownWithoutLinks,
        url: currentPageUrl,
        type,
      });
    },
    [
      isLoading,
      dispatch,
      currentPageUrl,
      isPdfProcessing,
      isQuestionsLimitReached,
      getPageMarkdown,
      upsertEmbedding,
      onSubmit,
      query,
      initialFindingsQuery,
      collection,
      getRelevantLinks,
    ]
  );

  useEffect(() => {
    if (!isStreaming) {
      setIsLoading(false);
    }
    if (isStreaming || !currentMessage?.answer?.[0]?.message?.content?.answer) {
      return;
    }

    clientLogger.info(
      `timer: insights: ${requestId.current} completion completed`
    );
    // if ("error" in response) {
    //   const message = getSupabaseErrorMessage(response.error);

    //   clientLogger.error("[CollectionRelavancyChecker] error in onSubmit", {
    //     error: message,
    //   });

    //   trackEvent(AnalyticsEvents.NEW_INSIGHT_GENERATED, {
    //     success: "false",
    //     withinProject: `${Boolean(collection?.id)}`,
    //     failureReason: `${message}`,
    //   });

    //   setIsLoading(false);
    //   dispatch(removeMessage(requestId.current));
    //   return;
    // }

    // const sources =
    //   response.data
    //     ?.map((choice) => choice.message.content.sources)
    //     .flat()
    //     .filter((c) => Boolean(c)) || [];

    const answerFoundInPage =
      currentMessage.answer[0].message.content.answer.startsWith("Yes");

    // if (!sources && (!collection?.id || answerFoundInPage)) {
    //   checkAndLoadKeyPoints(markdownWithoutLinks, newInsights);
    // }
    dispatch(
      setMessages({
        requestId: requestId.current,
        status: SearchOperationStatus.COMPLETED,
      })
    );

    incrementInsightsCount();

    trackEvent(AnalyticsEvents.NEW_INSIGHT_GENERATED, {
      success: "true",
      withinProject: `${Boolean(collection?.id)}`,
      answerFound: `${answerFoundInPage}`,
    });
  }, [collection?.id, currentMessage?.answer, dispatch, isStreaming]);

  return {
    checkRelevancy,
    isLoading: isFetching || isLoading || loadingPageMarkdown,
  };
};
