import { LoadingButton } from "@meetin/uicore";
import useCheckout from "./useCheckout";

type Props = {
  priceId: string;
  planTitle: string;
  disabled?: boolean;
};

const CheckoutButton = ({
  priceId,
  planTitle,
  disabled,
}: Props): JSX.Element => {
  const { createSession, isLoading } = useCheckout();

  const handleClick = () => createSession({ priceId });

  return (
    <LoadingButton
      onClick={handleClick}
      loading={isLoading}
      disabled={disabled}
    >
      {planTitle}
    </LoadingButton>
  );
};

export default CheckoutButton;
