import { Avatar } from "@meetin/uicore";
import randomColor from "randomcolor";
import { useEffect } from "react";
import { clientLogger } from "../logger";
import { useGetUserProfileQuery } from "../user";
import { getSupabaseErrorMessage } from "../supabase";

type Props = {
  userId: string;
  size?: number;
};
const UserAvatar = ({ userId, size = 22 }: Props) => {
  const { data: member, error } = useGetUserProfileQuery({
    userId,
  });

  useEffect(() => {
    if (error) {
      clientLogger.error(`Unable to get ${userId} details`, {
        error: getSupabaseErrorMessage(error),
      });
    }
  }, [error, userId]);
  if (!member?.image) {
    return (
      <Avatar
        sx={{
          height: size,
          width: size,
          background: randomColor({ seed: userId }),
        }}
      >
        {member?.name?.charAt(0)}
      </Avatar>
    );
  }

  return (
    <Avatar
      src={member.image}
      alt={member.name || "user profile picture"}
      style={{ height: size, width: size }}
    />
  );
};

export default UserAvatar;
