import { showErrorNotification } from "@meetin/uicore";
import { getEnvVariable, getUserToken } from "../app";
import { clientLogger } from "../logger";

export const getResponseData = async (response: Response) => {
  const text = await response.text();
  try {
    return JSON.parse(text);
  } catch (e) {
    showErrorNotification({
      message: text || "Unknown error",
    });
  }
  return null;
};

export const fetchFromApi = async (
  endpoint: string,
  queryData: Record<string, unknown>,
  requestInit?: RequestInit
) => {
  const token = await getUserToken();

  if (!token) {
    throw new Error("Missing authorization");
  }

  const data = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
    body: JSON.stringify(queryData),
    ...requestInit,
  };
  if (requestInit?.method === "GET") {
    delete (data as { body?: unknown }).body;
  }
  const response = await fetch(
    `${getEnvVariable("AI_API_URL")}/${endpoint}`,
    data
  );

  if (!response.ok) {
    clientLogger.error(`error in api request`, {
      error: response.statusText,
    });
    throw new Error(response.statusText || "unknown error");
  }

  return await getResponseData(response);
};
