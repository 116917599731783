import { Button } from "@meetin/uicore";

type Props = {
  showReplyForm: boolean;
  setShowReplyForm: (show: boolean) => void;
};

const ReplyButton = ({
  showReplyForm,
  setShowReplyForm,
}: Props): JSX.Element => {
  const enableReplyForm = () => setShowReplyForm(true);

  return (
    <Button
      onClick={enableReplyForm}
      size="small"
      variant="contained"
      sx={{ display: showReplyForm ? "none" : "flex" }}
      endIcon={
        <span style={{ opacity: 0.55, fontSize: "13px", fontWeight: "600" }}>
          R
        </span>
      }
    >
      Reply
    </Button>
  );
};

export default ReplyButton;
