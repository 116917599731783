import { ThemeProvider } from "@meetin/uicore";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import UnsortedPostsView from "./modules/dashboard/UnsortedPostsView";
import { Provider } from "react-redux";
import "./App.css";
import Home from "./modules/home/Home";
import PostPage from "./modules/post/Post";
import Dashboard from "./modules/dashboard/Dashboard";
import AppProvider from "./modules/app/AppProvider";
import CollectionView from "./modules/dashboard/CollectionView";
import { store } from "./store";
import AllPostsPage from "./modules/post/AllPostsPage";
import OAuthHandler from "./modules/oauth/OAuthHandler";
import InvitePage from "./modules/invite/Invite";
import PdfAiPage from "./modules/pdf/PdfAiPage";
import SubscriptionSuccess from "./modules/subscription/SubscriptionSuccess";
import SubscriptionCancel from "./modules/subscription/SubscriptionCancel";
import Pricing from "./modules/pricing/Pricing";
import SubscriptionExisting from "./modules/subscription/SubscriptionExisting";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "p/:postId/:commentId?",
        element: <PostPage />,
      },
      {
        path: "i",
        element: <InvitePage />,
      },
      {
        path: "pdf",
        element: <PdfAiPage />,
      },
    ],
    errorElement: <div>Not found!</div>,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/subscription/success",
    element: <SubscriptionSuccess />,
  },
  {
    path: "/subscription/cancel",
    element: <SubscriptionCancel />,
  },
  {
    path: "/subscription/existing",
    element: <SubscriptionExisting />,
  },
  {
    path: "/oauth/:provider?",
    element: <OAuthHandler />,
  },
  {
    path: "/collections",
    element: <Dashboard />,
    children: [
      {
        path: "/collections/all-notes",
        element: <AllPostsPage />,
      },
      {
        path: "/collections/unsorted",
        element: <UnsortedPostsView />,
      },
      {
        path: "/collections/:collectionId/:collectionTitle?",
        element: <CollectionView />,
      },
    ],
  },
]);

const App = () => (
  <div className="App">
    <ThemeProvider>
      <Provider store={store}>
        <AppProvider>
          <RouterProvider router={router} />
        </AppProvider>
      </Provider>
    </ThemeProvider>
  </div>
);

export default App;
