import {
  InputAdornment,
  OutlinedInput,
  Stack,
  Tab,
  Tabs,
  showErrorNotification,
  showInfoNotification,
} from "@meetin/uicore";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { useContext, useRef, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyGetPageMarkdownQuery } from "../rtkAskLayerQueries";
// import MarkdownDisplay from "./MarkdownDisplay";
// import PromptSuggestions from "./PromptSuggestions";
import { setMessages } from "../redux/askLayerSlice";
import {
  selectSearchAllPages,
  selectActiveCollectionId,
  selectIsPdfProcessing,
} from "../redux/askLayerSelectors";
import useEmbedding from "../useEmbedding";
import { AskLayerQueryType, SearchOperationStatus } from "../types";
import useAnonymousChatUsage from "./useAnonymousChatUsage";
import { trackEvent, AnalyticsEvents } from "../../../analytics";
import { ComponentsContext } from "../../../common";
import { clientLogger } from "../../../logger";
import { getSupabaseErrorMessage } from "../../../supabase";
import useSubscriptionLimits from "../../../subscription/useSubscriptionLimits";
import useAICompletion from "./useAICompletion";
import AskLayerSubmitButton from "./AskLayerSubmitButton";
import { GenerateInsightsButton } from "./GenerateInsightsButton";
import { useRelevantLinks } from "../../../links/hooks/useRelevantLinks";

const schema = Yup.object({
  query: Yup.string().required("Query is required"),
  promptText: Yup.string().optional(),
  requestId: Yup.string().optional(),
}).required();

type AskLayerFormInput = {
  query: string;
  promptText: string | undefined;
  requestId: string | undefined;
};

interface Props {
  isGenerateInsightsVisible: boolean;
  isCheckRelevancyLoading: boolean;
  checkRelevancy: () => Promise<void>;
  onNewQuestionSubmit: () => void;
}

const AskLayerForm = ({
  isGenerateInsightsVisible,
  onNewQuestionSubmit,
  isCheckRelevancyLoading,
  checkRelevancy,
}: Props) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  const searchAllPages = useSelector(selectSearchAllPages);
  const activeCollectionId = useSelector(selectActiveCollectionId);
  const isPdfProcessing = useSelector(selectIsPdfProcessing);
  const isPdfProcessingRef = useRef(isPdfProcessing);
  const { currentPageUrl, userPreferences } = useContext(ComponentsContext);
  const [getPageMarkdown, { isLoading: loadingPageMarkdown }] =
    useLazyGetPageMarkdownQuery();
  const isPreprocessing = useRef(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const { isQuestionsLimitReached } = useSubscriptionLimits();
  const { getRelevantLinks } = useRelevantLinks();
  const { getAnonymousUsageText, isAnonymousLimtExhausted } =
    useAnonymousChatUsage();
  const time = useRef(0);
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    resetField,
    formState: { isSubmitted },
  } = useForm<AskLayerFormInput>({
    resolver: yupResolver(schema),
  });

  const getInitialFindingsQuery = (query: string) =>
    `I am conducting research on "${query}". Provide a summary aboout what you know of this subject. Avoid mentioning your knowledge cutoff because it is unnecessary and is common sense.`;

  const query = watch("query");

  const selectedAskLayerType = useMemo(
    () => userPreferences.askLayerSearchType || AskLayerQueryType.AI_SEARCH,
    [userPreferences]
  );

  const { upsertEmbedding, isLoading: upsertingEmbedding } = useEmbedding();

  const {
    isFetching: askingLayer,
    onSubmit,
    answer,
    isStreaming,
  } = useAICompletion();

  useEffect(() => {
    const requestId = getValues("requestId");
    if (!requestId || isStreaming) {
      return;
    }

    dispatch(
      setMessages({
        requestId,
        status: SearchOperationStatus.COMPLETED,
      })
    );
  }, [dispatch, getValues, isStreaming]);

  useEffect(() => {
    if (!answer) {
      return;
    }
    dispatch(
      setMessages({
        answer: [
          {
            index: 0,
            message: {
              role: "assistant",
              content: answer,
            },
            finish_reason: "stop",
          },
        ],
        requestId: getValues("requestId") || `${Date.now()}`,
        sources: answer.sources,
        status: SearchOperationStatus.STREAMING,
      })
    );
  }, [answer, currentPageUrl, dispatch, getValues, searchAllPages]);

  const isLoading =
    isSubmitted &&
    (loadingPageMarkdown ||
      upsertingEmbedding ||
      askingLayer ||
      isPdfProcessing);

  // read the page/pdf content and create embeddings to reduce time for first question
  const preprocessPageContent = async () => {
    // In web, if pdf processing is happening, dont preprocess the content
    if (isPdfProcessing) {
      clientLogger.debug("pdf preprocessing in progress", { currentPageUrl });
      return;
    }
    if (isAnonymousLimtExhausted || isQuestionsLimitReached()) {
      clientLogger.debug("Limit exhausted");
      return;
    }
    if (isPreprocessing.current) {
      clientLogger.debug("preprocessing in progress");
      return;
    }
    isPreprocessing.current = true;
    const response = await getPageMarkdown(currentPageUrl);
    if (response.data) {
      await upsertEmbedding({ markdown: response.data, url: currentPageUrl });
    }
    isPreprocessing.current = false;
  };

  useEffect(() => {
    if (isSubmitted && isPdfProcessing) {
      showInfoNotification({
        message: "I am reading this pdf, hang tight...",
      });
    }
  }, [isPdfProcessing, isSubmitted]);

  const onFormSubmit = async (data: AskLayerFormInput) => {
    if (!query) {
      return;
    }
    setIsProcessing(true);

    if (isPdfProcessingRef.current) {
      setTimeout(() => {
        onFormSubmit(data);
      }, 500);
      setIsProcessing(false);
      return;
    }
    if (isAnonymousLimtExhausted || isQuestionsLimitReached()) {
      onNewQuestionSubmit();

      clientLogger.debug("Limit exhausted");
      if (isAnonymousLimtExhausted) {
        showInfoNotification({
          message:
            "You have reached the limit of guest usage. Please signup to continue.",
        });
      }
      setIsProcessing(false);
      return;
    }

    if (isPreprocessing.current) {
      clientLogger.debug("preprocessing in progress");
      setTimeout(() => {
        onFormSubmit(data);
      }, 500);
      setIsProcessing(false);
      return;
    }
    time.current = Date.now();
    clientLogger.info("ask layer form submitted", { data, currentPageUrl });
    const { data: markdown, error } = await getPageMarkdown(currentPageUrl);

    if (!markdown || error) {
      clientLogger.error("not able to get tab main html");
      showErrorNotification({
        message: getSupabaseErrorMessage(error) || "Please try again later.",
      });

      trackEvent(AnalyticsEvents.NEW_QUESTION_ASKED, {
        withinProject: `${Boolean(activeCollectionId)}`,
        answerFound: "false",
        callFailed: "true",
      });
      setIsProcessing(false);
      return;
    }

    const requestId = `${Date.now()}`;
    setValue("requestId", requestId);
    dispatch(
      setMessages({
        question: getValues("query"),
        answer: [],
        requestId,
        type: selectedAskLayerType,
        sources: [],
        searchAllPages: false, // do not enable search when asking a new question
        url: currentPageUrl,
        searchQuery: getValues("query"),
        status: SearchOperationStatus.RUNNING,
      })
    );
    const response = await upsertEmbedding({ markdown, url: currentPageUrl });
    if ("error" in response) {
      const message = getSupabaseErrorMessage(response.error);
      clientLogger.error("error upserting embedding", { error: message });
      showErrorNotification({
        message,
      });

      trackEvent(AnalyticsEvents.NEW_QUESTION_ASKED, {
        withinProject: `${Boolean(activeCollectionId)}`,
        answerFound: "false",
        callFailed: "true",
      });

      setIsProcessing(false);
      return;
    }

    if (selectedAskLayerType === AskLayerQueryType.AI_INITIAL_FINDINGS) {
      getRelevantLinks(requestId, data.query, "");
    }

    await onSubmit({
      ...data,
      query:
        selectedAskLayerType === AskLayerQueryType.AI_INITIAL_FINDINGS
          ? getInitialFindingsQuery(data.query)
          : data.query,
      markdown,
      url: currentPageUrl,
      type: selectedAskLayerType,
    });
    setIsProcessing(false);
    resetField("query");
  };

  useEffect(() => {
    const query = getValues("query");
    if (query) {
      onFormSubmit({
        query,
        promptText: "",
        requestId: `${Date.now()}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAskLayerType, getValues]);

  const handleAskLayerQueryTypeChange = async (
    _: unknown,
    value: AskLayerQueryType
  ) => {
    if (isProcessing) {
      return;
    }
    await chrome.runtime.sendMessage({
      type: "UPDATE_USER_PREFERENCE",
      message: { askLayerSearchType: value },
    });
  };

  // const onPromptSelect = (promptText: string) => {
  //   setValue("query", promptText);
  // };

  // useEffect(() => {
  //   const fetchInsightsCount = async () => {
  //     const currentCount = (await chrome.storage.local.get(["insights_count"])).insights_count || 0;
  //     clientLogger.info("sa:2", currentCount);
  //   };
  //   fetchInsightsCount();
  // }, []);

  /* TODO: move this Stack somewhere higher where it can share with the
      generate insights button initially hidden */
  return (
    <form onSubmit={handleSubmit(onFormSubmit)} ref={formRef}>
      <Stack
        sx={{
          borderRadius: "20px",
          overflow: "hidden",
        }}
      >
        {/* <PromptSuggestions onPromptSelect={onPromptSelect} /> */}
        <Tabs
          value={selectedAskLayerType}
          onChange={handleAskLayerQueryTypeChange}
          disabled={isProcessing}
          sx={{
            overflow: "hidden",
            borderBottom: "1px solid #E5E5E5",
            ".MuiTab-root.Mui-selected": {
              backgroundColor: "#e9eefd",
              color: "#2253E7",
              fontWeight: "500", // added this line to change the font weight of selected tab
            },
            ".MuiTabs-indicator": {
              display: "none",
            },
            ".MuiTab-root": {
              width: "50%",
              fontSize: "14px",
              fontWeight: "400", // added this line to change the font weight of unselected tab
              padding: "0",
            },
          }}
        >
          <Tab
            label="General questions"
            value={AskLayerQueryType.AI_INITIAL_FINDINGS}
          />
          <Tab
            label="Ask about this page"
            value={AskLayerQueryType.AI_SEARCH}
          />
        </Tabs>
        <div
          style={{
            padding: "8px",
          }}
        >
          <OutlinedInput
            onFocus={preprocessPageContent}
            size="small"
            fullWidth
            {...register("query")}
            //  handle a case where a question was already asked before and change placeholder
            placeholder={
              !isGenerateInsightsVisible
                ? "Ask a question about this page"
                : "Ask a followup question"
            }
            sx={{
              pr: 0,
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiIconButton-edgeEnd": {
                marginRight: "0px",
              },
              "& input": {
                px: "10px",
              },
            }}
            disabled={isProcessing}
            endAdornment={
              <InputAdornment position="end">
                <AskLayerSubmitButton
                  isLoading={isLoading}
                  isDisabled={!query}
                />
              </InputAdornment>
            }
          />
          {getAnonymousUsageText()}
        </div>

        {/* <TextField
            label="Prompt Text"
            variant="outlined"
            size="small"
            fullWidth
            {...register("promptText")}
          /> */}
        {/* <LoadingButton type="submit" loading={isLoading}>
            Ask Layer
          </LoadingButton> */}
      </Stack>
      <GenerateInsightsButton
        isLoading={isCheckRelevancyLoading}
        checkRelevancy={checkRelevancy}
      />
      {/* {pageMarkdown ? <MarkdownDisplay markdown={pageMarkdown} /> : null} */}
    </form>
  );
};

export default AskLayerForm;
