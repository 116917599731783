import { pdfJsEventBus } from "@meetin/components";
import { Box } from "@meetin/uicore";
import * as pdfjsLib from "pdfjs-dist";
import * as pdfjsViewer from "pdfjs-dist/web/pdf_viewer.js";
import { useCallback, useEffect, useRef } from "react";

type Props = {
  file: ArrayBuffer | string;
};

const PdfViewer = ({ file }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const loadViewer = useCallback(async () => {
    if (!canvasRef.current || !containerRef.current) {
      return;
    }

    // // (Optionally) enable hyperlinks within PDF files.
    const pdfLinkService = new pdfjsViewer.PDFLinkService({
      eventBus: pdfJsEventBus,
    });
    const pdfFindController = new pdfjsViewer.PDFFindController({
      eventBus: pdfJsEventBus,
      linkService: pdfLinkService,
    });
    const pdfViewer = new pdfjsViewer.PDFViewer({
      container: containerRef.current,
      eventBus: pdfJsEventBus,
      linkService: pdfLinkService,
      findController: pdfFindController,

      l10n: pdfjsViewer.NullL10n,
    });
    pdfLinkService.setViewer(pdfViewer);

    const loadingTask = pdfjsLib.getDocument(file);
    const pdfDocument = await loadingTask.promise;

    pdfViewer.setDocument(pdfDocument);
    pdfLinkService.setDocument(pdfDocument, null);
  }, [file]);

  useEffect(() => {
    loadViewer();
  }, [loadViewer]);

  return (
    <Box sx={{ position: "relative" }}>
      <canvas ref={canvasRef} />
      <Box
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          width: "100%",
          height: "100%",
        }}
        ref={containerRef}
      >
        <div id="viewer" className="pdfViewer"></div>
      </Box>
    </Box>
  );
};

export default PdfViewer;
