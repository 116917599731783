// lodash functions

export const pickBy = <T extends object>(
  obj: T | null | undefined,
  predicate?: (value: T[keyof T], key: string) => boolean
): T | undefined | null => {
  if (!obj) return obj;
  const entries = Object.entries(obj);
  const result = Object.fromEntries(
    entries.filter(([key, value]) => predicate?.(value, key))
  );

  return result as T;
};

export const camelCase = (str = ""): string => {
  const result = str.replace(/([A-Z])/g, " $1");
  return (
    result.charAt(0).toLowerCase() + result.slice(1).replace(/[\s-]+/g, "")
  );
};

export const noop = () => {};
