import { REALTIME_POSTGRES_CHANGES_LISTEN_EVENT } from "@supabase/supabase-js";
import { Post, PostWithCollections } from "@meetin/shared";
import { useEffect, useCallback, useContext } from "react";
import { useDispatch } from "react-redux";
import { useSupabaseSubscriber } from "../../supabase";
import { clientLogger } from "../../logger";
import { postsApi } from "./rtkPostsQueries";
import {
  ComponentsContext,
  usePageVisibility,
  usePrevious,
} from "../../common";

const RepliesChannel = `load_replies`;
const PostsUpdatesChannel = `posts_updates`;

const usePostsSubscriptions = (
  postIds: string,
  postsInPageQueryArgs?: { channels: string[] }
): void => {
  const isPageActive = usePageVisibility();
  const { subscribe, unsubscribe } = useSupabaseSubscriber();
  const dispatch = useDispatch();
  const prevPostIds = usePrevious(postIds);
  const { user, subscriptionsEnabled } = useContext(ComponentsContext);
  const currentUserId = user?.user_id;

  const subscribeToReplies = useCallback(() => {
    const filter = `parent=in.(${postIds})`;
    subscribe({
      channel: RepliesChannel,
      filter: {
        event: REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.INSERT,
        schema: "public",
        table: "posts",
        filter,
      },
      callback: (payload) => {
        clientLogger.info("replies received!", { payload });
        const newReply = payload.new as Post;

        if (!newReply.parent) {
          return;
        }

        dispatch(
          // @ts-expect-error valid
          postsApi.util.updateQueryData(
            "getReplies",
            { postId: newReply.parent },
            (allPosts: PostWithCollections[] | null) => {
              if (!allPosts) {
                return [newReply];
              }
              allPosts.push(newReply);

              return allPosts;
            }
          )
        );
      },
    });
  }, [dispatch, postIds, subscribe]);

  const getTagAndArgs = useCallback(
    (post: Post) => {
      if (post.posted_by === currentUserId) {
        return {
          tag: "getPostsInPageByUserId",
          args: { url: post.page_url, userId: currentUserId },
        };
      }

      return {
        tag: "getPostsInPageByChannels",
        args: { url: post.page_url, channels: postsInPageQueryArgs?.channels },
      };
    },
    [currentUserId, postsInPageQueryArgs?.channels]
  );

  // Subscribes to post table updates
  // no need to poll for this as it is covered by usePostsInPage hook
  const subscribeToPostsUpdate = useCallback(() => {
    subscribe({
      channel: PostsUpdatesChannel,
      filter: {
        event: REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.UPDATE,
        schema: "public",
        table: "posts",
        filter: `id=in.(${postIds})`,
      },
      callback: (payload) => {
        const updatedPost = payload.new as Post;
        const postId = updatedPost.id;
        clientLogger.info(`post: ${postId} updated`, { payload });
        if (updatedPost.archived_at) {
          const { tag, args } = getTagAndArgs(updatedPost);
          clientLogger.info(`post: ${postId} archived`);
          dispatch(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            postsApi.util.updateQueryData(
              // @ts-expect-error valid
              tag,
              args,
              (posts: PostWithCollections[] | null) => {
                if (posts) {
                  const index = posts.findIndex(
                    (p) => p && p.id === updatedPost.id
                  );
                  if (index > -1) {
                    posts.splice(index, 1);
                  }
                }
              }
            )
          );
        }
      },
    });
  }, [dispatch, getTagAndArgs, postIds, subscribe]);

  useEffect(() => {
    if (!postIds) {
      return undefined;
    }

    if (subscriptionsEnabled) {
      subscribeToReplies();
      subscribeToPostsUpdate();
    }

    return () => {
      if (prevPostIds && postIds !== prevPostIds) {
        unsubscribe(RepliesChannel);
        unsubscribe(PostsUpdatesChannel);
      }
    };
  }, [
    postIds,
    prevPostIds,
    subscribeToPostsUpdate,
    subscribeToReplies,
    unsubscribe,
    subscriptionsEnabled,
  ]);

  useEffect(() => {
    if (!isPageActive) {
      unsubscribe(RepliesChannel);
      unsubscribe(PostsUpdatesChannel);
    }
  }, [isPageActive, unsubscribe]);
};

export default usePostsSubscriptions;
