import { MinimizeIcon } from "@meetin/uicore/icons";
import { IconButton } from "@meetin/uicore";
import { Tooltip } from "../../common";

const MinimizePostButton = ({
  onMinimize,
}: {
  onMinimize?: () => void;
}): JSX.Element => (
  <Tooltip placement="top" title="Minimize">
    <IconButton
      onClick={onMinimize}
      sx={{
        background: "none",
        border: "none",
        padding: 0,
        cursor: "pointer",
        marginLeft: "6px",
      }}
    >
      <MinimizeIcon />
    </IconButton>
  </Tooltip>
);

export default MinimizePostButton;
