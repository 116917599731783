import { useEffect } from "react";
import { getAnonymousUsage } from "@meetin/components";

const useAnonymous = () => {
  const onStorageChange = (e: StorageEvent) => {
    getAnonymousUsage();
  };
  useEffect(() => {
    window.addEventListener("storage", onStorageChange);

    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, []);
};

export default useAnonymous;
