import { showErrorNotification } from "@meetin/uicore";
import { useEffect } from "react";
import { getSupabaseErrorMessage } from "../supabase";
import { useCreateCheckoutSessionMutation } from "./rtkSubscription";

const useCheckout = () => {
  const [createSession, { isLoading, data, error }] =
    useCreateCheckoutSessionMutation();

  useEffect(() => {
    if (error) {
      showErrorNotification({ message: getSupabaseErrorMessage(error) });
    }
  }, [error]);

  useEffect(() => {
    if (data?.url) {
      window.location.href = data.url;
    }
  }, [data]);

  return {
    createSession,
    isLoading,
  };
};

export default useCheckout;
