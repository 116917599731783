import { useCallback, useEffect, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import OneLine from "oneline";
import { showErrorNotification } from "@meetin/uicore";
import {
  AiMessage,
  AskLayerQueryType,
  SERPItem,
  SearchOperation,
  SearchOperationStatus,
  SearchStatus,
} from "./types";
import {
  useLazyAskLayerQuery,
  useLazyGetSERPResultsQuery,
  useLazyGetMarkdownFromCrawlerQuery,
} from "./rtkAskLayerQueries";
import {
  resetMessageOpertation,
  setMessages,
  updateMessageOperation,
} from "./redux/askLayerSlice";
import { selectLatestOperationInMessage } from "./redux/askLayerSelectors";
import useEmbedding from "./useEmbedding";
import { clientLogger } from "../../logger";
import { getUrlWithoutHash } from "../../posts";
import { getSupabaseErrorMessage } from "../../supabase";

const useWebsiteSearcher = ({
  query,
  searchAllPages,
  requestId,
  url,
}: {
  query: string;
  searchAllPages: boolean;
  requestId: AiMessage["requestId"];
  url: AiMessage["url"];
}) => {
  const [answer, setAnswer] = useState("");
  const [doSearch] = useLazyGetSERPResultsQuery();
  const [getMarkdown] = useLazyGetMarkdownFromCrawlerQuery();
  const { upsertEmbedding } = useEmbedding();
  const [askLayerQuestion] = useLazyAskLayerQuery();
  const status = useSelector(selectLatestOperationInMessage(requestId));
  const dispatch = useDispatch();
  const currentRequest = useRef<
    ReturnType<typeof getMarkdown> | ReturnType<typeof doSearch> | null
  >(null);

  const setStatus = useCallback(
    (s: SearchStatus) => {
      dispatch(updateMessageOperation({ requestId, ...s }));
    },
    [dispatch, requestId]
  );
  /**
   * gets markdown content of the page by url
   */
  const getLinkMarkdowns = useCallback(
    async (links: SERPItem[]) => {
      clientLogger.info("getting markdown from links", { links });

      setStatus({
        operation: SearchOperation.GET_MARKDOWN,
        status: SearchOperationStatus.RUNNING,
        link: links[0],
      });
      const request = getMarkdown({
        urls: links.map((link) => link.link),
      });

      currentRequest.current = request;
      const markdownResponse = await request;
      if (markdownResponse.error || !markdownResponse.data) {
        clientLogger.error(`error while loading markdown for ${links}`, {
          error: markdownResponse.error,
        });
        return null;
      }
      clientLogger.info("got markdown from links", {
        count: markdownResponse.data.length,
      });

      setStatus({
        operation: SearchOperation.GET_MARKDOWN,
        status: SearchOperationStatus.COMPLETED,
        link: links[0],
      });

      return markdownResponse.data;
    },
    [getMarkdown, setStatus]
  );

  /**
   * gets answer for the query for each url by markdown
   */
  const getAnswerPerLink = useCallback(
    async (linkMarkdowns: Record<string, string>, link: SERPItem) => {
      setStatus({
        operation: SearchOperation.GET_ANSWERS_PER_LINK,
        status: SearchOperationStatus.RUNNING,
        link,
      });
      const answerResponses = await Promise.all(
        Object.keys(linkMarkdowns).map(async (l) => {
          const response = await upsertEmbedding({
            markdown: linkMarkdowns[l],
            url: l,
          });
          if ("error" in response) {
            const message = getSupabaseErrorMessage(response.error);
            clientLogger.error("error upserting embedding", { error: message });
            showErrorNotification({
              message,
            });

            return null;
          }
          return askLayerQuestion({
            markdown: linkMarkdowns[l],
            url: l,
            query,
            type: AskLayerQueryType.AI_SEARCH,
          });
        })
      );
      setStatus({
        operation: SearchOperation.GET_ANSWERS_PER_LINK,
        status: SearchOperationStatus.COMPLETED,
        link,
      });
      return answerResponses.reduce((acc, response, index) => {
        if (!response) {
          return acc;
        }
        const l = Object.keys(linkMarkdowns)[index];
        if ("error" in response || !response?.data) {
          clientLogger.error(`error while loading markdown for ${l}`, {
            error: response.error,
          });
          return acc;
        }

        const answerInLink = response.data[0].message.content.answer;

        return { ...acc, [l]: answerInLink };
      }, {});
    },
    [askLayerQuestion, query, setStatus, upsertEmbedding]
  );

  /**
   * get a consolidated answer by sending all answers to openai and question
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getConsolidatedAnswer = useCallback(
    async (linkAnswers: Record<string, string>, link: SERPItem) => {
      clientLogger.info(`consolidating answer`, {
        linkAnswers,
      });
      const promptText = OneLine`Context contains answers for the question from various urls. 
      Your job is to provide a consolidated answer for the question only based on all the provided answers and quote the source link as well.`;

      const markdown = Object.keys(linkAnswers).reduce(
        (acc, l) => `${acc}Answer: ${linkAnswers[l]} Source: ${l} \n`,
        ""
      );

      if (!markdown) {
        clientLogger.info("null markdown", { linkAnswers, query });
        return null;
      }
      setStatus({
        operation: SearchOperation.GET_CONSOLIDATED_ANSWER,
        status: SearchOperationStatus.RUNNING,
        link,
      });
      const response = await askLayerQuestion({
        markdown,
        url: "",
        query,
        promptText,
        type: AskLayerQueryType.AI_CONSOLIDATE_ANSWER,
      });

      if ("error" in response || !response?.data) {
        clientLogger.error(`error while consolidating answer`, {
          error: response.error,
          linkAnswers,
        });
        return null;
      }

      setStatus({
        operation: SearchOperation.GET_CONSOLIDATED_ANSWER,
        status: SearchOperationStatus.COMPLETED,
        link,
      });
      return response.data[0].message.content;
    },
    [askLayerQuestion, query, setStatus]
  );

  const triggerSerpQuery = useCallback(async () => {
    setStatus({
      operation: SearchOperation.GET_SERP_RESULTS,
      status: SearchOperationStatus.RUNNING,
      link: null,
    });
    const request = doSearch({
      url,
      query,
      requestId,
    });

    currentRequest.current = request;
    const response = await request;
    const firstLink = response?.data?.organic_results
      ?.filter((result) => getUrlWithoutHash(result.link) !== url)
      .slice(0, 1);

    dispatch(
      setMessages({
        requestId,
        searchQuery: response.data?.searchQuery || query,
      })
    );

    if (response.error || !firstLink || !response.data) {
      showErrorNotification({
        message:
          response.data?.error || "Unable to find results for your query",
      });
      clientLogger.error(`error while loading search results`, {
        error: response.data?.error || getSupabaseErrorMessage(response.error),
        result: response.data,
      });
      return null;
    }

    setStatus({
      operation: SearchOperation.GET_SERP_RESULTS,
      status: SearchOperationStatus.COMPLETED,
      link: null,
    });

    // filter current page and take top 1 related links and scrape the data
    return firstLink;
  }, [dispatch, doSearch, query, requestId, setStatus, url]);

  const processLinks = useCallback(async () => {
    try {
      if (!searchAllPages || answer || currentRequest.current) {
        return;
      }

      if (status && status.operation !== SearchOperation.NONE) {
        return;
      }

      const results = await triggerSerpQuery();

      if (!results) {
        setStatus({
          operation: SearchOperation.COMPLETED,
          status: SearchOperationStatus.COMPLETED,
          link: null,
        });
        return;
      }

      const links = Array.from(
        new Set(results.slice(0, 3).map((result) => result))
      );

      const linkMarkdowns = await getLinkMarkdowns(links);
      if (!linkMarkdowns) {
        clientLogger.info("no markdowns received");
        setStatus({
          operation: SearchOperation.COMPLETED,
          status: SearchOperationStatus.COMPLETED,
          link: null,
        });
        return;
      }
      const linkAnswers = await getAnswerPerLink(linkMarkdowns, links[0]);
      // we dont need to consolidate the answer as we are going to scrape only first link
      // const consolidatedAnswer = await getConsolidatedAnswer(linkAnswers);
      batch(() => {
        setAnswer((Object.values(linkAnswers)[0] as string) || "");
        setStatus({
          operation: SearchOperation.COMPLETED,
          status: SearchOperationStatus.COMPLETED,
          link: links[0],
        });
      });
    } catch (err) {
      clientLogger.error(
        "Error loading markdown from links",
        undefined,
        err as Error
      );
      setStatus({
        operation: SearchOperation.COMPLETED,
        status: SearchOperationStatus.COMPLETED,
        link: null,
      });
    }
  }, [
    answer,
    getAnswerPerLink,
    getLinkMarkdowns,
    searchAllPages,
    setStatus,
    status,
    triggerSerpQuery,
  ]);

  useEffect(() => {
    processLinks();
  }, [processLinks]);

  const onCancel = () => {
    clientLogger.info(`cancelling ${requestId}`);
    if (currentRequest.current) {
      currentRequest.current.abort();
      dispatch(resetMessageOpertation(requestId));
      dispatch(setMessages({ requestId, searchAllPages: false }));
    }
  };

  return {
    onCancel,
    status,
    answer,
  };
};

export default useWebsiteSearcher;
