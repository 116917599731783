import { isExtension } from "../common/utils";

export const reset = () => {
  if (!isExtension()) {
    return;
  }
  chrome.runtime?.sendMessage({
    type: "SPLITIO_RESET",
  });
};

export const getFeatureEnabled = async (
  featureName: string,
  attributes?: Record<string, unknown>
): Promise<boolean | null> => {
  if (!isExtension()) {
    return null;
  }

  const response = await chrome.runtime.sendMessage({
    type: "SPLITIO_GET_FEATURE_TREATMENT",
    message: {
      featureName,
      attributes,
    },
    from: 1, // Sender.Content
  });

  return response as boolean;
};
