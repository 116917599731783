import { Box, Stack, Link } from "@meetin/uicore";
import ExtensionButton from "../../extensionButton/ExtensionButton";

const Header = ({ hasExtension }: { hasExtension: boolean }) => {
  return (
    <Stack
      component="header"
      direction="row"
      sx={{
        borderBottom: "1px solid rgba(0, 0, 0, 0.20)",
        px: 3.5,
        pt: 2,
        pb: 1,
      }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Link
          href="/"
          sx={{ display: "inline-block", verticalAlign: "middle" }}
        >
          <img src="/logo-text.svg" alt="Layer Logo" />
        </Link>
        {!hasExtension ? <ExtensionButton /> : null}
      </Box>
    </Stack>
  );
};

export default Header;
