import { Typography, styled } from "@meetin/uicore";

interface Props {
  number: number;
}

const TextIcon = styled(Typography)({
  display: "inline-block",
  fontWeight: "700",
  color: "#2253E7",
  width: "13px",
  height: "13px",
  fontSize: "11px",
  border: "2px solid #2253E7",
  textAlign: "center",
  borderRadius: "50%",
});

export const KeyPointNumberIcon = ({ number }: Props) => (
  <TextIcon variant="caption">{number}</TextIcon>
);
