import { createSelector } from "@reduxjs/toolkit";
import { Features } from "@meetin/shared";
import { InitialState } from "./slice";

const selectFeatureFlagState = (state: { featureFlags: InitialState }) =>
  state.featureFlags;

const selectFeatures = createSelector(
  selectFeatureFlagState,
  (state) => state.features
);

export const selectFeatureFlagValue = (featureName: Features) =>
  createSelector(selectFeatures, (features) => features[featureName] ?? false);
