import { CircularProgress, Alert } from "@meetin/uicore";
import { AppDispatch, Profile } from "@meetin/shared";
import { useGetUnsortedPostsQuery } from "./hooks";
import { getSupabaseErrorMessage } from "../supabase";
import PostsList from "./components/PostsList";

type Props = {
  userId: Profile["user_id"];
  ignorePostClick?: boolean;
  dispatch: AppDispatch;
};
const UnsortedPosts = ({ userId, dispatch, ignorePostClick }: Props) => {
  const {
    data: unsortedPosts,
    error,
    isFetching,
  } = useGetUnsortedPostsQuery({ userId }, { skip: !userId });

  if (isFetching) {
    return <CircularProgress size={20} />;
  }

  const errorMessage = getSupabaseErrorMessage(error);
  if (errorMessage) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  return (
    <PostsList
      posts={unsortedPosts}
      dispatch={dispatch}
      userId={userId}
      ignorePostClick={ignorePostClick}
    />
  );
};

export default UnsortedPosts;
