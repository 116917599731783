import { Post } from "@meetin/shared";
import { Fragment } from "react";
import { Divider, List } from "@meetin/uicore";
import useReplies from "./hooks/useReplies";
import Reply from "./Reply";

type Props = {
  postId: Post["id"];
  selectedCommentId?: Post["id"];
};

const Replies = ({ postId, selectedCommentId }: Props): JSX.Element | null => {
  const { replies } = useReplies(postId);

  if (!replies?.length) {
    return null;
  }

  return (
    <List sx={{ pt: "0px !important", mt: "0px !important" }}>
      {replies.map((reply, idx) => (
        <Fragment key={reply.id}>
          <Reply reply={reply} selectedCommentId={selectedCommentId} />
          {replies?.length > 1 && idx !== replies.length - 1 && (
            <Divider variant="fullWidth" />
          )}
        </Fragment>
      ))}
    </List>
  );
};

export default Replies;
