import { useEffect } from "react";
import { Profile, SupabaseUser } from "@meetin/shared";

interface AnalyticsConfig {
  user: Profile | null;
  supabaseUser: SupabaseUser | null;
}

export const useAnalytics = ({ user, supabaseUser }: AnalyticsConfig) => {
  useEffect(() => {
    if (supabaseUser) {
      chrome.runtime.sendMessage({
        type: "ANALYTICS_SET_USER_ID",
        message: { userId: supabaseUser.email },
      });
    }
  }, [supabaseUser]);

  useEffect(() => {
    chrome.runtime.sendMessage({
      type: "ANALYTICS_IDENTIFY_USER",
      message: { user },
    });
  }, [user]);
};
