export const slackPostRequest = async (
  apiUrl: string,
  token: string,
  bodyData: Record<string, unknown>
) => {
  const headers: HeadersInit = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: token,
  };
  const response = await fetch(`${apiUrl}/slack/api-proxy`, {
    headers,
    method: "POST",
    body: JSON.stringify(bodyData),
  });
  const responseJson = await response.json();
  if (response.status > 399) {
    throw new Error(responseJson.error);
  }
  return responseJson;
};
