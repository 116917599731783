import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { SerializedError } from "@reduxjs/toolkit";

export const ConnectionErrorMessage = "Please refresh your page and try again.";

const checkConnectionError = (message?: string) => {
  if (message?.startsWith("Could not establish connection.")) {
    return ConnectionErrorMessage;
  }

  return message;
};
export const getSupabaseErrorMessage = (
  error?: FetchBaseQueryError | SerializedError
) => {
  if (!error) {
    return null;
  }

  if ("message" in error) {
    return checkConnectionError(error.message);
  }

  if ("error" in error) {
    return checkConnectionError(error.error);
  }

  return null;
};
