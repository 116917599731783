import {
  AlertIcon,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
} from "@meetin/uicore";
import {
  MarkAsDoneIcon,
  ReadIcon,
  SearchIcon,
  ThinkingIcon,
} from "@meetin/uicore/icons";
import { useSelector } from "react-redux";
import { useRef } from "react";
import {
  AiMessage,
  SearchOperation,
  SearchOperationStatus,
  SearchStatus,
} from "./types";
import { selectOperationsInMessage } from "./redux/askLayerSelectors";
import { getPortalContainer } from "../../common/utils";
import { ZIndex } from "../../common";

const WebsiteSearcherStatus = ({
  requestId,
  searchQuery,
  answer,
}: {
  requestId: AiMessage["requestId"];
  searchQuery: AiMessage["searchQuery"];
  answer: string;
}) => {
  const statuses = useSelector(selectOperationsInMessage(requestId));
  const selectRef = useRef<HTMLSelectElement>(null);

  const getIcon = ({ operation, status }: SearchStatus) => {
    if (status === SearchOperationStatus.RUNNING) {
      return <CircularProgress size={16} />;
    }
    switch (operation) {
      case SearchOperation.GET_SERP_RESULTS:
        return <SearchIcon />;

      case SearchOperation.GET_ANSWERS_PER_LINK:
        return <ThinkingIcon />;

      case SearchOperation.GET_MARKDOWN:
        return <ReadIcon />;

      case SearchOperation.COMPLETED:
        return answer.startsWith("Sorry, I could not find an answer") ? (
          <AlertIcon />
        ) : (
          <MarkAsDoneIcon />
        );

      case SearchOperation.GET_CONSOLIDATED_ANSWER:
        return <ThinkingIcon />;

      default:
        return <MarkAsDoneIcon />;
    }
  };
  const getLabel = ({ operation, status }: SearchStatus) => {
    switch (operation) {
      case SearchOperation.GET_SERP_RESULTS:
        return status === SearchOperationStatus.RUNNING
          ? `Searching: "${searchQuery}"`
          : `Searched: "${searchQuery}"`;

      case SearchOperation.GET_ANSWERS_PER_LINK:
        return "Thinking...";

      case SearchOperation.GET_MARKDOWN:
        return "Reading content...";

      case SearchOperation.COMPLETED:
        if (status === SearchOperationStatus.COMPLETED) {
          if (answer.startsWith("Sorry, I could not find an answer")) {
            return "Unable to find answer";
          }
          if (answer) {
            return "Finished browsing";
          }
          return "Failed to complete browsing";
        }
        return "Browsing to find answer";

      case SearchOperation.GET_CONSOLIDATED_ANSWER:
        return "Thinking...";

      default:
        return null;
    }
  };
  if (!statuses?.length) {
    return null;
  }

  const lastStatus = statuses[statuses.length - 1];

  return (
    <Select
      value={lastStatus.operation}
      displayEmpty
      ref={selectRef}
      sx={{
        flex: 1,
        minWidth: 220,
        "& .MuiOutlinedInput-input": {
          display: "flex",
          "& .MuiTypography-root": {
            maxWidth: "calc(100% - 24px)",
          },
        },
      }}
      MenuProps={{
        sx: {
          "& .MuiPaper-root": { borderRadius: 1 },
          zIndex: ZIndex + 1,
          maxWidth: 300,
          "& .MuiMenuItem-root": {
            whiteSpace: "normal",
            "& .MuiTypography-root": {
              display: "-webkit-box",
              "-webkit-line-clamp": "2",
              overflow: "hidden",
              "-webkit-box-orient": "vertical",
              maxWidth: "calc(100% - 24px)",
            },
          },
        },
        container: selectRef.current || getPortalContainer(),
      }}
    >
      {statuses.map((item) => (
        <MenuItem value={item.operation} key={item.operation}>
          {getIcon(item)}
          <Typography sx={{ ml: 1 }}>{getLabel(item)}</Typography>
        </MenuItem>
      ))}
    </Select>
  );
};

export default WebsiteSearcherStatus;
