/* eslint-disable no-underscore-dangle */
import {
  RealtimeChannel,
  RealtimePostgresChangesPayload,
} from "@supabase/supabase-js";
import { SubscriptionArgs } from "./types";
import { clientLogger } from "../logger";

type InitProps = {
  subscribe: (args: SubscriptionArgs) => Promise<RealtimeChannel>;
  unsubscribe: (data: { channel: RealtimeChannel["topic"] }) => void;
  tabId: number;
};
type Props = {
  tabId: InitProps["tabId"];
  initializeHelper: (props: InitProps) => void;
  subscribe: InitProps["subscribe"];
  unsubscribe: InitProps["unsubscribe"];
  callbacks: Record<
    string,
    (
      payload: RealtimePostgresChangesPayload<{
        [key: string]: unknown;
      }>
    ) => void
  >;
  _subscribe: (
    args: SubscriptionArgs & { tabId: number }
  ) => Promise<RealtimeChannel>;
  _unsubscribe: (data: {
    channel: RealtimeChannel["topic"];
    tabId: number;
  }) => void;
  onUpdate: (data: {
    payload: RealtimePostgresChangesPayload<{
      [key: string]: unknown;
    }>;
    channel: SubscriptionArgs["channel"];
  }) => void;
};

/**
 * This helper is wrapper around supabase real time subscriptions
 * Can be initialized by browser-extension and web
 * Browser extension will call background method for any subscription, while web will directly call supabase client
 * Added a wrapper around subscribe and unsubscribe to hold the references of callbacks, so we can trigger callback when update is received
 */

export const SupabaseSubscriberHelper: Props = {
  tabId: 0,
  callbacks: {},
  _subscribe: () => {
    throw new Error("SupabaseSubscriberHelper -> _subscribe not initialized");
  },
  _unsubscribe: () => {
    throw new Error("SupabaseSubscriberHelper -> _unsubscribe not initialized");
  },
  initializeHelper: ({ subscribe, unsubscribe, tabId }: InitProps) => {
    clientLogger.debug("SupabaseSubscriberHelper initializeHelper");
    SupabaseSubscriberHelper._subscribe = subscribe;
    SupabaseSubscriberHelper._unsubscribe = unsubscribe;
    SupabaseSubscriberHelper.tabId = tabId;
  },
  subscribe: (args: SubscriptionArgs) => {
    clientLogger.debug(`SupabaseSubscriberHelper subscribe: ${args.channel}`, {
      filter: args.filter,
    });
    SupabaseSubscriberHelper.callbacks[args.channel] = args.callback;
    return SupabaseSubscriberHelper._subscribe({
      ...args,
      tabId: SupabaseSubscriberHelper.tabId,
    });
  },
  unsubscribe: ({ channel }: Parameters<InitProps["unsubscribe"]>["0"]) => {
    clientLogger.debug(`SupabaseSubscriberHelper unsubscribe: ${channel}`);
    delete SupabaseSubscriberHelper.callbacks[channel];
    SupabaseSubscriberHelper._unsubscribe({
      channel,
      tabId: SupabaseSubscriberHelper.tabId,
    });
  },
  onUpdate: ({
    payload,
    channel,
  }: {
    payload: RealtimePostgresChangesPayload<{
      [key: string]: unknown;
    }>;
    channel: SubscriptionArgs["channel"];
  }) => {
    SupabaseSubscriberHelper.callbacks[channel](payload);
  },
};
