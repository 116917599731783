import { Database } from "@meetin/supabase";
import { Collaborator, rtkApi } from "@meetin/shared";
import { showErrorNotification } from "@meetin/uicore";
import { clientLogger } from "../../logger";
import { SupabaseClientHelper } from "../../supabase";

type CreateCollaboratorProps =
  Database["public"]["Tables"]["collection_collaborators"]["Insert"];

/**
 * Collaborators related supabase queries
 */
export const collaboratorsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    // add collaborator
    addCollaborator: builder.mutation<
      Collaborator | null,
      CreateCollaboratorProps
    >({
      // since this collection is not present in user collections query, using id as List to invalidate all data
      invalidatesTags: () => [{ type: "Collections", id: "LIST" }],
      queryFn: async (collaborator: CreateCollaboratorProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!collaborator?.user_id) {
          clientLogger.error(`could not add collaborator`, {
            collaborator,
          });

          throw new Error("Please try again later");
        }

        const { data, error } = await supabaseClient
          .from("collection_collaborators")
          .upsert(collaborator)
          .eq("collection_id", collaborator.collection_id)
          .eq("user_id", collaborator.user_id);

        if (error) {
          clientLogger.error(
            `could not add collaborator to collection: ${collaborator.collection_id}`,
            {
              error: error.message,
            }
          );
          showErrorNotification({ message: error.message });
          throw new Error(error.message);
        }

        return { data };
      },
    }),
  }),
  overrideExisting: false,
});

export const { useAddCollaboratorMutation } = collaboratorsApi;
