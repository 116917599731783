import { useEffect } from "react";
import {
  LoadingButton,
  RefreshIcon,
  showErrorNotification,
  showSuccessNotification,
} from "@meetin/uicore";
import { useRefreshSubscriptionMutation } from "./rtkSubscription";
import { getSupabaseErrorMessage } from "../supabase";
import { Tooltip } from "../common";

const RefreshSubscriptionButton = () => {
  const [refreshSubscription, { isSuccess, isLoading, error }] =
    useRefreshSubscriptionMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessNotification({
        message: "Subscription refreshed successfully!",
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      showErrorNotification({ message: getSupabaseErrorMessage(error) });
    }
  }, [error]);

  return (
    <Tooltip title="Refresh plan">
      <LoadingButton
        loading={isLoading}
        onClick={() => refreshSubscription(undefined)}
      >
        <RefreshIcon />
      </LoadingButton>
    </Tooltip>
  );
};

export default RefreshSubscriptionButton;
