import {
  useState,
  useImperativeHandle,
  forwardRef,
  ForwardRefRenderFunction,
} from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  Stack,
  Typography,
} from "@meetin/uicore";
import useSubscriptionLimits from "../../../subscription/useSubscriptionLimits";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import UpgradeImage from "../Upgrade_Pro.jpg";
import UpgradeSubscriptionButton from "../../../subscription/UpgradeSubscriptionButton";
import { getPortalContainer } from "../../../common/utils";

export type QuestionsLimitRef = {
  onNewQuestionSubmit: () => void;
};
type Props = {};

const QuestionsLimit: ForwardRefRenderFunction<QuestionsLimitRef, Props> = (
  _,
  ref
): JSX.Element | null => {
  const { allowedQuestionsLimit, isQuestionsLimitReached } =
    useSubscriptionLimits();
  // default to true so popup will not show when user opens extension
  const [isCancelled, setIsCancelled] = useState(true);

  const limitReached = isQuestionsLimitReached();

  const handleCancel = () => {
    setIsCancelled(true);
  };
  useImperativeHandle(ref, () => ({
    onNewQuestionSubmit() {
      setIsCancelled(false);
    },
  }));

  // For unlimited questions, we don't show the limit
  if (allowedQuestionsLimit === -1) {
    return null;
  }
  return (
    <Stack>
      {!isCancelled && limitReached ? (
        <Dialog
          container={getPortalContainer()}
          open
          sx={{
            "& .MuiDialog-paper": {
              p: 0,
              width: 360,
              overflow: "hidden",
              border: "none !important",
            },
            "& .MuiCard-root": {
              p: 0,
              boxShadow: "none",
              border: "none !important",
            },
          }}
        >
          <Card>
            <CardMedia sx={{ height: 228 }} title="Upgrade to Pro">
              <img src={UpgradeImage} alt="Upgrade to Pro" />
            </CardMedia>
          </Card>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              Upgrade to Pro
            </Typography>
            <Typography variant="body2" color="text.secondary">
              You’ve reached your daily question limit. Purchase a Pro plan to
              get access to unlimited questions, early access to new features &
              more.
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <Button onClick={handleCancel}>Maybe later</Button>
            <UpgradeSubscriptionButton onClick={handleCancel} />
          </CardActions>
        </Dialog>
      ) : null}
    </Stack>
  );
};

export default forwardRef(QuestionsLimit);
