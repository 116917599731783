import { useEffect } from "react";
import {
  CircularProgress,
  ListItem,
  ListItemText,
  Typography,
  showErrorNotification,
} from "@meetin/uicore";
import { getSupabaseErrorMessage } from "../supabase";
import {
  useGetUserSubscriptionDetailsQuery,
  useGetUserSubscriptionSettingsQuery,
} from "./rtkSubscription";
import RefreshSubscriptionButton from "./RefreshSubscriptionButton";
import UpgradeSubscriptionButton from "./UpgradeSubscriptionButton";
import CancelPlan from "./CancelPlan";
import PerpetualLicenseChecker from "./perpetualLicense/PerpetualLicenseChecker";

const UserSubscription = () => {
  const { data: subscriptionDetails } = useGetUserSubscriptionDetailsQuery(
    undefined,
    {}
  );
  const { data, isFetching, error } = useGetUserSubscriptionSettingsQuery(
    undefined,
    {}
  );

  const plan = data?.subscription?.plan;
  const isCancelledPlan = subscriptionDetails?.cancel_at_period_end;
  const planEndsOn = subscriptionDetails?.current_period_end;

  const getSecondaryAction = () => {
    if (plan?.price_id === "free") {
      return <UpgradeSubscriptionButton variant="outlined" />;
    }
    if (subscriptionDetails) {
      return <CancelPlan subscriptionDetails={subscriptionDetails} />;
    }
    return null;
  };
  useEffect(() => {
    if (error) {
      showErrorNotification({ message: getSupabaseErrorMessage(error) });
    }
  }, [error]);

  return (
    <ListItem secondaryAction={getSecondaryAction()}>
      <ListItemText
        primary="Current Plan"
        secondary={
          isFetching ? (
            <CircularProgress size={16} />
          ) : (
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {plan?.plan_name || ""}
              </Typography>
              <RefreshSubscriptionButton />
              {isCancelledPlan && planEndsOn ? (
                <Typography variant="caption">
                  Subscription will end on{" "}
                  {new Date(planEndsOn * 1000).toLocaleString("default", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </Typography>
              ) : null}
              <PerpetualLicenseChecker showButton />
            </>
          )
        }
      />
    </ListItem>
  );
};

export default UserSubscription;
