import { Button, CircularProgress, Typography } from "@meetin/uicore";
import { GenerateIcon } from "@meetin/uicore/icons";

interface Props {
  checkRelevancy: () => Promise<void>;
  isLoading: boolean;
}

export const GenerateInsightsButton = ({
  checkRelevancy,
  isLoading,
}: Props) => (
  <Button
    onClick={() => checkRelevancy()}
    variant="outlined"
    color="primary"
    size="small"
    disabled={isLoading}
    sx={{
      "&:hover": {
        backgroundColor: "#D6E0FF",
        border: "1px solid #DAE1F9",
      },
      position: "absolute",
      top: "-43px",
      left: "0",
      right: "0",
      marginLeft: "auto",
      marginRight: "auto",
      backgroundColor: "#eef1fb",
      border: "1px solid #DAE1F9",
      width: "180px",
      boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.08)",
      borderRadius: "99px",
      paddingTop: "6px",
      paddingBottom: "6px",
    }}
  >
    {isLoading ? (
      <CircularProgress color="primary" size={19} sx={{ marginRight: "4px" }} />
    ) : (
      <GenerateIcon
        style={{
          marginRight: "4px",
          height: "19px",
          width: "19px",
          color: "rgba(34, 83, 231, 1)",
        }}
      />
    )}
    <Typography
      variant="body2"
      sx={{
        color: "rgba(34, 83, 231, 1)",
        fontWeight: "500",
        fontSize: "13px",
        lineHeight: "15px",
        letterSpacing: "-0.5px",
        whiteSpace: "nowrap",
      }}
    >
      Generate Page Insights
    </Typography>
  </Button>
);
