import { Collection, Profile, SupabaseClientType } from "@meetin/shared";

type GetUserCollectionsQuery = {
  userId?: Profile["user_id"];
};
export const getUserCollections = async (
  supabase: NonNullable<SupabaseClientType>,
  { userId }: GetUserCollectionsQuery
): Promise<Collection[]> => {
  if (!userId) {
    throw new Error("Invalid user");
  }
  const [
    { data, error },
    { data: collaboratingCollections, error: colloratorError },
  ] = await Promise.all([
    supabase
      .from("collections")
      .select()
      .eq("created_by", userId)
      .order("created_at", { ascending: false }),
    supabase
      .from("collection_collaborators")
      .select("collection_id(*)")
      .eq("user_id", userId)
      // sort by time added to my collection
      .order("created_at", { ascending: false }),
  ]);

  if (error) {
    throw new Error(error.message);
  }
  if (colloratorError) {
    throw new Error(colloratorError.message);
  }
  return [
    ...(data || []),
    ...(collaboratingCollections || [])
      .map((c) => c.collection_id as Collection[])
      // Filter null values
      .flatMap((c) => c || []),
  ];
};
