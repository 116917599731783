import { createSelector } from "@reduxjs/toolkit";
import { rtkApi, Profile, ProfileFields } from "@meetin/shared";
import { Database } from "@meetin/supabase";
import { SupabaseClientHelper } from "../../supabase/client";
import { clientLogger } from "../../logger";
import { ANONYMOUS_USER_ID } from "../../app";

type ProfileQueryProps = {
  userId: string;
};

type ProfilesBySlackUserIdsQueryProps = {
  slackUserIds: Profile["slack_user_id"][];
};

type UpdateProfileProps = {
  profile: Partial<Database["public"]["Tables"]["profiles"]["Update"]>;
};

export const profileApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    // update my profile
    updateProfile: builder.mutation<null, UpdateProfileProps>({
      invalidatesTags: ["CurrentUserProfile"],
      queryFn: async ({ profile }: UpdateProfileProps, api) => {
        const userId =
          // @ts-expect-error TODO: set correct type for getstate
          api.getState().api.queries["getCurrentUserProfile(undefined)"].data
            .user_id;
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!profile || !userId) {
          clientLogger.error(`could not update profile`, {
            profile,
            userId,
          });

          throw new Error("Please try again later");
        }

        clientLogger.info("updating profile");
        const { error } = await supabaseClient
          .from("profiles")
          .update(profile)
          .eq("user_id", userId);

        if (error) {
          throw new Error(error.message);
        }

        return { data: null };
      },
    }),
    getCurrentUserProfile: builder.query<Profile | null, undefined>({
      providesTags: ["CurrentUserProfile"],
      queryFn: async (_, api) => {
        const userId =
          // @ts-expect-error TODO: set correct type for getstate
          api.getState().api.queries["getCurrentUserProfile(undefined)"].data
            ?.user_id;
        if (!userId) {
          return { data: null };
        }
        await chrome.runtime.sendMessage({
          type: "DELETE_CACHE",
          message: `profile-${userId}`,
        });
        const { data, error: profileError } = await chrome.runtime.sendMessage({
          type: "GET_PROFILE",
          message: userId,
        });
        if (profileError) {
          clientLogger.error(
            "[getCurrentUserProfile] unable to get profile",
            undefined,
            profileError
          );
          throw new Error(profileError.message);
        }
        return { data };
      },
    }),
    getUserProfile: builder.query<Profile | null, ProfileQueryProps>({
      queryFn: async ({ userId }: ProfileQueryProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!userId) {
          return { data: null };
        }
        const { data, error } = await supabaseClient
          .from("profiles")
          .select(ProfileFields)
          .eq("user_id", userId)
          .single();

        if (error || !data) {
          throw new Error(error.message);
        }
        return { data };
      },
    }),
    getUserProfilesBySlackUserId: builder.query<
      Profile[] | null,
      ProfilesBySlackUserIdsQueryProps
    >({
      queryFn: async ({ slackUserIds }: ProfilesBySlackUserIdsQueryProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!slackUserIds?.length) {
          return { data: null };
        }
        const { data, error } = await supabaseClient
          .from("profiles")
          .select(ProfileFields)
          .in("slack_user_id", slackUserIds);

        if (error || !data) {
          throw new Error(error.message);
        }
        return { data };
      },
    }),
  }),
});

export const {
  useUpdateProfileMutation,
  useGetCurrentUserProfileQuery,
  useGetUserProfileQuery,
  useGetUserProfilesBySlackUserIdQuery,
} = profileApi;

export const selectCurrentUser =
  profileApi.endpoints.getCurrentUserProfile.select(undefined);

export const selectIsAnonymous = createSelector(
  selectCurrentUser,
  (user) => user.data?.user_id === ANONYMOUS_USER_ID
);
