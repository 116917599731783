import { Member } from "@slack/web-api/dist/response/UsersListResponse";
import { Channel } from "@slack/web-api/dist/response/ConversationsListResponse";
import { Team } from "./team";
import { ProfileExtended } from "./user";

export enum SlackPopupEvents {
  AuthComplete = "auth-complete",
}

export type SlackOAuthSuccessResponse = {
  token: string;
  user: ProfileExtended;
  team: Team;
};

export type SlackMember = Member & {
  id: NonNullable<Member["id"]>;
  display: NonNullable<Member["name"]>;
};
export type SlackChannel = Channel & {
  id: NonNullable<Member["id"]>;
  display: NonNullable<Member["name"]>;
};
