import { Database } from "@meetin/supabase";

export type Collection = Database["public"]["Tables"]["collections"]["Row"];

export type PostIdCollectionId =
  Database["public"]["Tables"]["post_id_collection_id"]["Row"];

export enum CollectionVisibility {
  Private = "Private",
  Public = "Public",
}
