import { Box, CircularProgress, Link, Stack, styled } from "@meetin/uicore";
import { useContext, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Post } from "@meetin/shared";
import { clientLogger } from "../logger";
import { AppContext } from "modules/app/AppProvider";
import { PostsList, getImageUrl, CopyLinkButton } from "@meetin/components";
import { useAppDispatch } from "store/hooks";

const PostWrapper = styled(Box)(({ theme }) => ({}));

/**
 * This page will show up for
 * - users coming via slack link
 * - users coming via shared link
 *
 * Different use cases
 * User has extension
 * User has extension, but not logged in
 * User dont have extension
 */
const PostPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { postId, commentId } = useParams();
  const [searchParams] = useSearchParams();
  const { hasExtension, supabaseClient, user } = useContext(AppContext);
  const [post, setPost] = useState<Post | null>(null);
  const dispatch = useAppDispatch();
  const referrerId = searchParams.get("u");
  const userId = user?.user_id;

  useEffect(() => {
    if (!supabaseClient || !postId) {
      return;
    }

    setIsLoading(true);
    clientLogger.info(`fetching post: ${postId}`);
    supabaseClient
      .from("posts")
      .select()
      .eq("id", postId)
      .single()
      .then(({ data, error }) => {
        if (error) {
          clientLogger.error(`Unable to get post: ${postId}`, {
            error: error.message,
          });
        }

        setPost(data);
        setIsLoading(false);
      });
  }, [postId, supabaseClient]);

  useEffect(() => {
    if (!supabaseClient || !referrerId || !post?.page_url) {
      return;
    }
    // If this is shared link, and no extension dont do anything
    if (!hasExtension) {
      return;
    }

    // For share links, and user has extension with valid user account, update access and then redirect
    if (userId) {
      clientLogger.info(
        `validating post: ${post.id} access referrer: ${referrerId}`
      );
      supabaseClient.functions
        .invoke<Post>("validate-post-access", {
          body: { postId: post.id, referrerId },
        })
        .then(({ data, error }) => {
          if (error) {
            clientLogger.error(`Unable to get post: ${post.id}`, {
              error: error.message,
            });
            return;
          }

          if (!data) {
            clientLogger.error(`Unable to get post: ${post.id} null data`);
            return;
          }

          clientLogger.info(
            `validated post: ${post.id} access referrer: ${referrerId}, dispatching event`
          );
          window.dispatchEvent(
            new CustomEvent("store-post", {
              detail: { postId: post.id, commentId, url: post.page_url },
            })
          );
        });
      return;
    }
  }, [
    commentId,
    hasExtension,
    post?.page_url,
    post?.id,
    referrerId,
    supabaseClient,
    userId,
  ]);

  useEffect(() => {
    if (!post?.page_url) {
      return;
    }

    if (!referrerId) {
      window.dispatchEvent(
        new CustomEvent("store-post", {
          detail: { postId, commentId, url: post.page_url },
        })
      );
    }
  }, [post, postId, commentId, referrerId, hasExtension]);

  if (isLoading) {
    return (
      <PostWrapper>
        <CircularProgress />
      </PostWrapper>
    );
  }
  if (!hasExtension && post?.page_url) {
    clientLogger.debug(`rendering post: ${post?.id}`);
    return (
      <PostWrapper>
        <Stack direction={{ xs: "column-reverse", md: "row" }}>
          <Box
            sx={{
              width: 400,
              pt: 1,
              pr: 1,
              textAlign: "right",
              "& .MuiGrid2-root": { m: 0 },
              "& .MuiCard-root": {
                border: "none !important",
                boxShadow: "none",
                backdropFilter: "none",
                p: 0,
                "&:hover": {
                  background: "none",
                },
              },
            }}
          >
            <CopyLinkButton
              postId={post.id}
              ignoreUserIdCheck
              buttonText="Share link"
            />
            <PostsList
              posts={[post]}
              dispatch={dispatch}
              userId={user?.user_id || ""}
            />
          </Box>
          <Stack
            sx={{
              background: "#F0F3F5",
              px: 3.5,
              py: 2,
              flex: 1,
              maxHeight: "calc(100vh - 64px)",
              justifyContent: "center",
            }}
          >
            {post.image_path ? (
              <img
                style={{
                  objectFit: "contain",
                  maxHeight: "90%",
                  maxWidth: "100%",
                  boxShadow: "0px 4px 20px 0px rgba(0, 0, 0, 0.25)",
                  borderRadius: 3,
                }}
                src={getImageUrl(post.image_path)}
                alt="Post screenshot"
              />
            ) : null}
            <Link href={post.page_url} sx={{ mt: 2, color: "#00050FD9" }}>
              Click here to see the comment in the webpage
            </Link>
          </Stack>
        </Stack>
      </PostWrapper>
    );
  }

  clientLogger.debug(`redirecting post: ${post?.id}`);

  return (
    <PostWrapper>
      Redirecting {post?.page_url ? `to ${post.page_url}` : ""}
    </PostWrapper>
  );
};

export default PostPage;
