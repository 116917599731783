// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useOnlineEffect } from "react-network-detect";
import {
  AppDispatch,
  rtkApi,
  Collection,
  PostWithCollections,
  Profile,
  SlackChannel,
  SlackMember,
} from "@meetin/shared";
import { useMemo, useCallback, useContext } from "react";
import {
  useGetPostsInPageByChannelsQuery,
  useGetPostsInPageByCollectionsQuery,
  useGetPostsInPageByUserIdQuery,
} from "./rtkPostsQueries";
import { ComponentsContext } from "../../common/ComponentsProvider";

type QueryProps = {
  url: string;
  channels: (SlackChannel["id"] | SlackMember["id"])[];
  collectionIds?: Collection["id"][];
  userId: Profile["user_id"];
  dispatch: AppDispatch;
};

/**
 * hook to get posts in page
 * this will get data based on posted by user, slack channels etc
 * */
const usePostsInPage = ({
  url,
  channels,
  userId,
  dispatch,
  collectionIds,
}: QueryProps) => {
  const { pollingInterval } = useContext(ComponentsContext);

  const { isOnline } = useOnlineEffect();
  const { data: postsByUser } = useGetPostsInPageByUserIdQuery(
    { url, userId },
    {
      skip: !url || !userId || !isOnline,
      // for getting latest data
      refetchOnFocus: true,
    }
  );

  const { data: postsByChannels } = useGetPostsInPageByChannelsQuery(
    { url, channels },
    {
      skip: !url || !channels.length || !isOnline,
      refetchOnFocus: true,
      // Need polling for subscription disabled case
      pollingInterval,
    }
  );

  // get posts in collections that i am part of
  // could be collection created by me or shared with me
  const { data: postsByCollections } = useGetPostsInPageByCollectionsQuery(
    { url, collectionIds },
    {
      skip: !url || !collectionIds?.length || !isOnline,
      refetchOnFocus: true,
      // Need polling for subscription disabled case
      pollingInterval,
    }
  );

  // filter posts by id and send as single array
  const posts = useMemo(() => {
    const myMentionsById: Record<string, PostWithCollections> =
      postsByChannels?.reduce(
        (
          acc: Record<string, PostWithCollections>,
          post: PostWithCollections
        ) => {
          if (post) {
            acc[post.id] = post;
          }
          return acc;
        },
        {}
      ) || {};

    const postsInCollectionsById: Record<string, PostWithCollections> =
      postsByCollections?.reduce(
        (
          acc: Record<string, PostWithCollections>,
          post: PostWithCollections
        ) => {
          if (post) {
            acc[post.id] = post;
          }
          return acc;
        },
        {}
      ) || {};

    const myPostsById: Record<string, PostWithCollections> =
      postsByUser?.reduce(
        (
          acc: Record<string, PostWithCollections>,
          post: PostWithCollections
        ) => {
          if (post) {
            acc[post.id] = post;
          }
          return acc;
        },
        {}
      ) || {};

    const uniqueIds = [
      ...new Set([
        ...Object.keys(myMentionsById),
        ...Object.keys(myPostsById),
        ...Object.keys(postsInCollectionsById),
      ]),
    ];
    return uniqueIds.map(
      (id) =>
        myMentionsById[id] || myPostsById[id] || postsInCollectionsById[id]
    );
  }, [postsByChannels, postsByUser, postsByCollections]);

  const refetch = useCallback(() => {
    dispatch(
      rtkApi.util.invalidateTags([
        "getPostsInPageByChannels",
        "getPostsInPageByUserId",
      ])
    );
  }, [dispatch]);

  return { posts, refetch };
};

export default usePostsInPage;
