import { CopyIcon } from "@meetin/uicore/icons";
import { useContext } from "react";
import { Post } from "@meetin/shared";
import { Button, IconButton, showSuccessNotification } from "@meetin/uicore";
import { clientLogger } from "../../logger";
import { ComponentsContext, Tooltip } from "../../common";
import { getPostPublicReferralLink } from "../helpers/link";

type Props = {
  postId: Post["id"];
  ignoreUserIdCheck?: boolean;
  buttonText?: string;
};

const CopyLinkButton = ({
  postId,
  ignoreUserIdCheck,
  buttonText,
}: Props): JSX.Element | null => {
  const { user, webAppUrl } = useContext(ComponentsContext);

  const handleClick = async () => {
    if (!ignoreUserIdCheck && !user?.user_id) {
      return;
    }
    const shareLink = `${webAppUrl}${getPostPublicReferralLink(
      postId,
      user?.user_id || ""
    )}`;
    clientLogger.info(`copying link: ${shareLink}`);
    navigator.clipboard.writeText(shareLink);
    showSuccessNotification({ message: "Link copied successfully!" });
  };

  if ((!ignoreUserIdCheck && !user?.user_id) || !webAppUrl) {
    return null;
  }

  return (
    <Tooltip title="Copy link to share" placement="top">
      {buttonText ? (
        <Button sx={{ color: "rgba(0, 0, 0, 1)" }} onClick={handleClick}>
          {buttonText}
        </Button>
      ) : (
        <IconButton onClick={handleClick}>
          <CopyIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

export default CopyLinkButton;
