import { useState } from "react";
import { Box } from "@meetin/uicore";
import { GlobeIcon } from "@meetin/uicore/icons";

const FavIcon = ({ url }: { url: string }) => {
  const [imageUrl, setImageUrl] = useState(url);

  const onImageLoadError = () => {
    setImageUrl(GlobeIcon);
  };

  return (
    <Box
      sx={{
        backgroundSize: "contain",
        minWidth: 15,
        mr: 0.875,
        borderRadius: "3px",
        "& img": {
          maxWidth: "15px",
          maxHeight: "15px",
        },
      }}
      aria-label={`${url} icon`}
    >
      <img onError={onImageLoadError} src={imageUrl} alt={`${url} icon`} />
    </Box>
  );
};
export default FavIcon;
