import { rtkApi, PageOGDetails } from "@meetin/shared";
import { SupabaseClientHelper } from "../../supabase";
import { clientLogger } from "../../logger";
import { getUrlWithoutHash } from "../utils";
import { getOGData } from "../helpers";

type OGDetailsBuUrlQueryProps = {
  url: string;
};

export const ogDetailsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getOGDetails: builder.query<PageOGDetails | null, OGDetailsBuUrlQueryProps>(
      {
        queryFn: async ({ url }: OGDetailsBuUrlQueryProps) => {
          const supabaseClient = SupabaseClientHelper.getSupabaseClient();
          if (!url) {
            return { data: null };
          }
          clientLogger.debug(`loading og details ${url}`);

          if (url === getUrlWithoutHash(window.location.href)) {
            return { data: getOGData() as PageOGDetails };
          }
          const { data, error } = await supabaseClient
            .from("og_meta")
            .select()
            .eq("url", url);
          clientLogger.debug(`loaded og details for ${url}`, {
            data,
            error,
          });

          if (error) {
            clientLogger.error(`error og details for ${url}`, {
              error: error.message,
            });
            throw new Error(error.message);
          }

          return { data: data?.[0] || null };
        },
      }
    ),
  }),
});

export const { useGetOGDetailsQuery } = ogDetailsApi;
