import { Box, Typography, styled } from "@meetin/uicore";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { clientLogger } from "../logger";
import ExtensionButton from "../extensionButton/ExtensionButton";
import { isExtensionLoaded } from "modules/app/utils";

const Wrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
const InvitePage = () => {
  let [searchParams] = useSearchParams();
  const url = searchParams.get("url");
  const userId = searchParams.get("userId");
  const sessionId = searchParams.get("sessionId");
  const tabId = searchParams.get("tabId");

  const [hasExtension, setHasExtension] = useState(isExtensionLoaded());

  useEffect(() => {
    const timer = setInterval(() => {
      setHasExtension(isExtensionLoaded());
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (!hasExtension) {
      return;
    }

    clientLogger.info("setting invite data", { url, userId, sessionId, tabId });
    window.dispatchEvent(
      new CustomEvent("store-invite", {
        detail: { url, userId, sessionId, tabId },
      })
    );
  }, [userId, hasExtension, url, sessionId, tabId]);

  if (!hasExtension) {
    return (
      <Wrapper>
        <Typography variant="h6">
          You need Layer extension to proceed.
        </Typography>
        <ExtensionButton />
      </Wrapper>
    );
  }

  return <Wrapper>Redirecting to {url}</Wrapper>;
};

export default InvitePage;
