import { createSelector } from "@reduxjs/toolkit";
import { rtkSubscriptionApi } from "./rtkSubscription";
import {
  DEFAULT_QUESTIONS_PER_DAY,
  DEFAULT_QUESTIONS_PER_WEEK,
} from "./constants";

export const selectUserSubscription =
  rtkSubscriptionApi.endpoints.getUserSubscriptionSettings.select(undefined);

export const selectServerDate =
  rtkSubscriptionApi.endpoints.getServerDate.select(undefined);
export const selectAllowedQuestionsPerDay = createSelector(
  selectUserSubscription,
  (subscription) =>
    subscription.data?.subscription?.plan?.settings?.questions_per_day ||
    DEFAULT_QUESTIONS_PER_DAY
);

export const selectAllowedQuestionsPerWeek = createSelector(
  selectUserSubscription,
  (subscription) =>
    subscription.data?.subscription?.plan?.settings?.questions_per_week ||
    DEFAULT_QUESTIONS_PER_WEEK
);
