const getFavIcon = (document: Document): string => {
  const getFaviconUrl = (relValue: string, sizes?: string): string | null => {
    const selector = sizes
      ? `link[rel*='${relValue}'][sizes='${sizes}']`
      : `link[rel*='${relValue}']`;
    const link = document.querySelector(selector);
    return link ? (link as HTMLLinkElement).href : null;
  };

  let faviconUrl =
    getFaviconUrl("apple-touch-icon", "180x180") ||
    getFaviconUrl("icon", "192x192") ||
    getFaviconUrl("shortcut icon") ||
    getFaviconUrl("icon") ||
    getFaviconUrl("apple-touch-icon") ||
    getFaviconUrl("mask-icon");

  if (!faviconUrl) {
    faviconUrl = `${window.location.origin}/favicon.ico`;
  }

  return faviconUrl;
};

export default getFavIcon;
