import {
  rtkApi,
  Collection,
  Post,
  PostIdCollectionId,
  Profile,
} from "@meetin/shared";
import { showErrorNotification } from "@meetin/uicore";
import { clientLogger } from "../../logger";
import { SupabaseClientHelper } from "../../supabase";
import { BackgroundCacheKeys } from "../../common/cacheKeys";
import { getUserCollections } from "./getCollections";
import { isExtension } from "../../common/utils";

type GetCollectionQueryProps = {
  userId?: Profile["user_id"];
};

type GetCollectionByPostQueryProps = {
  postId: Post["id"];
};

type CreateCollectionProps = {
  userId?: Profile["user_id"];
} & Pick<Collection, "title" | "objective">;

type DeleteCollectionFromPostProps = {
  post: Post["id"];
  collection: Collection["id"];
};

type AddCollectionsToPostProps = {
  post: Post["id"];
  collections: Collection["id"][];
};

type UpdateCollectionProps = {
  collection: Collection;
};

type GetCollectionByIdProps = {
  collectionId?: Collection["id"];
};

type DeleteCollectionByIdProps = {
  collectionId?: Collection["id"];
};
/**
 * Collections related supabase queries
 */
export const collectionsApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    // add collection to post
    addCollectionsToPost: builder.mutation<
      PostIdCollectionId[] | null,
      AddCollectionsToPostProps
    >({
      invalidatesTags: [
        "getPostsInPageByUserId",
        "getPostsInPageByChannels",
        "getPostsInPageByCollections",
        "getUnsortedPosts",
      ],
      queryFn: async ({ collections, post }: AddCollectionsToPostProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!collections || !post) {
          clientLogger.error(`could not add collections to post: ${post}`, {
            collections,
          });

          throw new Error("Please try again later");
        }

        const { data, error } = await supabaseClient
          .from("post_id_collection_id")
          .upsert(collections.map((c) => ({ post_id: post, collection_id: c })))
          .select();

        if (error) {
          clientLogger.error(`could not add collection to post: ${post}`, {
            error: error.message,
          });
          showErrorNotification({ message: error.message });
          throw new Error(error.message);
        }

        return { data };
      },
    }),
    // delete collection from post
    deleteCollectionFromPost: builder.mutation<
      null,
      DeleteCollectionFromPostProps
    >({
      invalidatesTags: [
        "getPostsInPageByUserId",
        "getPostsInPageByChannels",
        "getPostsInPageByCollections",
        "getUnsortedPosts",
      ],
      queryFn: async ({ collection, post }: DeleteCollectionFromPostProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!collection || !post) {
          clientLogger.error(
            `could not delete collection: ${collection} from post: ${post}`,
            {
              collection,
              post,
            }
          );

          throw new Error("Please try again later");
        }

        const { data, error } = await supabaseClient
          .from("post_id_collection_id")
          .delete()
          .eq("post_id", post)
          .eq("collection_id", collection);

        if (error) {
          clientLogger.error(
            `could not delete collection: ${collection} from post: ${post}`,
            { error: error.message }
          );
          throw new Error(error.message);
        }

        return { data };
      },
    }),
    // Create new collection
    createCollection: builder.mutation<Collection, CreateCollectionProps>({
      invalidatesTags: [{ type: "Collections", id: "LIST" }],
      queryFn: async ({ title, userId, objective }: CreateCollectionProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!userId) {
          clientLogger.error(`could not create new collection: ${title}`, {
            userId,
          });

          throw new Error("Please try again later");
        }

        const { data, error } = await supabaseClient
          .from("collections")
          .insert({ title, objective, created_by: userId })
          .select()
          .single();

        if (error) {
          throw new Error(error.message);
        }

        if (isExtension()) {
          clientLogger.info(
            "deleting user collections cache in background after create collection"
          );
          chrome.runtime.sendMessage({
            type: "DELETE_CACHE",
            message: BackgroundCacheKeys.USER_COLLECTIONS,
          });
        }

        return { data };
      },
    }),
    // delete a collection
    deleteCollection: builder.mutation<null, DeleteCollectionByIdProps>({
      invalidatesTags: [{ type: "Collections", id: "LIST" }],
      queryFn: async ({ collectionId }: DeleteCollectionByIdProps, api) => {
        const userId =
          // @ts-expect-error TODO: set correct type for getstate
          api.getState().api.queries["getCurrentUserProfile(undefined)"].data
            ?.user_id;

        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!userId) {
          throw new Error("Invalid user id");
        }

        if (!collectionId) {
          throw new Error("Invalid collection id");
        }

        clientLogger.info(`delete1 collection: ${collectionId}`);
        const { data, error } = await supabaseClient
          .from("collections")
          .delete()
          .eq("id", collectionId);

        if (error) {
          throw new Error(error.message);
        }

        if (isExtension()) {
          clientLogger.info(
            "deleting user collections cache in background after delete collection"
          );
          chrome.runtime.sendMessage({
            type: "DELETE_CACHE",
            message: BackgroundCacheKeys.USER_COLLECTIONS,
          });
        }

        return { data };
      },
    }),
    // fetch current user's collections
    getUserCollections: builder.query<
      Collection[] | null,
      GetCollectionQueryProps
    >({
      providesTags: (result) =>
        // is result available?
        result
          ? // successful query
            [
              ...result.map(({ id }) => ({ type: "Collections", id } as const)),
              { type: "Collections", id: "LIST" },
            ]
          : // an error occurred, but we still want to refetch this query when `{ type: 'Posts', id: 'LIST' }` is invalidated
            [{ type: "Collections", id: "LIST" }],
      queryFn: async ({ userId }: GetCollectionQueryProps) => {
        // For extension
        if (isExtension()) {
          const { error, data } = await chrome.runtime.sendMessage<
            { type: string; message: GetCollectionQueryProps },
            { error: string | null; data: Collection[] | null }
          >({
            type: "GET_COLLECTIONS",
            message: { userId },
          });

          if (error) {
            clientLogger.error("error while fetching user collections", {
              error,
            });
            throw new Error(error);
          }
          return { data };
        }

        // For web layer
        try {
          const response = await getUserCollections(
            SupabaseClientHelper.getSupabaseClient(),
            { userId }
          );
          return { data: response };
        } catch (error) {
          clientLogger.error(
            "error while fetching user collections",
            undefined,
            error as Error
          );
          // showErrorNotification({ message: (error as Error).message });
          throw new Error((error as Error).message);
        }
      },
    }),
    // get collections added in post
    getCollectionsByPost: builder.query<
      PostIdCollectionId[] | null,
      GetCollectionByPostQueryProps
    >({
      queryFn: async ({ postId }: GetCollectionByPostQueryProps) => {
        if (!postId) {
          throw new Error("Invalid post");
        }
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        clientLogger.debug(`getting collections for post: ${postId}`);
        const { data, error } = await supabaseClient
          .from("post_id_collection_id")
          .select()
          .eq("post_id", postId);

        if (error || !data) {
          clientLogger.error(`Unable to get post collections: ${postId}`, {
            error: error.message,
            data,
          });
          throw new Error(error.message);
        }
        return { data };
      },
    }),
    // Update collection
    updateCollection: builder.mutation<null, UpdateCollectionProps>({
      invalidatesTags: (result, error, { collection: { id } }) => [
        { type: "Collections", id },
      ],
      queryFn: async ({ collection }: UpdateCollectionProps) => {
        const supabaseClient = SupabaseClientHelper.getSupabaseClient();
        if (!collection) {
          clientLogger.error(`could not update collection`, { collection });

          throw new Error("Please try again later");
        }

        const { data, error } = await supabaseClient
          .from("collections")
          .update(collection)
          .eq("id", collection.id);

        if (error) {
          clientLogger.error(`could not update collection: ${collection.id}`, {
            error: error.message,
          });
          throw new Error(error.message);
        }

        if (isExtension()) {
          clientLogger.info(
            "deleting user collections cache in background after update collection"
          );
          chrome.runtime.sendMessage({
            type: "DELETE_CACHE",
            message: BackgroundCacheKeys.USER_COLLECTIONS,
          });
        }
        return { data };
      },
    }),

    // get single collection
    getCollectionById: builder.query<Collection | null, GetCollectionByIdProps>(
      {
        queryFn: async ({ collectionId }: GetCollectionByIdProps) => {
          if (!collectionId) {
            throw new Error("Invalid collection id");
          }
          const supabaseClient = SupabaseClientHelper.getSupabaseClient();

          clientLogger.debug(`get collection: ${collectionId}`);

          const { data, error } = await supabaseClient
            .from("collections")
            .select()
            .eq("id", collectionId)
            .single();

          if (error || !data) {
            clientLogger.error(
              `Unable to get collection by id: ${collectionId}`,
              {
                error: error.message,
                data,
              }
            );
            throw new Error(error.message);
          }
          return { data };
        },
      }
    ),
  }),
  overrideExisting: false,
});

export const {
  useDeleteCollectionMutation,
  useCreateCollectionMutation,
  useGetUserCollectionsQuery,
  useDeleteCollectionFromPostMutation,
  useAddCollectionsToPostMutation,
  useUpdateCollectionMutation,
  useGetCollectionByIdQuery,
} = collectionsApi;
