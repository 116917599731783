export const StyleMentionInput = {
  "&multiLine": {
    minHeight: "40px",
  },
  input: {
    border: "none",
    outline: "none",
    fontWeight: "500",
    fontSize: "15px",
    color: "rgba(0, 5, 15, 0.85)",
  },
  suggestions: {
    backgroundColor: "#fff",
    border: "1px solid rgba(0,0,0,0.1)",
    borderRadius: "8px",
    padding: "5px",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    minWidth: "150px",
    maxHeight: "200px",
    overflowY: "auto",
    item: {
      fontSize: "15px",
      padding: "4px 11px 4px 11px",
      borderRadius: "4px",
      fontWeight: "500",
      "&focused": {
        backgroundColor: "#F5F6F8",
      },
    },
  },
};
