import { setAnonymousUsage } from "../ai";
import { ReduxStoreHelper } from "../store";

export const AnonymousSettings = {
  CHAT: 15,
};

export const getAnonymousUsage = () => {
  Object.keys(AnonymousSettings).forEach((type) => {
    const key = `l-${type}`;
    const existingUsage = localStorage.getItem(key);
    if (type === "CHAT") {
      ReduxStoreHelper.getStore().dispatch(
        setAnonymousUsage(parseInt(existingUsage || "0", 10))
      );
    }
  });
};

export const incrementAnonymousUsage = (
  type: keyof typeof AnonymousSettings
) => {
  const key = `l-${type}`;
  const existingUsage = localStorage.getItem(key);
  const usage = existingUsage ? parseInt(existingUsage, 10) : 0;
  localStorage.setItem(key, (usage + 1).toString());

  return usage + 1;
};

export const incrementAnonymousChatUsage = () => {
  const usage = incrementAnonymousUsage("CHAT");
  ReduxStoreHelper.getStore().dispatch(setAnonymousUsage(usage));
};
