import { useRef, useContext, useState } from "react";
import { Stack } from "@meetin/uicore";
import { useSelector } from "react-redux";
import AiMessages from "./AiMessages";
import AskLayerForm from "./AskLayerForm";
import {
  selectActiveCollectionId,
  selectAiMessages,
} from "../redux/askLayerSelectors";
import { useCheckPageRelevancy } from "../useCheckRelevancy";
import { InsightsBlankState } from "../../../pageInsights/components";
import QuestionsLimit, { QuestionsLimitRef } from "./QuestionsLimit";
import { AskLayerQueryType } from "../types";
import { useCollections } from "../../../collections";
import { ComponentsContext } from "../../../common";

// type Props = {
//   disableWebBrowsing?: boolean;
// };
const AIComponent = () => {
  const { user } = useContext(ComponentsContext);
  const activeCollectionId = useSelector(selectActiveCollectionId);
  const userId = user?.user_id;
  const { selectedCollection } = useCollections({
    userId,
    collectionId: activeCollectionId ?? undefined,
  });
  const [hasAnyPendingGeneration, setHasAnyPendingGeneration] = useState(false);

  const chats = useSelector(selectAiMessages);
  const { checkRelevancy, isLoading: isCheckRelevancyLoading } =
    useCheckPageRelevancy();
  const questionLimitRef = useRef<QuestionsLimitRef>(null);

  const hasAnyChats = chats.length > 0;

  const onNewQuestionSubmit = () => {
    questionLimitRef.current?.onNewQuestionSubmit();
  };

  const handleCheckRelavancy = async () => {
    questionLimitRef.current?.onNewQuestionSubmit();

    checkRelevancy();
  };

  const handleGenerateInitialFindings = async () => {
    questionLimitRef.current?.onNewQuestionSubmit();

    setHasAnyPendingGeneration(false);
    checkRelevancy(AskLayerQueryType.AI_INITIAL_FINDINGS);
  };

  return (
    <Stack
      flex={1}
      sx={{
        position: "relative",
        pb: 0,
        paddingTop: "16px",
        overflowY: "auto",
        flexBasis: "auto",
      }}
    >
      {selectedCollection && hasAnyChats && !hasAnyPendingGeneration ? (
        <>
          <AiMessages />
          {/* floating bottom component - might want to move to a separate containing component */}
          <Stack
            sx={{
              position: "sticky",
              bottom: 0,
              left: 0,
              margin: "4px",
              filter: "drop-shadow(0px 1px 40px white)",
            }}
            border="1px solid #E5E5E5"
            borderTop="0"
            borderRadius="20px"
            boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.10)"
            bgcolor="white"
          >
            <AskLayerForm
              checkRelevancy={handleCheckRelavancy}
              isCheckRelevancyLoading={isCheckRelevancyLoading}
              isGenerateInsightsVisible={hasAnyChats}
              onNewQuestionSubmit={onNewQuestionSubmit}
            />
            <QuestionsLimit ref={questionLimitRef} />

            {/* <Box
              position="relative"
              sx={{
                borderTop: "1px solid #E5E5E5",
                whiteSpace: "nowrap",
                minHeight: 40,
                pr: 2,
              }}
            >
              {hasAnyChats && (
                <GenerateInsightsButton
                  checkRelevancy={handleCheckRelavancy}
                  isLoading={isLoading}
                />
              )}
              {!disableWebBrowsing ? <WebsiteSearcherToggle /> : null}
              <UsedQuestions />
            </Box> */}
          </Stack>
        </>
      ) : (
        <>
          <InsightsBlankState
            setHasAnyPendingGeneration={setHasAnyPendingGeneration}
            generateInitialFindings={handleGenerateInitialFindings}
          />
          <QuestionsLimit ref={questionLimitRef} />
        </>
      )}
    </Stack>
  );
};

export default AIComponent;
