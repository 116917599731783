import { CircularProgress } from "@meetin/uicore";
import { AppDispatch, Profile } from "@meetin/shared";
import { useGetPostsInPageByUserIdQuery } from "./hooks";
import PostsList from "./components/PostsList";

type Props = {
  userId: NonNullable<Profile["user_id"]>;
  dispatch: AppDispatch;
  ignorePostClick?: boolean;
};
const AllPostsView = ({ userId, dispatch, ignorePostClick }: Props) => {
  const { data, isFetching } = useGetPostsInPageByUserIdQuery(
    { url: "", userId, ignoreUrl: true },
    { skip: !userId }
  );

  if (isFetching) {
    return <CircularProgress size={20} />;
  }

  return (
    <PostsList
      posts={data}
      dispatch={dispatch}
      userId={userId}
      ignorePostClick={ignorePostClick}
    />
  );
};

export default AllPostsView;
