import "pdfjs-dist/web/pdf_viewer.css";
import * as pdfjsViewer from "pdfjs-dist/web/pdf_viewer.js";
import * as pdfjs from "pdfjs-dist";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker?worker";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pdfjsWorkerUrl from "pdfjs-dist/build/pdf.worker?url";
import { clientLogger } from "../logger";
import { PdfViewerEntryPoints } from "./constants";

export const pdfJsEventBus = new pdfjsViewer.EventBus();

export const initPdfJs = (entryType: PdfViewerEntryPoints) => {
  try {
    if (typeof window === "undefined" || !("Worker" in window)) {
      throw new Error("Web Workers not supported in this environment.");
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.pdfjsWorker = pdfjsWorker;
    // this file is build in vite.config.ts -> rollupOptions -> input
    switch (entryType) {
      case PdfViewerEntryPoints.CONTENT_SCRIPT:
        pdfjs.GlobalWorkerOptions.workerSrc =
          chrome.runtime.getURL("pdf-worker.js");
        break;
      case PdfViewerEntryPoints.WEB:
      case PdfViewerEntryPoints.SIDE_PANEL:
        pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorkerUrl;
        break;
      default:
        break;
    }
  } catch (error) {
    clientLogger.error(`Unable to load pdfjs`, undefined, error as Error);
  }
};
