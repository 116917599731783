import { useChat } from "ai/react";
import { useMemo, useState, useCallback } from "react";
import { showErrorNotification } from "@meetin/uicore";
import { AskLayerQueryProps } from "../rtkAskLayerQueries";
import { getEnvVariable, getUserToken } from "../../../app";
import { Reply } from "../types";
import { clientLogger } from "../../../logger";
import { getOGDataFromTab } from "../../../posts";
import { fetchFromApi } from "../../../api";

const useAICompletion = () => {
  const [isStreaming, setIsStreaming] = useState(false);
  const {
    isLoading: isFetching,
    append,
    messages,
  } = useChat({
    api: `${getEnvVariable("AI_API_URL")}/completion`,
    onError: (err) => {
      clientLogger.warn("useAICompletion onError", undefined, err);
      setIsStreaming(false);
    },
    onFinish: (message) => {
      if (!message.content) {
        clientLogger.error("useAICompletion onFinish no response", message);

        showErrorNotification({
          message: "Unable to find answer. Please try again later.",
        });
      }
      try {
        const response = JSON.parse(message.content);
        if (response.error) {
          showErrorNotification({
            message: response.error,
          });
        }
      } catch (e) {
        /* empty */
      }
      setIsStreaming(false);
    },
    onResponse() {},
  });

  const getJson = (message: string) => {
    try {
      return JSON.parse(message);
    } catch (e) {
      return null;
    }
  };
  // If reply is streaming, it needs to appended with right enclosures to parse as JSON
  const getJsonWithReply = useCallback(
    (message: string) =>
      getJson(`${message}"}`) || getJson(`${message}}`) || null,
    []
  );
  // If sources is streaming, it needs to appended with right enclosures to parse as JSON
  const getJsonWithSources = useCallback(
    (message: string) =>
      getJson(`${message}"]}`) ||
      getJson(`${message}]}`) ||
      getJson(`${message}"}`) ||
      null,
    []
  );

  const getAnswerFromMessage = useCallback(
    (message: string): null | Reply => {
      // If message does not start with answer, then answer is not streamed yet, so return null
      // we stream response only if it atleast contains '{\n  "answer": "'
      if (message?.length < 15) {
        return null;
      }
      try {
        // If complete JSON response, return parsed answer and sources
        return JSON.parse(message);
      } catch (e) {
        // if stream has complete answer, but still streaming sources, ignore sources and return answer
        if (message.includes('"sources":')) {
          // removes the comma at end
          const json = getJsonWithSources(message.replace(/,\s*$/, ""));
          if (json) {
            return json;
          }
          return getAnswerFromMessage(
            `${message.split('"sources":')[0].replace('",\n  ', "")}}`
          );
        }

        // If not a JSON response, then return empty string as json
        if (!message.includes('"answer":')) {
          return { answer: "", relevant: false, chunks: 0 };
        }
      }

      // if stream is still streaming answer, send answer string
      const json = getJsonWithReply(message);
      return json?.answer ? json : { answer: message.split('"answer": "')[1] };
    },
    [getJsonWithReply, getJsonWithSources]
  );

  const cleanResponse = (response: Reply | null) =>
    response
      ? {
          ...response,
          answer: response?.answer?.trim(),
        }
      : response;

  const answer: Reply | null = useMemo(() => {
    if (!messages?.length) {
      return null;
    }
    const lastMessage = messages[messages.length - 1];
    if (lastMessage.role !== "assistant") {
      return null;
    }
    return cleanResponse(getAnswerFromMessage(lastMessage.content));
  }, [getAnswerFromMessage, messages]);

  const onSubmit = async (data: AskLayerQueryProps) => {
    const token = await getUserToken();

    if (!token) {
      throw new Error("Missing authorization");
    }

    setIsStreaming(true);

    fetchFromApi("save-og-data", { data: await getOGDataFromTab() });

    return append(
      { content: data.query, role: "user" },
      {
        options: {
          body: data,
          headers: {
            authorization: token,
          },
        },
      }
    );
  };

  return {
    isStreaming,
    onSubmit,
    isFetching,
    answer,
  };
};

export default useAICompletion;
