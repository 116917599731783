import { useUpsertEmbeddingMutation } from "./rtkAskLayerQueries";
import { ReduxStoreHelper } from "../../store";

const useEmbedding = () => {
  const [triggerUpsertEmbedding, { isLoading }] = useUpsertEmbeddingMutation();

  const upsertEmbedding = ({
    url,
    markdown,
  }: {
    url: string;
    markdown: string;
  }) => {
    const isEmbeddingDone =
      // @ts-expect-error valid type
      ReduxStoreHelper.getStore().getState().askLayer.embeddingsResult[url];
    if (isEmbeddingDone) {
      return { isSuccess: true };
    }

    return triggerUpsertEmbedding({ url, markdown });
  };

  return { upsertEmbedding, isLoading };
};

export default useEmbedding;
