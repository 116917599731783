import { Chip, CircularProgress, Close } from "@meetin/uicore";
import { Collection, Post } from "@meetin/shared";
import { useDeleteCollectionFromPostMutation } from "../hooks";
import { AnalyticsEvents, trackEvent } from "../../analytics";

type Props = {
  collection: Collection;
  isPostOwner: boolean;
  postId?: Post["id"];
  onDelete?: () => void;
};

const CollectionChip = ({
  collection,
  isPostOwner,
  postId,
  onDelete,
}: Props): JSX.Element => {
  const [deleteCollection, { isLoading, isSuccess }] =
    useDeleteCollectionFromPostMutation();

  const handleDelete = () => {
    if (onDelete) {
      onDelete();
      return;
    }

    if (postId) {
      trackEvent(AnalyticsEvents.COLLECTION_REMOVED_COMMENT);

      deleteCollection({
        collection: collection.id,
        post: postId,
      });
    }
  };

  if (isPostOwner) {
    return (
      <Chip
        sx={{ maxWidth: 220, overflow: "hidden", textOverflow: "ellipsis" }}
        label={collection.title}
        deleteIcon={
          isLoading || isSuccess ? (
            <CircularProgress size={15} />
          ) : (
            <Close size={18} />
          )
        }
        onDelete={handleDelete}
      />
    );
  }
  return (
    <Chip
      sx={{ maxWidth: 220, overflow: "hidden", textOverflow: "ellipsis" }}
      label={collection.title}
    />
  );
};

export default CollectionChip;
