import { CollectionsIcon } from "@meetin/uicore/icons";
import {
  ListSubheader,
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  Box,
} from "@meetin/uicore";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { useCollections } from "../hooks";
import CollectionComponent from "./Collection";
import { clientLogger } from "../../logger";

type Props = Parameters<typeof useCollections>["0"] & { search?: string };
/**
 * shows list of my collections and my shared collections
 */
const CollectionsList = ({
  userId,
  collectionId,
  search,
}: Props): JSX.Element => {
  const { collections, isFetching, selectedCollection } = useCollections({
    userId,
    collectionId,
  });

  const filteredCollections = useMemo(() => {
    if (!search) {
      return collections;
    }

    return collections?.filter((c) => c.title.indexOf(search) > -1);
  }, [search, collections]);
  clientLogger.info(`got ${collections?.length} collections`);

  return (
    <List
      sx={{
        flex: 1,
        "& .MuiListItem-root": { py: 0.5, px: 2.1 },
        "& .MuiListItemIcon-root": { minWidth: 20, mr: 1 },
        "& .MuiListSubheader-root": {
          textAlign: "left",
          backgroundColor: "#fff",
          color: "rgba(0, 5, 15, 0.5)",
        },
      }}
    >
      <ListSubheader>Your Collections</ListSubheader>

      <Box sx={{ position: "relative" }}>
        {isFetching ? (
          <CircularProgress size={20} sx={{ position: "absolute" }} />
        ) : null}
        <ListItem
          component={Link}
          to="/collections/unsorted"
          sx={{
            background: "transparent",
            borderRadius: 1,
          }}
        >
          <ListItemIcon>
            <CollectionsIcon />
          </ListItemIcon>
          <ListItemText primary="Unsorted" sx={{}} />
        </ListItem>
        {filteredCollections
          ?.filter((c) => c.created_by === userId)
          .map((collection) => (
            <CollectionComponent
              key={collection.id}
              collection={collection}
              selectedCollection={selectedCollection}
            />
          ))}
        <ListSubheader>Shared Collections</ListSubheader>
        {filteredCollections
          ?.filter((c) => c.created_by !== userId)
          .map((collection) => (
            <CollectionComponent
              key={collection.id}
              collection={collection}
              selectedCollection={selectedCollection}
            />
          ))}
      </Box>
    </List>
  );
};

export default CollectionsList;
