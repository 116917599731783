import { useEffect, useContext } from "react";
import {
  ComponentsContext,
  useGetCustomApiKeyQuery,
  useSaveCustomApiKeyMutation,
} from "@meetin/components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  TextField,
} from "@meetin/uicore";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getPortalContainer } from "../../common/utils";

const schema = Yup.object({
  apiKey: Yup.string(),
}).required();

type Inputs = { apiKey: string | undefined };

type Props = {
  hideOnLoading: boolean;
  onClose: () => void;
};
const ApiKeyForm = ({ onClose, hideOnLoading }: Props) => {
  const { user } = useContext(ComponentsContext);
  const { data: currentApiKey, isFetching } = useGetCustomApiKeyQuery(
    undefined,
    { skip: !user?.user_id }
  );
  const { register, handleSubmit, setValue } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: { apiKey: currentApiKey || "" },
  });

  useEffect(() => {
    setValue("apiKey", currentApiKey || "");
  }, [currentApiKey, setValue]);
  const [saveApiKey, { isLoading }] = useSaveCustomApiKeyMutation();

  // If rendered from initializer, dont show loading screen
  if (hideOnLoading && isFetching) return null;

  return (
    <Dialog open container={getPortalContainer()}>
      <DialogTitle>Enter your api key</DialogTitle>
      <form onSubmit={handleSubmit(saveApiKey)}>
        <DialogContent sx={{ textAlign: "center" }}>
          {isFetching ? (
            <CircularProgress size={18} />
          ) : (
            <TextField
              {...register("apiKey", { required: true })}
              label="Your custom api key"
              variant="outlined"
              InputLabelProps={{}}
              InputProps={{
                sx: {
                  fontSize: "14px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderRadius: "7px",
                  },
                },
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={isFetching} onClick={onClose}>
            Close
          </Button>
          <LoadingButton
            variant="contained"
            disabled={isFetching}
            loading={isLoading}
            type="submit"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ApiKeyForm;
