import { useEffect } from "react";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  showErrorNotification,
} from "@meetin/uicore";
import CheckoutButton from "./CheckoutButton";
import {
  useGetAllPlansQuery,
  useGetUserSubscriptionSettingsQuery,
} from "./rtkSubscription";
import { getSupabaseErrorMessage } from "../supabase";

const ChangeSubscription = () => {
  const { data: currentSubscription } = useGetUserSubscriptionSettingsQuery(
    undefined,
    {}
  );

  const { data, error, isFetching } = useGetAllPlansQuery(undefined, {});

  useEffect(() => {
    if (error) {
      showErrorNotification({ message: getSupabaseErrorMessage(error) });
    }
  }, [error]);

  if (isFetching) {
    return <CircularProgress size={16} />;
  }
  return (
    <Box>
      <List>
        {data?.plans
          .filter((plan) => plan.price_id !== "free")
          .map((plan) => (
            <ListItem key={plan.price_id}>
              <CheckoutButton
                priceId={plan.price_id}
                planTitle={`Checkout ${plan.plan_name}`}
                disabled={
                  currentSubscription?.subscription?.plan.plan_name ===
                  plan.plan_name
                }
              />
            </ListItem>
          ))}
      </List>
    </Box>
  );
};

export default ChangeSubscription;
