import {
  List,
  ListItemButton,
  ListItemText,
  styled,
  Drawer,
  Box,
  Toolbar,
  Divider,
  AppBar,
  IconButton,
  Typography,
  MenuIcon,
} from "@meetin/uicore";
import {
  AllPostsButton,
  CollectionsList,
  AddNewCollectionButton,
} from "@meetin/components";
import { Outlet, useParams } from "react-router-dom";
import { AddToMyCollectionButton, ShareModal } from "@meetin/components";
import { AppContext } from "modules/app/AppProvider";
import { useContext, useState } from "react";
import ExtensionButton from "../extensionButton/ExtensionButton";

const drawerWidth = 240;

const Navigation = styled(List)<{ component?: React.ElementType }>({
  "&": {
    height: "100%",
    backgroundColor: "#F7F8F9",
    boxSizing: "border-box",
  },

  "& .MuiListItemButton-root, & .MuiListItem-root": {
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 32,
    color: "rgba(255,255,255,.8)",
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 8,
  },
  "& .MuiListItemText-primary": {
    color: "rgba(0, 0, 0, 0.8)",
    fontSize: 14,
    fontWeight: 500,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const Dashboard = (): JSX.Element => {
  const { user } = useContext(AppContext);
  const { collectionId } = useParams();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box>
      <Divider />
      <Navigation>
        <ListItemButton>
          <ListItemText primary="Search" />
        </ListItemButton>
        {user?.user_id ? (
          <AddNewCollectionButton userId={user.user_id} />
        ) : null}
        <AllPostsButton />

        <CollectionsList userId={user?.user_id} collectionId={collectionId} />
      </Navigation>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        color="default"
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, textAlign: "left" }}
          >
            <img src="/logo.svg" alt="Layer logo" style={{ width: 24 }} /> Layer
          </Typography>

          {user?.user_id ? null : (
            <>
              <Typography variant="body1" sx={{ mr: 1 }}>
                This is read only
              </Typography>
              <ExtensionButton />
            </>
          )}

          {user?.user_id && collectionId ? (
            <>
              <AddToMyCollectionButton
                collectionId={collectionId}
                userId={user.user_id}
              />
              <ShareModal collectionId={collectionId} userId={user.user_id} />
            </>
          ) : null}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              height: "calc(100% - 56px)",
              top: 56,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              top: 64,
              height: "calc(100% - 64px)",
            },
            mt: 8,
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, pt: 8 }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
