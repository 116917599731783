import { Tooltip as MUITooltip, TooltipProps } from "@meetin/uicore";
import { getPortalContainer } from "./utils";
import { ZIndex } from "./constants";

const Tooltip = ({
  children,
  zIndex = ZIndex,
  ...rest
}: { zIndex?: number } & TooltipProps) => (
  <MUITooltip
    {...rest}
    PopperProps={{
      container: getPortalContainer(),
      sx: { zIndex },
    }}
  >
    {children}
  </MUITooltip>
);

export default Tooltip;
