import { List, ListItem, Typography } from "@meetin/uicore";
import { theme } from "@meetin/uicore/src/theme/theme";
import { ListAltIcon } from "@meetin/uicore/icons";
import { AskLayerQueryType, AskLayerResponse } from "../types";

type Props = {
  data?: AskLayerResponse | null;
  chatType: AskLayerQueryType;
};
const AskLayerResponseComponent = ({ data, chatType }: Props) => {
  if (!data) {
    return null;
  }

  return (
    <List
      sx={{
        fontSize: "16px",
        lineHeight: 1.4,
        fontWeight: 400,
        padding: "0px 12px",
        margin: "8px 0 12px",
        fontFamily: theme.typography.fontFamily,
      }}
    >
      {chatType === AskLayerQueryType.AI_INITIAL_FINDINGS && (
        <Typography
          variant="body1"
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "17px",
            lineHeight: "15px",
            letterSpacing: "-0.5px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.9)",
            marginBottom: "11px",
            marginTop: "4px",
          }}
        >
          <ListAltIcon
            style={{
              marginRight: "8px",
            }}
          />
          Answer by ChatGPT
        </Typography>
      )}
      {chatType === AskLayerQueryType.AI_INSIGHTS && (
        <Typography
          variant="body1"
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "17px",
            lineHeight: "15px",
            letterSpacing: "-0.5px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.9)",
            marginBottom: "11px",
            marginTop: "4px",
          }}
        >
          <ListAltIcon
            style={{
              marginRight: "8px",
            }}
          />
          Summary of this page
        </Typography>
      )}
      {data.map((choice) => (
        <ListItem
          key={choice.index}
          sx={{ flexDirection: "column", alignItems: "baseline" }}
        >
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "pre-wrap",
              fontSize: "16px",
              lineHeight: "24px",
              fontWeight: 400,
              color: "rgba(0, 5, 15, 0.85)",
            }}
          >
            {choice.message.content.answer}
          </Typography>
        </ListItem>
      ))}
    </List>
  );
};

export default AskLayerResponseComponent;
