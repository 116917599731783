// eslint-disable-next-line check-file/filename-naming-convention
export const GreenTick = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none">
    <path
      fill="#34C759"
      d="M17.094 33.016c8.937 0 16.297-7.36 16.297-16.297C33.39 7.797 26.016.422 17.078.422 8.156.422.797 7.797.797 16.719c0 8.937 7.36 16.297 16.297 16.297Zm-1.735-8.532c-.64 0-1.14-.28-1.609-.859l-3.766-4.531c-.312-.39-.453-.766-.453-1.188a1.56 1.56 0 0 1 1.563-1.578c.515 0 .922.203 1.328.703l2.89 3.625 6.297-10.062c.375-.578.844-.89 1.391-.89.844 0 1.625.608 1.625 1.483 0 .376-.188.797-.422 1.157l-7.297 11.265c-.375.563-.922.875-1.547.875Z"
    />
  </svg>
);

export const PaperPlane = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} fill="none">
    <path
      fill="#34C759"
      d="M17.094 33.438c8.937 0 16.297-7.36 16.297-16.297C33.39 8.219 26.016.844 17.078.844 8.156.844.797 8.219.797 17.14c0 8.937 7.36 16.297 16.297 16.297Zm.453-6.204c-.86 0-1.188-.656-1.469-1.562l-1.344-4.344c-.171-.64-.125-1.062.266-1.484l9.063-9.875c.109-.125.125-.266.015-.375-.11-.078-.25-.11-.39 0l-9.844 9.11c-.469.421-.844.421-1.485.218l-4.437-1.344c-.875-.281-1.5-.625-1.5-1.453 0-.719.562-1.203 1.328-1.484l15.281-5.86c.375-.14.703-.219 1.016-.219.64 0 1.047.422 1.047 1.063 0 .297-.063.625-.219 1.016l-5.812 15.218c-.329.844-.813 1.375-1.516 1.375Z"
    />
  </svg>
);

export const Error = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34px"
    height="34px"
    viewBox="0 0 34 34"
    version="1.1"
  >
    <g id="surface1">
      <path
        style={{
          fillRule: "nonzero",
          fill: "rgb(204 18 51)",
          fillOpacity: 1,
          strokeWidth: 1,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          stroke: "rgb(204 18 51)",
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        d="M 12.88511 2.53125 C 12.537684 1.877757 11.462316 1.877757 11.11489 2.53125 L 2.11489 19.533088 C 1.952206 19.841912 1.960478 20.216912 2.142463 20.517463 C 2.324449 20.818015 2.649816 21 3 21 L 21 21 C 21.350184 21 21.675551 20.818015 21.857537 20.517463 C 22.036765 20.216912 22.047794 19.841912 21.882353 19.533088 Z M 13.000919 18 L 10.999081 18 L 10.999081 16.000919 L 13.000919 16.000919 Z M 10.999081 13.999081 L 10.999081 9 L 13.000919 9 L 13.000919 13.999081 Z M 10.999081 13.999081 "
        transform="matrix(1.416667,0,0,1.416667,0,0)"
      />
    </g>
  </svg>
);

export const Info = (): JSX.Element => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0938 33.0156C8.15625 33.0156 0.796875 25.6562 0.796875 16.7188C0.796875 7.79688 8.15625 0.421875 17.0781 0.421875C26.0156 0.421875 33.3906 7.79688 33.3906 16.7188C33.3906 25.6562 26.0312 33.0156 17.0938 33.0156ZM17.0312 11.5312C18.3125 11.5312 19.3281 10.4844 19.3281 9.20312C19.3281 7.89062 18.3125 6.875 17.0312 6.875C15.75 6.875 14.7031 7.89062 14.7031 9.20312C14.7031 10.4844 15.75 11.5312 17.0312 11.5312ZM14.1875 25.6719H20.7969C21.5781 25.6719 22.2031 25.125 22.2031 24.3125C22.2031 23.5625 21.5781 22.9531 20.7969 22.9531H19.1406V15.6875C19.1406 14.625 18.6094 13.9219 17.6094 13.9219H14.4844C13.7031 13.9219 13.0781 14.5312 13.0781 15.2812C13.0781 16.0781 13.7031 16.6406 14.4844 16.6406H16.0625V22.9531H14.1875C13.3906 22.9531 12.7812 23.5625 12.7812 24.3125C12.7812 25.125 13.3906 25.6719 14.1875 25.6719Z"
      fill="#999B9F"
    />
  </svg>
);
