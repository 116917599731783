import { SUPABASE_UPLOAD_BUCKET } from "@meetin/shared";
import { SupabaseClientHelper } from "../supabase";

export const formatTags = (str: string) =>
  str
    .replaceAll(/@\[[^\][]*\]\(([^()]*)\)/g, "<@$1>")
    .replaceAll(/#\[[^\][]*\]\(([^()]*)\)/g, "<#$1>");

export const hasChannelTag = (str: string) => /<#[^>]*>/.test(str);
export const hasUserTag = (str: string) => /<@[^>]*>/.test(str);

export const timeAgo = (timestamp?: string | null) => {
  if (!timestamp) {
    return "";
  }
  const ellapsedInMs = timestamp
    ? new Date().getTime() - new Date(timestamp).getTime()
    : undefined;
  const elapsedTimeInMinutes = ellapsedInMs
    ? ellapsedInMs / (1000 * 60)
    : undefined;
  const elapsedTimeInHours = ellapsedInMs
    ? ellapsedInMs / (1000 * 60 * 60)
    : undefined;
  const elapsedTimeInDays = ellapsedInMs
    ? ellapsedInMs / (1000 * 60 * 60 * 24)
    : undefined;

  if (elapsedTimeInMinutes && elapsedTimeInMinutes < 60)
    return `${Math.floor(elapsedTimeInMinutes)} min ago`;
  if (elapsedTimeInHours && elapsedTimeInHours < 24)
    return `${Math.floor(elapsedTimeInHours)} hr ago`;
  if (elapsedTimeInDays) return `${Math.floor(elapsedTimeInDays)} days ago`;
  return "";
};

export const positionWithinViewPort = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();

  if (rect.right > document.body.clientWidth) {
    element.style.left = `${rect.left - rect.width - 22}px`;
    element.classList.add("invert");
  }
};

export const getCurrentPageUrl = (): Promise<string | null> => {
  if (chrome.tabs?.query) {
    return new Promise((resolve) => {
      chrome.tabs.query({ active: true, currentWindow: true }, (tabs) => {
        if (tabs[0]?.url) {
          resolve(tabs[0].url);
          return;
        }

        resolve(null);
      });
    });
  }

  return Promise.resolve(window.location.href);
};

export const getUrlWithoutHash = (urlString: string) => {
  if (!urlString) {
    return "";
  }

  const url = new URL(urlString);
  url.hash = "";
  return url.toString();
};

export const getImageUrl = (imageName?: string): string => {
  if (!imageName) {
    return "";
  }
  const supabaseClient = SupabaseClientHelper.getSupabaseClient();
  const {
    data: { publicUrl },
  } = supabaseClient.storage
    .from(SUPABASE_UPLOAD_BUCKET)
    .getPublicUrl(imageName);

  return publicUrl;
};
