import { useSelector } from "react-redux";
import { LoadingButton, Box, Typography, Portal } from "@meetin/uicore";
import { clientLogger } from "../../../logger";
import { AiMessage, AskLayerQueryType } from "../types";
import { useCheckPageRelevancy } from "../useCheckRelevancy";
import { selectChildMessage } from "../redux/askLayerSelectors";
import { AiMessage as MessageComponent } from "./AiMessage";

type Props = {
  chat: AiMessage;
  portal: HTMLDivElement | null;
};

/**
 * Only for insights
 * if page is too long, we only process initial few paragraphs as firt chunk
 * and set flag shouldProcessOtherChunks in insights message
 * if this flag is true, show a button to get insights of whole page
 * once we get insights of whole page, reset the shouldProcessOtherChunks flag to false
 * Also render the new summary
 */
const ProcessOtherChunks = ({ chat, portal }: Props): JSX.Element | null => {
  const { checkRelevancy, isLoading } = useCheckPageRelevancy();
  const childMessage = useSelector(selectChildMessage(chat.requestId));
  const hasOtherChunks =
    chat.answer[0].message.content.chunks &&
    chat.answer[0].message.content.chunks > 1;

  const handleAllInsights = async () => {
    clientLogger.info("Getting insights of whole page");
    // Navigate to the tab before processing further
    await chrome.runtime.sendMessage({
      type: "NAVIGATE_TO_URL",
      message: chat.url,
    });
    checkRelevancy(AskLayerQueryType.AI_INSIGHTS_ALL, chat.requestId);
  };

  if (childMessage) {
    if (portal) {
      return (
        <Portal container={portal}>
          <MessageComponent chat={childMessage} />
        </Portal>
      );
    }
    return <MessageComponent chat={childMessage} />;
  }

  if (hasOtherChunks) {
    return (
      <Box sx={{ pl: 2.5, mt: 1 }}>
        <Typography
          variant="body1"
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "16px",
            lineHeight: 1.4,
            fontWeight: 400,
          }}
        >
          I did a quick scan since the page is quite long.
        </Typography>
        <LoadingButton
          loading={isLoading}
          onClick={handleAllInsights}
          sx={{
            color: "#000",
            p: 0,
            fontWeight: 400,
            fontSize: "16px",
            textAlign: "left",
          }}
        >
          Click here if you want me to read it all - It might take a bit longer.
        </LoadingButton>
      </Box>
    );
  }
  return null;
};

export default ProcessOtherChunks;
