import { createSelector } from "@reduxjs/toolkit";
import { AskLayerInitialState } from "./askLayerSlice";
import { AiMessage, AskLayerQueryType, SearchOperationStatus } from "../types";
import { UserPreferences } from "../../../app";

const selectAppState = (state: { app: { userPreferences: UserPreferences } }) =>
  state.app;

const selectAskLayerState = (state: { askLayer: AskLayerInitialState }) =>
  state.askLayer;

export const selectMarkdown = createSelector(
  selectAskLayerState,
  (state) => state.markdown
);

export const selectMarkdownUrl = createSelector(
  selectAskLayerState,
  (state) => state.url
);

export const selectDocumentMimeType = createSelector(
  selectAskLayerState,
  (state) => state.documentMimeType
);

export const selectActiveCollectionId = createSelector(
  selectAskLayerState,
  (state) => state.activeCollectionId
);

export const selectAiMessages = createSelector(selectAskLayerState, (state) =>
  Object.values(state.messages)
    .filter((message) => message.type !== AskLayerQueryType.AI_INSIGHTS_ALL)
    .sort((a, b) => (a.requestId > b.requestId ? 1 : -1))
);

export const selectInsightsAllMessages = createSelector(
  selectAskLayerState,
  (state) =>
    Object.values(state.messages).filter(
      (message) => message.type === AskLayerQueryType.AI_INSIGHTS_ALL
    )
);

export const selectAiChats = createSelector(selectAiMessages, (messages) =>
  messages.filter((message) => message.type === AskLayerQueryType.AI_SEARCH)
);

export const selectInsights = createSelector(selectAiMessages, (messages) =>
  messages.find((message) => message.type === AskLayerQueryType.AI_INSIGHTS)
);
export const selectInsightsAll = createSelector(selectAiMessages, (messages) =>
  messages.find((message) => message.type === AskLayerQueryType.AI_INSIGHTS_ALL)
);
export const selectMessageByRequestId = (requestId: AiMessage["requestId"]) =>
  createSelector(selectAiMessages, (chats) =>
    chats.find((c) => c.requestId === requestId)
  );
export const selectChildMessage = (requestId: AiMessage["requestId"]) =>
  createSelector(selectInsightsAllMessages, (chats) =>
    chats.find((c) => c.parentRequestId === requestId)
  );

export const selectStreamingMessage = createSelector(
  selectAiMessages,
  (messages) =>
    messages.find(
      (message) => message.status === SearchOperationStatus.STREAMING
    )
);
export const selectSearchAllPages = createSelector(selectAppState, (appState) =>
  Boolean(appState?.userPreferences?.searchAllPages)
);

export const selectSearchAllPagesByMessage = (
  requestId: AiMessage["requestId"]
) =>
  createSelector(selectAiChats, (chats) =>
    Boolean(chats.find((c) => c.requestId === requestId)?.searchAllPages)
  );

export const selectOperationsInMessage = (requestId: AiMessage["requestId"]) =>
  createSelector(
    selectAiMessages,
    (chats) => chats.find((c) => c.requestId === requestId)?.operations
  );

export const selectLatestOperationInMessage = (
  requestId: AiMessage["requestId"]
) =>
  createSelector(
    selectOperationsInMessage(requestId),
    (operations) => operations?.slice(-1)[0]
  );

export const selectSelectEmbeddingResultByUrl = (url: string) =>
  createSelector(selectAskLayerState, (state) => state.embeddingsResult[url]);

export const selectAnonymousUsage = createSelector(
  selectAskLayerState,
  (state) => state.anonymousUsage
);

export const selectIsPdfProcessing = createSelector(
  selectAskLayerState,
  (state) => state.isPdfProcessing
);
