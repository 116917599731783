import { ReduxStoreHelper } from ".";
import { clientLogger } from "../logger";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dispatchRtkQuery = async (action: any) => {
  const p = ReduxStoreHelper.getStore()?.dispatch(action);

  try {
    return (await p.unwrap()) as string;
  } catch (e) {
    clientLogger.error("error converting pdf to markdown", {
      error: (e as Error).message,
    });
  }

  return null;
};
