export enum Actions {
  COLLECTION_CREATE = "Collection create",
  COLLECTION_CREATE_FAIL = "Collection create failed",
  COLLECTION_CREATE_SUCCESS = "Collection create success",
  COMMENT_ANCHOR_SELECTED = "Comment anchor selected",
  COMMENT_BUTTON_CLICK = "Comment button click",
  COMMENT_MENTION_ADDED = "Comment mention added",
  COMMENT_RESOLVE_BUTTON_CLICKED = "Comment resolve button clicked",
  COMMENT_RESOLVE_ERROR = "Comment resolve error",
  COMMENT_RESOLVE_SUCCESS = "Comment resolve success",
  COMMENT_SUBMIT_API = "Comment submit api",
  COMMENT_SUBMIT_CLICKED = "Comment submit clicked",
  COMMENT_SUBMIT_FAIL = "Comment submit fail",
  COMMENT_SUBMIT_NO_ANCHOR = "Comment submit no anchor",
  COMMENT_SUBMIT_SUCCESS = "Comment submit success",
  EXTENSION_BUTTON_CLICKED = "Extension button show menu bar",
  INBOX_BUTTON_CLICKED = "Inbox button clicked",
  SHORTCUTS_CANCEL_NEW_COMMENT = "Shortcuts cancel new comment",
  SHORTCUTS_CANCEL_SELECTING_TARGET = "Shortcuts cancel selecting target",
  SHORTCUTS_ESCAPE_ENLARGE_MENU_BAR = "Shortcuts escape enlarge menu bar",
  SHORTCUTS_ESCAPE_HIDE_BAR = "Shortcurs escape hide menu bar",
  SHORTCUTS_ESCAPE_SELECTING_TARGET = "Shortcuts escape selecting target",
  SHORTCUTS_HIDE_BAR = "Shortcuts hide menu bar",
  SHORTCUTS_HIDE_POSTS = "Shortcuts hide posts",
  SHORTCUTS_SHOW_BAR = "Shortcuts show menu bar",
  SHORTCUTS_SHOW_POSTS = "Shortcuts show posts",
}
