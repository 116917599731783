import { Collection } from "@meetin/shared";
import { InitialFindingLinks } from "../../links/types";

export enum AskLayerQueryType {
  UPSERT_EMBEDDING,
  AI_SEARCH,
  AI_INSIGHTS,
  AI_HIGHLIGHTS,
  CRAWL_FOR_MARKDOWN,
  AI_CONSOLIDATE_ANSWER,
  GET_SERP_RESULTS,
  GET_KEYWORDS_FROM_QUESTION,
  AI_INSIGHTS_ALL,
  AI_GENERAL_QUESTION,
  AI_INITIAL_FINDINGS,
}

export type Reply = {
  answer: string;
  sources?: string[];
  relevant?: boolean;
  chunks?: number;
};

type OpenAiChoices = {
  index: number;
  message: {
    role: "assistant" | "user";
    content: Reply;
  };
  finish_reason: string;
};
export type AskLayerResponse = OpenAiChoices[];

export type AiMessage = {
  question: string;
  answer: AskLayerResponse;
  requestId: string;
  sources: string[];
  type:
    | AskLayerQueryType.AI_SEARCH
    | AskLayerQueryType.AI_INSIGHTS
    | AskLayerQueryType.AI_INSIGHTS_ALL
    | AskLayerQueryType.AI_GENERAL_QUESTION
    | AskLayerQueryType.AI_INITIAL_FINDINGS;
  searchAllPages?: boolean;
  url: string;
  operations?: SearchStatus[];
  searchQuery?: string;
  status: SearchOperationStatus;
  collectionId?: Collection["id"];
  parentRequestId?: string;
  links?: InitialFindingLinks[];
};

export type SERPItem = {
  title: string;
  snippet: string;
  link: string;
  favicon: string;
};
export type SERPApiResponse = {
  organic_results?: [SERPItem];
  searchQuery: string;
  error?: string;
};

export enum SearchOperation {
  NONE,
  GET_SERP_RESULTS,
  GET_MARKDOWN,
  GET_ANSWERS_PER_LINK,
  GET_CONSOLIDATED_ANSWER,
  COMPLETED,
}

export enum SearchOperationStatus {
  RUNNING,
  COMPLETED,
  STREAMING,
}

export type SearchStatus = {
  operation: SearchOperation;
  status: SearchOperationStatus;
  link: SERPItem | null;
};
