import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { CollaboratorInviteStatus, Collection, Profile } from "@meetin/shared";
import { LoadingButton, showSuccessNotification } from "@meetin/uicore";
import { useAddCollaboratorMutation } from "../collaborators/rtkCollaborators";
import { useCollections } from "../hooks";

type Props = {
  collectionId: Collection["id"];
  userId: Profile["user_id"];
};

/**
 * Adds public collection to my shared collections list
 */
const AddToMyCollectionButton = ({
  collectionId,
  userId,
}: Props): JSX.Element | null => {
  const [searchParams] = useSearchParams();
  const [addCollaborator, { isSuccess, isLoading }] =
    useAddCollaboratorMutation();

  const { selectedCollection: collection } = useCollections({
    userId,
    collectionId,
  });

  const handleClick = async () => {
    addCollaborator({
      collection_id: collectionId,
      invited_by: searchParams.get("u"),
      accepted_at: new Date().toDateString(),
      status: CollaboratorInviteStatus.Accepted,
      user_id: userId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccessNotification({ message: "Collection added successfully!" });
    }
  }, [isSuccess]);

  if (collection) {
    return null;
  }

  return (
    <LoadingButton loading={isLoading} onClick={handleClick}>
      Add to my collection
    </LoadingButton>
  );
};

export default AddToMyCollectionButton;
