import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const rtkApi = createApi({
  baseQuery: fetchBaseQuery(),
  tagTypes: [
    "CurrentUserProfile",
    "getPostsInCollection",
    "Collections",
    "getUserCollections",
    "Replies",
    "getPostsInPageByChannels",
    "getPostsInPageByUserId",
    "getPostsInPageByCollections",
    "getUnsortedPosts",
    "getSERPResults",
    "Subscription",
    "SubscriptionDetails",
    "CustomApiKey",
  ],
  endpoints: () => ({}),
});
