import { Collection } from "@meetin/shared";
import { useGetPostsInCollectionQuery } from "../../posts";

type Props = {
  collectionId?: Collection["id"];
  refetchOnFocus?: boolean;
};

const usePostsInCollection = ({
  collectionId,
  refetchOnFocus = true,
}: Props) => {
  const { data, isFetching } = useGetPostsInCollectionQuery(
    { collectionId },
    {
      skip: !collectionId,
      refetchOnFocus,
    }
  );

  return {
    data,
    isFetching,
  };
};

export default usePostsInCollection;
