import { Profile } from "@meetin/shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AppSliceInitialState = {
  user: Profile | null;
};

const initialState: AppSliceInitialState = {
  user: null,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AppSliceInitialState["user"]>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = appSlice.actions;

export const appReducer = appSlice.reducer;
