import { AuthResponse } from "@supabase/supabase-js";
import { clientLogger } from "../logger";

const refreshSession = async () => {
  if (!chrome.runtime.sendMessage) {
    return;
  }
  const { data, error } = await chrome.runtime.sendMessage<
    Record<string, unknown>,
    AuthResponse
  >({
    type: "REFRESH_SESSION",
    message: { refreshToken: "" },
    from: 1,
  });
  if (error || !data.session) {
    clientLogger.error(
      "[refreshSession] failed to refresh session",
      {
        data,
      },
      error || undefined
    );
  }

  clientLogger.info("[refreshSession] session refreshed", { data });
};

export const handleAPIError = (message: string) => {
  if (message === "JWT expired") {
    refreshSession();
  }
};
