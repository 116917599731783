import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";
import { Profile, SupabaseUser } from "@meetin/shared";

export const initUser = (user: Profile, supabaseUser: SupabaseUser): void => {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  const { user_id, ...rest } = user;

  const screenResolution = `${
    window.screen.width * window.devicePixelRatio
  } x ${window.screen.height * window.devicePixelRatio}`;

  const userData = { ...rest, ...supabaseUser };
  datadogRum.setUser({
    ...userData,
    name: userData.name || "",
    screenResolution,
    devicePixelRatio: window.devicePixelRatio,
  });

  datadogLogs.logger.addContext("user", userData);
  datadogLogs.logger.addContext("screenResolution", screenResolution);
  datadogLogs.logger.addContext("devicePixelRatio", devicePixelRatio);
};
