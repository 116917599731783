import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  showErrorNotification,
} from "@meetin/uicore";
import { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRightIcon } from "@meetin/uicore/icons";
import { clientLogger } from "../../logger";
import { Actions, trackActionEnd, trackActionStart } from "../../monitoring";
import { useCollections, useCreateCollectionMutation } from "../../collections";
import { ComponentsContext } from "../../common";
import { AnalyticsEvents, trackEvent } from "../../analytics";
import {
  clearMessages,
  selectActiveCollectionId,
  setActiveCollectionId,
} from "../../ai";

interface Props {
  setHasAnyPendingGeneration: (b: boolean) => void;
  generateInitialFindings: () => Promise<void>;
}

export const BlankStateSearchInput = ({
  generateInitialFindings,
  setHasAnyPendingGeneration,
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useContext(ComponentsContext);
  const [createCollection] = useCreateCollectionMutation();
  const [queuedCheckRelevancy, setQueuedCheckRelevancy] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [value, setValue] = useState("");
  const activeCollectionId = useSelector(selectActiveCollectionId);
  const { selectedCollection } = useCollections({
    userId: user?.user_id,
    collectionId: activeCollectionId ?? undefined,
  });

  useEffect(() => {
    if (queuedCheckRelevancy && selectedCollection) {
      setQueuedCheckRelevancy(false);
      generateInitialFindings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queuedCheckRelevancy, selectedCollection]);

  const handleCreateCollection = async (newCollectionTitle: string) => {
    dispatch(clearMessages());
    setHasAnyPendingGeneration(true);
    if (!user) {
      return null;
    }
    setIsProcessing(true);

    trackActionStart(Actions.COLLECTION_CREATE);
    clientLogger.info(`creating new collection: ${newCollectionTitle}`);
    const response = await createCollection({
      title: newCollectionTitle,
      userId: user.user_id,
      objective: `To get the latest, most relevant information about ${newCollectionTitle}`,
    });

    if ("error" in response) {
      clientLogger.error(
        "Unable to create new collection",
        undefined,
        response.error as Error
      );
      showErrorNotification({ message: (response.error as Error).message });
      trackActionEnd(
        Actions.COLLECTION_CREATE,
        Actions.COLLECTION_CREATE_FAIL,
        {
          error: (response.error as Error).message,
        }
      );
      return null;
    }

    trackActionEnd(
      Actions.COLLECTION_CREATE,
      Actions.COLLECTION_CREATE_SUCCESS,
      {
        collection: response.data.id,
      }
    );

    trackEvent(AnalyticsEvents.COLLECTION_CREATED, {
      collection_name: newCollectionTitle,
    });

    dispatch(setActiveCollectionId(response.data.id));
    setQueuedCheckRelevancy(true);

    return null;
  };

  return (
    <Stack
      sx={{
        position: "sticky",
        bottom: 0,
        left: 0,
        margin: "4px",
        filter: "drop-shadow(0px 1px 40px white)",
        padding: "0px 4px",
        width: "100%",
      }}
      border="1px solid #E5E5E5"
      borderTop="0"
      borderRadius="13px"
      boxShadow="0px 0px 10px 0px rgba(0, 0, 0, 0.10)"
      bgcolor="white"
    >
      <OutlinedInput
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            if (value.trim() !== "") {
              handleCreateCollection(value);
            }
          }
        }}
        size="medium"
        fullWidth
        //  handle a case where a question was already asked before and change placeholder
        placeholder="Type here..."
        sx={{
          fontSize: "16px",
          pr: 0,
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "& .MuiIconButton-edgeEnd": {
            marginRight: "0px",
          },
          "& input": {
            px: "10px",
            py: "12px",
          },
        }}
        disabled={isProcessing}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              size="small"
              disabled={isProcessing}
              onClick={() => {
                if (value.trim() !== "") {
                  handleCreateCollection(value);
                }
              }}
              sx={{ color: "#252A33" }}
            >
              <ArrowRightIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </Stack>
  );
};
