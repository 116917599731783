import { datadogLogs, LogsEvent } from "@datadog/browser-logs";
import { EnvironmentTypes } from "./types";

const { logger } = datadogLogs;
const ignoreErrors = [
  "ResizeObserver loop limit exceeded",
  "A listener indicated an asynchronous response",
];

export const initLogger = ({
  serviceName,
  clientToken,
  site,
  env,
  version,
  context = {},
}: {
  serviceName: string;
  clientToken: string;
  site: string;
  env: EnvironmentTypes;
  version: string;
  context?: Record<string, unknown>;
}) => {
  datadogLogs.init({
    clientToken,
    site,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    env,
    version,
    service: serviceName,
    beforeSend: (event: LogsEvent) => {
      // discard a error if its message includes 'ResizeObserver loop limit exceeded'
      if (ignoreErrors.find((e) => event.message.includes(e))) {
        return false;
      }
      return true;
    },
  });

  const isDev = env === EnvironmentTypes.Dev;
  datadogLogs.logger.setLevel(isDev ? "debug" : "info");
  datadogLogs.logger.setHandler(isDev ? ["console"] : "http");
  Object.keys(context).forEach((key) =>
    datadogLogs.logger.addContext(key, context[key])
  );
};

export default logger;
