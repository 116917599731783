import { useEffect, useState } from "react";
import { clientLogger } from "../../logger";

const usePageVisibility = (): boolean => {
  const [isVisible, setIsVisible] = useState(!document.hidden);
  const onVisibilityChange = () => {
    clientLogger.debug(`visibilitychange visible: ${!document.hidden}`);
    setIsVisible(!document.hidden);
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange, false);

    return () => {
      document.removeEventListener("visibilitychange", onVisibilityChange);
    };
  });

  return isVisible;
};

export default usePageVisibility;
