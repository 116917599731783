import { createSelector } from "@reduxjs/toolkit";
import { Post } from "@meetin/shared";
import { PostInitialState } from "./postSlice";

const selectPostState = (state: { post: PostInitialState }) => state.post;

export const selectSelectedPostId = createSelector(
  selectPostState,
  (appState) => appState.selectedPostId
);

export const selectIsSelectedPost = (postId: Post["id"]) =>
  createSelector(selectSelectedPostId, (selectedPostId) =>
    Boolean(postId && selectedPostId === postId)
  );

export const selectCurrentCollectionId = createSelector(
  selectPostState,
  (appState) => appState.currentCollectionId
);
