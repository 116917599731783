import {
  TextField,
  Typography,
  Stack,
  Box,
  CircularProgress,
} from "@meetin/uicore";
import { DropzoneArea } from "mui-file-dropzone";
import { AIComponent, clientLogger, setMarkdown } from "@meetin/components";
import { ChangeEvent, useState } from "react";
import PdfViewer from "./PdfViewer";
import { useAppDispatch } from "store/hooks";
import { useLazyGetPdfContentQuery } from "@meetin/components/src/pdf/rtkPdf";

const PdfAiPage = () => {
  const [file, setFile] = useState<ArrayBuffer | string | null>(null);
  const dispatch = useAppDispatch();
  const [getPdfContent, { isFetching }] = useLazyGetPdfContentQuery();

  const handleChange = async (files: File[]) => {
    if (!files[0]) {
      return;
    }
    const buffer = await files[0].arrayBuffer();
    setFile(buffer);
    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = async () => {
      const dataUri = reader.result;
      const { data, error } = await getPdfContent({ data: dataUri as string });
      if (error) {
        clientLogger.error(
          "error getting pdf content",
          undefined,
          error as Error
        );
        return;
      }
      dispatch(setMarkdown(data || ""));
    };
    reader.readAsDataURL(files[0]);
  };

  const handleUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFile(e.target.value);
  };

  return (
    <Box>
      <DropzoneArea
        fileObjects={[]}
        acceptedFiles={["application/pdf"]}
        onChange={handleChange}
        filesLimit={1}
      />
      <Typography variant="h6" sx={{ mt: 2 }}>
        OR
      </Typography>
      <TextField value={file} onChange={handleUrlChange} />

      {file && (
        <Stack direction="row">
          <Box sx={{ width: "60%", position: "relative" }}>
            {isFetching ? (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                  background: "rgba(255,255,255,0.5)",
                }}
              >
                <CircularProgress />
              </Box>
            ) : null}
            <PdfViewer file={file} />
          </Box>
          <Box sx={{ width: "40%" }}>
            <AIComponent />
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default PdfAiPage;
