import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popover,
  Switch,
  showErrorNotification,
  showSuccessNotification,
  CopyAll,
} from "@meetin/uicore";
import { Collection, Profile, CollectionVisibility } from "@meetin/shared";
import { useState, ChangeEvent, useEffect, MouseEvent } from "react";
import { clientLogger } from "../../logger";
import { useCollections, useUpdateCollectionMutation } from "../hooks";
import SwitchIcon from "./SwitchIcon";
import { ZIndex } from "../../common";

type Props = {
  userId: Profile["user_id"];
  collectionId: Collection["id"];
  container?: HTMLElement | null;
  webUrl?: string;
};

const ShareModal = ({
  userId,
  collectionId,
  container,
  webUrl,
}: Props): JSX.Element | null => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const shareLink = `${webUrl || window.location.href}?u=${userId}`;

  const { selectedCollection: collection } = useCollections({
    userId,
    collectionId,
  });

  const [updateCollection, { isLoading, error, isSuccess }] =
    useUpdateCollectionMutation();

  const isPublicCollection =
    collection?.visibility === CollectionVisibility.Public;

  useEffect(() => {
    if (error) {
      showErrorNotification({ message: (error as Error).message });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      showSuccessNotification({ message: "Collection updated successfully!" });
    }
  }, [isSuccess]);

  const showModal = (event: MouseEvent<HTMLButtonElement>) => {
    clientLogger.info("opening share modal", {
      userId,
      collectionId: collection,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!collection) {
      return;
    }
    await updateCollection({
      collection: {
        ...collection,
        visibility: event.target.checked
          ? CollectionVisibility.Public
          : CollectionVisibility.Private,
      },
    });
  };

  const handleClose = () => {
    clientLogger.info("closing share modal", { userId, collectionId });
    setAnchorEl(null);
  };

  const handleCopyLink = () => {
    if (!shareLink) {
      showSuccessNotification({ message: "Please try again!" });
      return;
    }

    navigator.clipboard.writeText(shareLink);
    showSuccessNotification({
      message: "Link copied successfully!",
      dismiss: { duration: 2000 },
    });
  };

  if (!collection) {
    return null;
  }

  return (
    <>
      <Button onClick={showModal}>Share</Button>
      <Popover
        container={container}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{ zIndex: ZIndex + 1 }}
        PaperProps={{ sx: { width: 300 } }}
      >
        <Box sx={{ p: 2 }}>
          <FormControlLabel
            control={
              <Switch
                checkedIcon={<SwitchIcon loading={isLoading} />}
                icon={<SwitchIcon loading={isLoading} />}
                checked={isPublicCollection}
                onChange={handleChange}
                disabled={collection.created_by !== userId}
              />
            }
            label="Public"
          />
          {/* show share link only if it is public collection */}
          {isPublicCollection ? (
            <Box sx={{ mt: 2 }}>
              <FormControl sx={{}} variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Link
                </InputLabel>
                <OutlinedInput
                  type="text"
                  value={shareLink}
                  readOnly
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleCopyLink}
                        edge="end"
                      >
                        <CopyAll />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Copy"
                />
              </FormControl>
            </Box>
          ) : null}
        </Box>
      </Popover>
    </>
  );
};

export default ShareModal;
