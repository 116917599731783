// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Box, Typography } from "@meetin/uicore";
// import { PageOGDetails } from "@meetin/shared";
// import { useGetOGDetailsQuery } from "../../posts/hooks/rtkOGQueries";
// import { getOGDataFromTab } from "../../posts";
// import { clientLogger } from "../../logger";
// import FavIcon from "../../posts/components/FavIcon";
import { InitialFindingLinks } from "../types";

interface Props {
  link: InitialFindingLinks;
}

export const LinkItem = ({ link }: Props) => {
  const parsedURL = new URL(link.url);

  // const dispatch = useDispatch();
  // const { data, isFetching } = useGetOGDetailsQuery({ url: link.url || "" });

  // useEffect(() => {
  //   if (isFetching || data) {
  //     return;
  //   }

  //   getOGDataFromTab()
  //     .then((details) => {
  //       dispatch(
  //         // @ts-expect-error valid type
  //         ogDetailsApi.util.updateQueryData(
  //           "getOGDetails",
  //           {
  //             url: link.url,
  //           },
  //           (_currentData: PageOGDetails | null) => details as PageOGDetails
  //         )
  //       );
  //       return data;
  //     })
  //     .catch((err) => {
  //       clientLogger.error("Error getting OG data from tab", {}, err);
  //     });
  // }, [data, isFetching, link, dispatch]);

  // if (!data) {
  //   return null;
  // }

  return (
    <Box
      sx={{
        backgroundColor: "rgba(238, 241, 243, 1)",
        borderRadius: "8px",
        padding: "8px",
        cursor: "pointer",
        width: "48%",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        "&:hover": {
          backgroundColor: "#E4E8EB",
        },
        "&:hover .link-title": {
          textDecoration: "underline",
        },
      }}
      onClick={() => {
        window.open(link.url, "_blank");
      }}
    >
      <Typography
        className="link-title"
        variant="body1"
        sx={{
          fontSize: "13px",
          fontWeight: "500",
          color: "rgba(38, 42, 50, 1)",
          lineHeight: "15px",
          letterSpacing: "-0.5px",
          display: "-webkit-box",
          "-webkit-line-clamp": "2",
          "-webkit-box-orient": "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {link.title}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: "12px",
          alignItems: "flex-start",
        }}
      >
        <img
          src={`https://s2.googleusercontent.com/s2/favicons?domain=${parsedURL.hostname}&sz=16`}
          alt="parsedURL.hostname"
          style={{ marginRight: "4px" }}
        />
        {/* {data.favIcon ? <FavIcon url={data.favIcon} /> : null} */}
        <Typography
          variant="body1"
          sx={{
            fontSize: "13px",
            fontWeight: "500",
            color: "rgba(38, 42, 50, 0.75)",
            lineHeight: "15px",
            letterSpacing: "-0.5px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {parsedURL.hostname}
        </Typography>
      </div>
    </Box>
  );
};
