import { WEB_MAIN_ELEMENT_ID } from "@meetin/shared";
import { ANONYMOUS_TOKEN } from ".";
import { SupabaseClientHelper } from "../supabase";
import { UserPreference } from "./types";

export const isSidePanel = (): boolean => Boolean(chrome.sidePanel);
export const isWeb = (): boolean =>
  Boolean(document.getElementById(WEB_MAIN_ELEMENT_ID));

// rewrite these functions so they can take any type
export const toggleUserPreference = (
  flag: UserPreference,
  value: boolean
): void => {
  chrome.runtime.sendMessage({
    type: "UPDATE_USER_PREFERENCE",
    message: { [flag]: value },
  });
};

export const getEnvVariable = (key: string): string | undefined => {
  try {
    return process.env[`REACT_APP_${key}`];
  } catch (_err) {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return import.meta.env[`VITE_${key}`];
    } catch (err) {
      return undefined;
    }
  }
};

export const sendMessageToCurrentTab = (data: unknown) =>
  new Promise((resolve) => {
    if (!chrome.tabs) {
      return;
    }
    chrome.tabs.query({ active: true, currentWindow: true }, (tabs) => {
      if (tabs[0]?.id) {
        return chrome.tabs.sendMessage(
          tabs[0].id,
          data,
          (response: unknown) => {
            resolve(response);
          }
        );
      }
      return resolve(null);
    });
  });

// returns accesstoken for authenticated user or anonymous token for web if not authenticated
export const getUserToken = () =>
  SupabaseClientHelper.getSupabaseClient().realtime.accessToken ||
  localStorage.getItem(ANONYMOUS_TOKEN);
