import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  DialogContentText,
  showErrorNotification,
  showSuccessNotification,
  CircularProgress,
} from "@meetin/uicore";
import { clientLogger } from "../logger";
import {
  useCancelPlanMutation,
  useGetUserSubscriptionSettingsQuery,
  useRefreshSubscriptionMutation,
} from "./rtkSubscription";
import { getSupabaseErrorMessage } from "../supabase";
import { UserStripeSubscription } from "./types";
import { Tooltip } from "../common";
import { getEnvVariable } from "../app";

type Props = {
  subscriptionDetails: UserStripeSubscription;
};
const CancelPlan = ({ subscriptionDetails }: Props): JSX.Element | null => {
  const { data } = useGetUserSubscriptionSettingsQuery(undefined, {});
  const [cancelPlan, { isSuccess, error, isLoading: isCancelling }] =
    useCancelPlanMutation();
  const [refetchUserSubscription, { isLoading: isRefreshingSubscription }] =
    useRefreshSubscriptionMutation();

  const [showDialog, setShowDialog] = useState(false);
  const isCancelledPlan = subscriptionDetails.cancel_at_period_end;
  const canceledAt = subscriptionDetails.canceled_at;
  const handleCancelClick = () => {
    clientLogger.info("[CancelPlan] handleCancelClick");
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const handleCancelPlan = () => {
    cancelPlan(undefined);
  };

  useEffect(() => {
    if (error) {
      showErrorNotification({ message: getSupabaseErrorMessage(error) });
    }
  }, [error]);

  useEffect(() => {
    if (isSuccess) {
      showSuccessNotification({ message: "Your plan has been cancelled." });
      refetchUserSubscription(undefined);
      window.open(getEnvVariable("CANCEL_SUBSCRIPTION_FEEDBACK_URL"), "_blank");
      handleDialogClose();
    }
  }, [isSuccess, refetchUserSubscription]);

  if (!data?.subscription) {
    return null;
  }

  return (
    <>
      <Tooltip
        title={
          isCancelledPlan && canceledAt
            ? `You have cancelled this plan on ${new Date(
                canceledAt * 1000
              ).toLocaleString("default", {
                weekday: "short",
                year: "numeric",
                month: "short",
                day: "numeric",
              })}`
            : ""
        }
      >
        <span>
          <Button
            disabled={isCancelledPlan}
            onClick={handleCancelClick}
            variant="outlined"
            color="error"
          >
            Cancel Subscription
          </Button>
        </span>
      </Tooltip>
      {showDialog ? (
        <Dialog open onClose={handleDialogClose}>
          <DialogTitle>
            Cancel your plan: {data?.subscription?.plan.plan_name}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Your plan will be cancelled on{" "}
              {subscriptionDetails ? (
                new Date(
                  subscriptionDetails.current_period_end * 1000
                ).toLocaleString("default", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
              ) : (
                <CircularProgress size={12} />
              )}
              .
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={isCancelling || isRefreshingSubscription}
              onClick={handleDialogClose}
            >
              Close
            </Button>
            <LoadingButton
              loading={isCancelling || isRefreshingSubscription}
              onClick={handleCancelPlan}
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default CancelPlan;
