import { ThemeOptions } from "@mui/material";
import { blue, gray, green, red } from "./colors";

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    gray: Palette["primary"];
    accent: Palette["primary"];
  }
  interface PaletteOptions {
    gray?: PaletteOptions["primary"];
    accent?: PaletteOptions["primary"];
  }
  interface CommonColors {
    layer: {
      blue: typeof blue;
      red: typeof red;
      green: typeof green;
      gray: typeof gray;
    };
  }
}

const themeConfig = {
  palette: {
    primary: {
      main: "rgba(21, 111, 238, 1)",
    },
    secondary: {
      main: "#1A1C20",
    },
    text: {
      primary: "rgba(0, 5, 15, 0.9)",
      secondary: "rgba(153, 155, 159, 1)",
    },
    accent: {
      main: "rgba(0, 10, 40, 1)",
    },
    background: {
      paper: "rgba(255, 255, 255, 0.9)",
    },
    success: {
      main: "rgba(52, 199, 89, 1)",
    },
    warning: {
      main: "rgba(255, 149, 0, 1)",
    },
    error: {
      main: "rgba(255, 59, 48, 1)",
    },
    gray: {
      main: "#EDEDEE",
    },
    blue: {
      main: blue[7],
    },

    common: {
      layer: {
        blue,
        red,
        green,
        gray,
      },
    },
  },
  typography: {
    fontFamily: [
      "Inter",
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      "Roboto",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontSize: "30px",
      fontWeight: 600,
      letterSpacing: "0.19px",
    },
    h2: {
      fontSize: "24px",
      fontWeight: 600,
      letterSpacing: "0.19px",
    },
    h3: {
      fontSize: "22px",
      fontWeight: 600,
      letterSpacing: "0.19px",
    },
    h4: {
      fontSize: "20px",
      fontWeight: 600,
      letterSpacing: "0.19px",
    },
    h5: {
      fontSize: "18px",
      fontWeight: 600,
      letterSpacing: "0.19px",
    },
    h6: {
      fontSize: "16px",
      fontWeight: 600,
      letterSpacing: "0.19px",
    },
    body1: {
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "1.3",
    },
    body2: {
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "1.3",
    },
    button: {
      textTransform: "none",
    },
    caption: {
      primary: "rgba(0, 5, 15, 0.6)",
      fontSize: "12px",
      lineHeight: "1.1",
    },
  },
  shadows: [
    "none", // 0
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 1
    "0px 2px 4px rgba(0, 0, 0, 0.04), 0px 2px 2px rgba(0, 0, 0, 0.08)", // 2
    "0px 4px 8px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.08)", // 3
    "0px 4px 16px rgba(0, 0, 0, 0.28), inset 0px 0px 5px rgba(0, 0, 0, 0.12)", // 4 - used for comments
    "0px 4px 16px rgba(0, 0, 0, 0.28), inset 0px 0px 4px rgba(0, 0, 0, 0.12)", // 5 - used for menu bar
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 6
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 7
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 8
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 9
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 10
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 11
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 12
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 13
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 14
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 15
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 16
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 17
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 18
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 19
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 20
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 21
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 22
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 23
    "0px 1px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.08)", // 24
  ],
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "13px",
          border: "1px solid rgba(0, 0, 0, 0.1) !important",
          padding: "10px",
          boxShadow:
            "0px 4px 16px rgba(0, 0, 0, 0.28), inset 0px 0px 5px rgba(0, 0, 0, 0.12)",
          backdropFilter: "blur(10px)",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          overflow: "unset",
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        avatar: {
          margin: "8px",
        },
        action: {
          margin: "0px !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            boxShadow: "none",
          },
        },
        contained: {
          borderRadius: "8px",
        },
        outlined: {
          borderRadius: "8px",
        },
        outlinedPrimary: {
          color: "#007AFF",
          "&.Mui-disabled": {
            opacity: 0.5,
          },
        },

        text: {
          fontWeight: 600,
          minWidth: "0px",

          "& .MuiTouchRipple-root": {
            display: "none",
          },
          "&:hover": {
            backgroundColor: "transparent",
            textDecoration: "underline",
          },
          endIcon: {
            marginLeft: "5px",
          },
          sizeLarge: {
            padding: "11px 16px",
            fontSize: 18,
            fontWeight: 500,
          },
          sizeMedium: {
            padding: "2px 12px",
            fontSize: 15,
            fontWeight: 500,
          },
          sizeSmall: {
            padding: "0px 12px",
            fontSize: 14,
            fontWeight: 500,
          },
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          overflow: "auto",
          paddingTop: "0px",
          paddingBottom: "0px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
  },
} as ThemeOptions;

export default themeConfig;
