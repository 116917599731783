import { useEffect } from "react";

const SubscriptionCancel = () => {
  useEffect(() => {
    if (!window.opener) {
      return;
    }
    window.opener.postMessage({ type: "checkout-cancel", message: {} }, "*");
    return;
  }, []);
  return <>Redirecting...</>;
};

export default SubscriptionCancel;
