import { useRef, useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@meetin/uicore";
import { getEnvVariable } from "../app";
import { useRefreshSubscriptionMutation } from "../subscription";
import { clientLogger } from "../logger";

type Props = {
  variant?: "contained" | "outlined";
  onClick?: () => void;
};
type CheckoutResponse = { sessionId: string };

const UpgradeSubscriptionButton = ({
  variant = "contained",
  onClick,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const windowRef = useRef<Window | null>(null);
  const [
    refetchUserSubscription,
    { isLoading: isRefreshingSubscription, isSuccess },
  ] = useRefreshSubscriptionMutation();

  useEffect(() => {
    if (isSuccess) {
      onClick?.();
    }
  }, [isSuccess, onClick]);
  const onSuccess = useCallback(
    async (queryData: CheckoutResponse) => {
      setIsLoading(false);

      if (!windowRef.current) {
        return;
      }
      chrome.runtime.sendMessage({
        type: "DELETE_CACHE",
        message: "user-subscription",
      });
      clientLogger.info("checkout success", { queryData });
      setTimeout(() => {
        refetchUserSubscription(queryData.sessionId);
      }, 3000);
    },
    [refetchUserSubscription]
  );

  const onMessage = useCallback(
    (ev: MessageEvent<{ type: string; message: CheckoutResponse }>) => {
      if (typeof ev.data !== "object") return;
      if (!ev.data.type) return;
      if (ev.data.type === "checkout-complete") {
        onSuccess(ev.data.message);
        windowRef.current?.close();
        windowRef.current = null;
      }
      if (ev.data.type === "checkout-cancel") {
        clientLogger.info("checkout cancel");
        setIsLoading(false);
        windowRef.current?.close();
        windowRef.current = null;
      }
    },
    [onSuccess]
  );

  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [onMessage]);

  const handleUpgradeClick = () => {
    setIsLoading(true);
    const width = 1000;
    const height = window.screen.height * 0.9;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    windowRef.current = window.open(
      `${getEnvVariable("PRICING_PAGE_URL")}`,
      "",
      [
        "toolbar=no",
        "location=no",
        "directories=no",
        "status=no",
        "menubar=no",
        "scrollbars=no",
        "resizable=no",
        "copyhistory=no",
        `width=${width}`,
        `height=${height}`,
        `top=${top}`,
        `left=${left}`,
      ].join(",")
    );
  };

  return (
    <LoadingButton
      loading={isLoading || isRefreshingSubscription}
      onClick={handleUpgradeClick}
      variant={variant}
    >
      Upgrade
    </LoadingButton>
  );
};

export default UpgradeSubscriptionButton;
