import { rtkApi } from "@meetin/shared";
import { showErrorNotification } from "@meetin/uicore";
import { fetchFromApi } from "../api";
import { clientLogger } from "../logger";

type GetRelevantLinksRequest = { topic: string; objectives: string };
type RelevantLinksResponse = {
  result: [];
};

export const rtkLinksApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getRelevantLinks: builder.query<
      RelevantLinksResponse,
      GetRelevantLinksRequest
    >({
      queryFn: async (query) => {
        clientLogger.info("get links from Tavily");

        const response = await fetchFromApi(
          `get-relevant-links?topic=${query.topic}&objectives=${query.objectives}`,
          {},
          {
            method: "GET",
          }
        );

        if (response.error) {
          showErrorNotification({ message: response.error });
          throw new Error(response.error);
        }

        return { data: response };
      },
    }),
  }),
});

export const { useLazyGetRelevantLinksQuery } = rtkLinksApi;
