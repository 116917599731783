import React from "react";
import {
  EnvironmentTypes,
  PdfViewerEntryPoints,
  initLogger,
  initPdfJs,
} from "@meetin/components";
import { WEB_ROOT_ELEMENT_ID } from "@meetin/shared";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SERVICE_NAME } from "modules/app/utils";

const root = ReactDOM.createRoot(
  document.getElementById(WEB_ROOT_ELEMENT_ID) as HTMLElement
);

initLogger({
  serviceName: SERVICE_NAME,
  clientToken: `${process.env.REACT_APP_DATADOG_CLIENT_TOKEN}`,
  site: `${process.env.REACT_APP_DATADOG_SITE}`,
  env: `${process.env.REACT_APP_ENV}` as EnvironmentTypes,
  version: `${process.env.REACT_APP_VERSION}`,
});
initPdfJs(PdfViewerEntryPoints.WEB);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
