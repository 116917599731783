import { useContext, useCallback } from "react";
import OneLine from "oneline";
import { Collection } from "@meetin/shared";
import { showErrorNotification } from "@meetin/uicore";
import { useDispatch } from "react-redux";
import { setMessages } from "../../ai/askLayer/redux/askLayerSlice";
import { AiMessage, AskLayerQueryType } from "../../ai/askLayer/types";
import { useLazyAskLayerQuery } from "./rtkAskLayerQueries";
import { ComponentsContext } from "../../common";
import { clientLogger } from "../../logger";
import { getSupabaseErrorMessage } from "../../supabase";

type Props = {
  collection?: Collection;
};

const useCollectionRelavancy = ({ collection }: Props) => {
  const [onSubmit, { isLoading }] = useLazyAskLayerQuery();
  const { currentPageUrl } = useContext(ComponentsContext);
  const dispatch = useDispatch();
  const highlightsQuery = collection
    ? OneLine`
    I am conducting research on "${collection.title}" with the following objectives: "${collection.objective}". If the provided context is relevant to my research, the "sources" field in the response JSON should encapsulate a list of exact sentences or paragraphs from the context that are most relevant to my research.
    Ensure a deep understanding of the context to generate meaningful insights. It's crucial to quote the selected excerpts exactly as they appear in the context, word for word, without any substitutions, alterations, or changes in formatting. Any deviation from the original text is unacceptable.
    Provide five highlights in the "sources" field of the response, ensuring they are verbatim reproductions of the original text and are insightful in addressing the research objectives.`
    : OneLine`Include five insightful highlights in the "sources" field of the response, ensuring they are verbatim reproductions of the original text. It's crucial to quote them exactly as they appear in the context, word for word, without any substitutions, alterations, or changes in formatting. Any deviation from the original text is unacceptable.`;

  const getKeyPoints = useCallback(
    async (markdown: string, insightsData: AiMessage | undefined) => {
      if (isLoading || !insightsData) {
        return null;
      }
      if (!markdown) {
        clientLogger.error(
          "[useCollectionRelavancy] not able to get tab main html"
        );
        return null;
      }

      clientLogger.info("[useCollectionRelavancy] checking relavancy", {
        collection: collection?.id,
        objective: collection?.objective,
        url: currentPageUrl,
      });

      const response = await onSubmit({
        query: highlightsQuery,
        markdown,
        url: currentPageUrl,
        type: AskLayerQueryType.AI_HIGHLIGHTS,
      });

      if ("error" in response) {
        clientLogger.error("[getKeyPoints] error ", {
          error: getSupabaseErrorMessage(response.error),
        });
        showErrorNotification({
          message: "Unable to get keypoints from the context",
        });
        return null;
      }

      dispatch(
        setMessages({
          ...insightsData,
          sources: [
            ...insightsData.sources,
            ...(response.data as unknown as string[]),
          ],
        })
      );
      return response.data as unknown as string[];
    },
    [
      isLoading,
      collection?.id,
      collection?.objective,
      currentPageUrl,
      onSubmit,
      highlightsQuery,
      dispatch,
    ]
  );

  return { getKeyPoints };
};

export default useCollectionRelavancy;
