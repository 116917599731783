import { useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { Collection } from "@meetin/shared";
import {
  CircularProgress,
  MenuItem,
  showErrorNotification,
  showSuccessNotification,
} from "@meetin/uicore";
import { useContext, MouseEvent } from "react";
import { ComponentsContext, ZIndex } from "../../common";
import { useDeleteCollectionMutation } from "../hooks";
import { clientLogger } from "../../logger";
import { getPortalContainer } from "../../common/utils";

type Props = {
  collection: Collection;
};

const DeleteCollectionButton = ({ collection }: Props): JSX.Element | null => {
  const { user } = useContext(ComponentsContext);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [deleteCollection, { isLoading }] = useDeleteCollectionMutation();

  const onDeleteClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    confirm({
      title: "Are you sure you want to delete this collection?",
      description:
        "You cannot undo this action. Notes/comments added to this collection will not be deleted.",
      dialogProps: {
        container: getPortalContainer(),
        hideBackdrop: true,
        sx: { zIndex: ZIndex + 2 },
      },
    })
      .then(async () => {
        try {
          await deleteCollection({
            collectionId: collection.id,
          }).unwrap();
          showSuccessNotification({
            message: `Collection ${collection.title} deleted successfully!`,
          });
          navigate("/collections");
        } catch (error) {
          clientLogger.error(`could not delete collection: ${collection.id}`, {
            error: (error as Error).message,
          });

          showErrorNotification({
            message: `Unable to delete collection. Error: ${
              (error as Error).message
            }`,
          });
          return null;
        }
        return null;
      })
      .catch(() => {
        clientLogger.info(`Delete collection ${collection.id} cancelled`);
      });
  };

  if (user?.user_id && user.user_id !== collection.created_by) {
    return null;
  }

  return (
    <MenuItem
      aria-label="delete collection"
      onClick={onDeleteClick}
      disabled={isLoading}
    >
      {isLoading ? <CircularProgress size={18} /> : null}
      Delete Collection
    </MenuItem>
  );
};

export default DeleteCollectionButton;
