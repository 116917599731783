export const blue = {
  12: "#0A1C2F",
  11: "#2D0080",
  10: "#14009F",
  9: "#0004BF",
  8: "#003ADF",
  7: "#007AFF",
  6: "#22B6FF",
  5: "#44E7FF",
  4: "#66FFFF",
  3: "#88FFE9",
  2: "#AAFFDE",
  1: "#CCFFE0",
};

export const gray = {
  1: "#F7F8FA",
  2: "#F0F2F5",
  3: "#E6EBF0",
  4: "#DFE5EB",
  5: "#D8DFE5",
  6: "#C7CFD6",
  7: "#B8C3CC",
  8: "#9AA8B3",
  9: "#5B6973",
  10: "#515D66",
  11: "#414B52",
  12: "#171F27",
};

export const green = {
  1: "#F8FCFA",
  2: "#F1F9F6",
  3: "#EDF8F4",
  4: "#D9F2E8",
  5: "#C3EADB",
  6: "#ACE2CD",
  7: "#86D5B8",
  8: "#2AC089",
  9: "#109363",
  10: "#0E8157",
  11: "#0C6E4A",
  12: "#052E1F",
};

export const red = {
  1: "#FEFAFB",
  2: "#FEF6F6",
  3: "#FDE7E9",
  4: "#FED8DA",
  5: "#FDC9CC",
  6: "#FCBABF",
  7: "#F99AA0",
  8: "#F76E77",
  9: "#F76E77",
  10: "#EF2734",
  11: "#EE1624",
  12: "#DF111E",
};
