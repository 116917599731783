import { Post } from "@meetin/shared";
import { Box, Typography } from "@mui/material";
import { UserName, UserAvatar } from "../user";
import { timeAgo } from "./utils";

type Props = {
  post: Post;
};

const PostAuthorInfo = ({ post }: Props): JSX.Element => (
  <Box sx={{ display: "flex", alignItems: "center" }}>
    <Box sx={{ marginRight: "8px" }}>
      <UserAvatar userId={post.posted_by} />
    </Box>
    <Typography
      variant="h6"
      color="text.primary"
      sx={{ margin: "0px", textTransform: "capitalize" }}
    >
      <UserName userId={post.posted_by} />
    </Typography>
    <Typography variant="body1" ml="8px" color="text.secondary">
      {timeAgo(post.created_at)}
    </Typography>
  </Box>
);

export default PostAuthorInfo;
