import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { EXTENSION_ROOT_ELEMENT_ID, WEB_MAIN_ELEMENT_ID } from "@meetin/shared";
import TurndownService from "turndown";
import Mark from "mark.js";
import { AskLayerResponse } from "./types";
import { getPdfContent } from "./pdfUtils";
import { clientLogger } from "../../logger";
import { getSupabaseErrorMessage } from "../../supabase";
import { isSidePanel, isWeb } from "../../app";
import { pdfJsEventBus } from "../../pdf";
import { ReduxStoreHelper } from "../../store";

const isElementVisible = (element: Element) => {
  const style = window.getComputedStyle(element);

  // Check for display and visibility
  if (style.display === "none" || style.visibility === "hidden") {
    return false;
  }

  // Check for opacity
  if (style.opacity === "0") {
    return false;
  }

  // Check for positioning
  if (
    style.position === "fixed" ||
    style.position === "absolute" ||
    style.position === "sticky"
  ) {
    return false;
  }

  // Check for media queries
  const mediaQueryList = window.matchMedia(style.getPropertyValue("content"));
  if (mediaQueryList.matches) {
    return false;
  }

  return true;
};

const filterVisibleElements = (element: Element) => {
  if (isElementVisible(element)) {
    Array.from(element.children).forEach(filterVisibleElements);
  } else {
    element.remove();
  }
};

export const filterHTMLElements = (
  parent: HTMLElement,
  returnText = false
): string => {
  if (window.location.host === "docs.google.com") {
    const scripts = document.body.querySelectorAll("script");
    let res = "";
    scripts.forEach((s) => {
      if (s.innerHTML.startsWith("DOCS_modelChunk = ")) {
        res += JSON.parse(
          `{${
            s.innerHTML
              .split(";")[0]
              .replace("DOCS_modelChunk", "")
              .replace("=", "")
              .trim()
              .split("{")[1]
              .split("}")[0]
          }}`
        ).s;
      }
    });
    return res;
  }
  // using outer html for stripping main element from web to avoid pre embedding pdf web page
  const htmlString = parent.outerHTML.trim().replace(/\s+/g, " ");
  const div = document.createElement("div");
  div.style.display = "none";
  div.innerHTML = htmlString;
  parent.appendChild(div);

  const selectorsToBeRemoved = [
    `#${EXTENSION_ROOT_ELEMENT_ID}`,
    `#${WEB_MAIN_ELEMENT_ID}`,
    "header",
    "footer",
    "noscript",
    "style",
    "script",
  ];
  selectorsToBeRemoved.forEach((selector) => {
    const elementsToBeRemoved = div.querySelectorAll(selector);
    if (elementsToBeRemoved.length > 0) {
      elementsToBeRemoved.forEach((element) => {
        element.parentElement?.removeChild(element);
      });
    }
  });

  Array.from(div.children).forEach(filterVisibleElements);

  const resultHTML = returnText ? div.innerText : div.innerHTML;
  parent.removeChild(div); // Clean up the temporary container div

  return resultHTML;
};

export const getTabHtml = async (returnText = false) => {
  // @ts-expect-error TODO fix this type properly
  const html = ReduxStoreHelper.getStore().getState().askLayer
    .markdown as string;
  if (html) {
    return html;
  }

  if (document.contentType === "application/pdf") {
    return getPdfContent(document.URL);
  }
  return filterHTMLElements(
    document.querySelector("main") || document.body,
    returnText
  );
};

const cleanMarkdown = (markdown: string) =>
  markdown
    .replace(/\*\*\*([^*]+)\*\*\*/g, "$1")
    .replace(/\|\|\|\|([^|]+)\|\|\|\|/g, "$1")
    // for replacing square brackets.. ex: test[\[test\]] to test[test]
    .replace(/\[\[([^*]+)\]\]/, "[$1]")
    .replace(/\[([\s\n]*.*?)\]\(.*?\)/g, "$1");

const getMarkdownFromHtml = (html: string | null) => {
  if (!html) {
    return null;
  }
  const turndownService = new TurndownService({
    // @ts-expect-error - overriding turndown options for strong and em italics
    strongDelimiter: "***",
    // @ts-expect-error - overriding turndown options for strong and em italics
    emDelimiter: "||||",
  });
  const markdown = turndownService.turndown(html);

  return cleanMarkdown(markdown);
};
const getPageMarkdown = async (activeTabId: number) => {
  const response = await chrome.tabs.sendMessage(activeTabId, {
    type: "GET_TAB_HTML_MAIN",
    from: 0,
  });
  if (!response?.html) {
    clientLogger.error("not able to get tab main html");
    return null;
  }

  return getMarkdownFromHtml(response.html);
};

export const getActiveTabHTMLMarkdown = async () => {
  // if this is called from content script
  if (!isSidePanel()) {
    return getMarkdownFromHtml(await getTabHtml());
  }

  // if called from sidepanel
  const tabs = await chrome.tabs.query({ active: true, currentWindow: true });
  const activeTab = tabs[0];

  if (activeTab?.id) {
    return getPageMarkdown(activeTab.id);
  }

  return null;
};

const textSimilarityByLevenshteinDistance = (a: string, b: string) => {
  const m = a.length;
  const n = b.length;
  const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= m; i++) {
    dp[i][0] = i;
  }

  // eslint-disable-next-line no-plusplus
  for (let j = 1; j <= n; j++) {
    dp[0][j] = j;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= m; i++) {
    // eslint-disable-next-line no-plusplus
    for (let j = 1; j <= n; j++) {
      const cost = a[i - 1] === b[j - 1] ? 0 : 1;
      dp[i][j] = Math.min(
        dp[i - 1][j] + 1,
        dp[i][j - 1] + 1,
        dp[i - 1][j - 1] + cost
      );
    }
  }

  const distance = dp[m][n];
  const similarity = 1 - distance / Math.max(a.length, b.length);
  return similarity;
};

export const resetHighlightedTexts = () => {
  const instance = new Mark(document.body);
  instance.unmark();
};

type MissingSentenceMatch = { text: string; similarity: number };

const handleNoMatch = async (data: { text: string; fromNoMatch?: boolean }) => {
  if (data.fromNoMatch) {
    return;
  }
  const term = data.text;
  clientLogger.info(`Text: ${term} not found, finding by similarity`);
  const markdownAsLines = (await getTabHtml(true))?.split(/[.\r?\n|\r|\n]/g);
  if (!markdownAsLines) {
    return;
  }
  const matches = markdownAsLines.reduce(
    (acc: MissingSentenceMatch[], text) => {
      const similarity = textSimilarityByLevenshteinDistance(term, text);
      if (similarity < 0.8) {
        return acc;
      }

      return [...acc, { text, similarity }];
    },
    []
  );

  clientLogger.info(`Text: ${term} not found, matches`, { matches });
  if (matches.length > 0) {
    // sort the matches and get the first match
    const newText = matches
      .sort((a: MissingSentenceMatch, b: MissingSentenceMatch) =>
        a.similarity > b.similarity ? -1 : 1
      )[0]
      .text.replace(/\\\[/g, "[")
      .replace(/\\\]/g, "]");
    // sometimes, there will extra text added to the end of the sentence if the sentence is not ending with .
    const last4Words = term.split(" ").slice(-4).join(" ");
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    highlightText({
      text: `${newText.split(last4Words)[0]}${last4Words}`,
      fromNoMatch: true,
    });
  }
};

export const highlightText = (data: {
  text: string;
  fromNoMatch?: boolean;
}) => {
  if (isWeb()) {
    pdfJsEventBus.on(
      "updatefindcontrolstate",
      async (result: {
        matchesCount: { current: number; total: number };
        state: number;
      }) => {
        if (result.state === 1 && result.matchesCount.current === 0) {
          handleNoMatch(data);
        }
      }
    );
    pdfJsEventBus.dispatch("find", {
      type: "",
      matchDiacritics: false,
      caseSensitive: false,
      highlightAll: true,
      findPrevious: false,
      query: data.text,
      entireWord: false,
      phraseSearch: true,
    });
    return;
  }
  const instance = new Mark(document.body);
  instance.mark(data.text, {
    separateWordSearch: false,
    exclude: [`#${EXTENSION_ROOT_ELEMENT_ID} *`],
    acrossElements: true,
    caseSensitive: false,
    ignoreJoiners: true,
    diacritics: true,
    ignorePunctuation: [",", ":"],
    accuracy: "partially",

    filter: (
      node: Text,
      _term: string,
      marksSoFar: number,
      _marksTotal: number
    ) => {
      if (marksSoFar === 0) {
        node.parentElement?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      return true;
    },
    noMatch: async () => {
      handleNoMatch(data);
    },
  });
};

export const consolidateChunkResponse = (
  inputArray: (
    | {
        data: AskLayerResponse;
      }
    | {
        error: FetchBaseQueryError | SerializedError;
      }
  )[]
) => {
  const aggregatedObject: {
    summary: string;
    vitalSections: string[];
    exactSentences: string[];
  } = {
    summary: "",
    vitalSections: [],
    exactSentences: [],
  };

  // eslint-disable-next-line no-restricted-syntax
  for (const obj of inputArray) {
    if ("error" in obj) {
      clientLogger.error("[consolidateChunkResponse] error ", {
        error: getSupabaseErrorMessage(obj.error),
      });
    } else if (obj?.data) {
      const { content } = obj.data[0].message;
      const isRelavantContent = content.answer.startsWith("Yes");
      if (isRelavantContent && !aggregatedObject.summary.startsWith("Yes")) {
        // eslint-disable-next-line prefer-destructuring
        aggregatedObject.summary = content.answer.split("Vital")[0];
      }

      // const vitalSectionString = content.match(/Vital([\s\S]*?)\n\nExact/);

      // if (vitalSectionString) {
      //   const vitalSections = vitalSectionString[1]
      //     .trim()
      //     .match(/\d+\.\s[^\n]*(?=\n[\d+.\s]|$)/g);
      //   vitalSections?.map((text) => aggregatedObject.vitalSections.push(text));
      // }

      // const sentences = content
      //   .split(/Exact sentences or paragraphs/)[1]
      //   ?.split("\n-");
      // if (sentences?.length) {
      //   sentences.shift();
      //   sentences.map((text) =>
      //     aggregatedObject.exactSentences.push(
      //       text.match(/"([^"]+)"/)?.[1] || text
      //     )
      //   );
      // }
    }
  }

  return [
    {
      index: 0,
      message: {
        role: "assistant",
        content: { answer: `${aggregatedObject.summary}`, sources: [] },
      },
      finish_reason: "completed",
    },
  ] as AskLayerResponse;
};
