import { configureStore } from "@reduxjs/toolkit";
import { clientLogger } from "../logger";

type StoreType = ReturnType<typeof configureStore>;
type InitProps = {
  store: StoreType;
};
type Props = {
  store: StoreType | null;
  initializeStore: (props: InitProps) => StoreType;
  getStore: () => NonNullable<StoreType>;
};

/**
 * Helper utility to store singleton supabase instance
 */
export const ReduxStoreHelper: Props = {
  store: null,
  initializeStore: ({ store }: InitProps) => {
    if (ReduxStoreHelper.store) {
      clientLogger.info("store already initialized");
      return ReduxStoreHelper.store;
    }

    ReduxStoreHelper.store = store;
    return store;
  },
  getStore: () => {
    if (ReduxStoreHelper.store) {
      return ReduxStoreHelper.store;
    }

    throw new Error("Supabase client not initialized");
  },
};
