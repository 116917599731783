import { Box, Stack, styled } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  icon: ReactNode;
}

const Wrapper = styled(Stack)`
  width: 100%;
`;
const NotificationWrapper = (props: Props): JSX.Element => {
  const { children, icon } = props;

  return (
    <Wrapper direction="row" alignItems="center" gap={1}>
      {icon && <Box display="contents">{icon}</Box>}
      <Box flex={1}>{children}</Box>
    </Wrapper>
  );
};

export default NotificationWrapper;
