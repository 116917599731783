import { Typography } from "@meetin/uicore";
import { useGetUserProfileQuery } from "../user";

type Props = {
  userId: string;
};
const UserName = ({ userId }: Props) => {
  const { data: member } = useGetUserProfileQuery({
    userId,
  });

  if (!member) {
    return (
      <Typography variant="h6" color="text.primary">
        Unknown
      </Typography>
    );
  }

  return (
    <Typography variant="h6" color="text.primary">
      {member.name}
    </Typography>
  );
};

export default UserName;
