import { rtkApi } from "@meetin/shared";
import {
  clientLogger,
  ANONYMOUS_TOKEN,
  SupabaseClientHelper,
} from "@meetin/components";

type Request = {};
type Response = { token: string | null };

const rtkAnonymous = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnonymousToken: builder.mutation<Response, Request>({
      queryFn: async ({}: Request) => {
        const existingToken = localStorage.getItem(ANONYMOUS_TOKEN);

        if (existingToken) {
          return { data: { token: null } };
        }

        const response = await fetch(
          `${process.env.REACT_APP_AI_API_URL}/anonymous`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            // TODO pass unique user data
            body: JSON.stringify({}),
          }
        );

        if (!response.ok) {
          clientLogger.error("Could not authenticate", {
            error: response.statusText,
          });
          throw new Error("Could not authenticate");
        }

        const { token } = await response.json();
        const userToken =
          SupabaseClientHelper.getSupabaseClient().realtime.accessToken;
        if (userToken) {
          clientLogger.info("ignore anonymous token");
          return { data: { token: null } };
        }
        clientLogger.info("setting anonymous token");
        localStorage.setItem(ANONYMOUS_TOKEN, token);

        return { data: { token } };
      },
    }),
  }),
});

export const { useGetAnonymousTokenMutation } = rtkAnonymous;
