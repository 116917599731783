import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Features } from "@meetin/shared";
import { getFeatureEnabled } from "./functions";
import { updateFeatureFlagValue } from "./redux";

// used only for initializing required Split feature flag values.
// We have a listener for updating feature flag values when received
export const useSplitFeatureFlagInitialization = (featureNames: Features[]) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchInitialFlagValues = async () => {
      featureNames.forEach(async (featureName) => {
        const isOn = await getFeatureEnabled(featureName);
        dispatch(
          updateFeatureFlagValue({
            featureName,
            isOn: isOn ?? false,
          })
        );
      });
    };

    fetchInitialFlagValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
