import { useContext } from "react";
import { Post, PostWithCollections } from "@meetin/shared";
import { useGetRepliesQuery } from "./rtkPostsQueries";
import { ComponentsContext } from "../../common";

const useReplies = (
  postId: Post["id"]
): { replies: PostWithCollections[] | null | undefined } => {
  const { pollingInterval } = useContext(ComponentsContext);
  const { data: replies } = useGetRepliesQuery(
    { postId },
    { refetchOnFocus: true, pollingInterval }
  );

  return { replies };
};

export default useReplies;
