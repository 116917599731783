import { AddNewCollectionIcon } from "@meetin/uicore/icons";
import { useState, useEffect, KeyboardEvent } from "react";
import {
  ListItemButton,
  ListItem,
  showErrorNotification,
  showSuccessNotification,
  ListItemIcon,
  ListItemText,
  LoadingButton,
  TextField,
  Stack,
} from "@meetin/uicore";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, SubmitHandler } from "react-hook-form";
import { Collection, Profile } from "@meetin/shared";
import { useCreateCollectionMutation } from "../hooks";
import { getSupabaseErrorMessage } from "../../supabase";
import { AnalyticsEvents, trackEvent } from "../../analytics";

const schema = Yup.object({
  title: Yup.string().required("Collection title is required"),
  objective: Yup.string().nullable(),
}).required();

type CollectionInput = {
  title: Collection["title"];
  objective: Collection["objective"] | undefined;
};

type Props = { userId: Profile["user_id"] };

const AddNewCollectionButton = ({ userId }: Props) => {
  const [showForm, setShowForm] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<CollectionInput>({
    resolver: yupResolver(schema),
    defaultValues: { objective: "" },
  });

  const toggleForm = () => setShowForm((show) => !show);
  const [createCollection, { error, isSuccess }] =
    useCreateCollectionMutation();

  const errorMessage = getSupabaseErrorMessage(error);

  const onSubmit: SubmitHandler<CollectionInput> = (data) => {
    createCollection({ ...data, objective: data.objective || "", userId });

    trackEvent(AnalyticsEvents.COLLECTION_CREATED, {
      collection_name: data.title,
      collection_objective: data.objective || "",
    });
  };

  useEffect(() => {
    if (errorMessage) {
      showErrorNotification({ message: errorMessage });
    }
  }, [errorMessage]);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setShowForm(false);
    }

    if (e.key === "Enter") {
      e.stopPropagation();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccessNotification({ message: "Collection created successfully" });
      setShowForm(false);
      reset();
    }
  }, [isSuccess, reset]);

  if (showForm) {
    return (
      <ListItem
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={onKeyDown}
      >
        <Stack>
          <TextField
            label="Topic"
            {...register("title", { required: true })}
            autoFocus
          />
          <TextField
            label="Objectives"
            sx={{
              my: 2,
            }}
            multiline
            rows={2}
            {...register("objective", {})}
          />
          <LoadingButton
            variant="contained"
            type="submit"
            loading={isSubmitting}
          >
            Create
          </LoadingButton>
        </Stack>
      </ListItem>
    );
  }

  return (
    <ListItemButton
      role="button"
      onClick={toggleForm}
      sx={{
        "&.MuiListItemButton-root": { color: "#007AFF" },
        "& img": { mr: 1 },
      }}
    >
      <ListItemIcon>
        <AddNewCollectionIcon />
      </ListItemIcon>

      <ListItemText primary="New Collection" sx={{}} />
    </ListItemButton>
  );
};

export default AddNewCollectionButton;
