import { Button } from "@meetin/uicore";
import { useDispatch } from "react-redux";
import { AiMessage } from "./types";
import { setMessages } from "./redux/askLayerSlice";

const WebsiteSearcherButton = ({ chat }: { chat: AiMessage }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setMessages({ ...chat, searchAllPages: true }));
  };
  return (
    <Button
      onClick={handleClick}
      sx={{
        justifyContent: "start",
        pl: 2.5,
        pt: 0,
        "&:hover": { textDecoration: "underline" },
      }}
    >
      Try searching entire site
    </Button>
  );
};

export default WebsiteSearcherButton;
