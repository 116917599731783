import { REALTIME_POSTGRES_CHANGES_LISTEN_EVENT } from "@supabase/supabase-js";
import { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useSupabaseSubscriber } from "../supabase";
import { ComponentsContext } from "../common";
import { clientLogger } from "../logger";
import { FeatureUsage } from "../subscription/types";
import {
  GetFeatureUsagePerWeekResponse,
  rtkSubscriptionApi,
  useGetFeaturePerWeekUsageQuery,
  useGetFeatureUsageQuery,
  useGetServerDateQuery,
} from "../subscription";

const useFeaturesUsageSubscription = () => {
  const { subscribe, unsubscribe } = useSupabaseSubscriber();
  const { user } = useContext(ComponentsContext);
  const { data: currentDate } = useGetServerDateQuery(undefined, {
    skip: !user,
  });

  const { refetch: refetchPerDayData } = useGetFeatureUsageQuery(
    { date: currentDate || "" },
    {
      skip: !user?.user_id || !currentDate,
    }
  );

  const { refetch } = useGetFeaturePerWeekUsageQuery(
    { date: currentDate || "" },
    {
      skip: !user?.user_id || !currentDate,
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.user_id || !currentDate) {
      return undefined;
    }

    const filter = `user_id=eq.${user.user_id}`;
    subscribe({
      channel: "features_usage",
      filter: {
        event: REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.ALL,
        schema: "public",
        table: "features_usage",
        filter,
      },
      callback: (payload) => {
        clientLogger.info("features usage update received!", { payload });
        const featuresUsage = payload.new as FeatureUsage;

        // If new date is added, refetch latest feature usage
        if (
          payload.eventType === REALTIME_POSTGRES_CHANGES_LISTEN_EVENT.INSERT
        ) {
          refetch();
          refetchPerDayData();
          return;
        }

        dispatch(
          // @ts-expect-error valid type
          rtkSubscriptionApi.util.updateQueryData(
            "getFeatureUsage",
            { date: currentDate },
            (_current: FeatureUsage | null) => featuresUsage
          )
        );
        dispatch(
          // @ts-expect-error valid type
          rtkSubscriptionApi.util.updateQueryData(
            "getFeaturePerWeekUsage",
            { date: currentDate },
            (current: GetFeatureUsagePerWeekResponse) => {
              // If new date, then return current date in array
              if (!current) {
                return [featuresUsage];
              }
              // If existing date, then update it
              const currentDateIndex = current.findIndex(
                (date) => date.date === featuresUsage.date
              );

              if (currentDateIndex > -1) {
                current[currentDateIndex] = featuresUsage;
                return current;
              }

              // If current date not found in week's usage, push new date
              current.push(featuresUsage);

              return current;
            }
          )
        );
      },
    });

    return () => {
      unsubscribe("features_usage");
    };
  }, [
    subscribe,
    unsubscribe,
    user?.user_id,
    refetch,
    dispatch,
    currentDate,
    refetchPerDayData,
  ]);

  return {};
};

export default useFeaturesUsageSubscription;
