import { IsString } from "class-validator";

export class SessionInviteArgs {
  @IsString()
  public channel!: string;

  @IsString()
  public url!: string;

  @IsString()
  public userId!: string;

  @IsString()
  public sessionId!: string;

  @IsString()
  public tabId!: number;

  constructor(data: unknown) {
    Object.assign(this, data);
  }
}
