import { ListItem, ListItemIcon, ListItemText } from "@meetin/uicore";
import { Collection } from "@meetin/shared";
import { Link } from "react-router-dom";
import { CollectionsIcon } from "@meetin/uicore/icons";
import slug from "slug";

type Props = {
  collection: Collection;
  selectedCollection?: Collection;
};

const CollectionComponent = ({
  collection,
  selectedCollection,
}: Props): JSX.Element => {
  const isSelectedCollection = selectedCollection?.id === collection.id;

  return (
    <ListItem
      component={Link}
      to={`/collections/${collection.id}/${slug(collection.title)}`}
      sx={{
        background: isSelectedCollection
          ? "rgba(0, 127, 255, 0.2)"
          : "transparent",
        borderRadius: 1,
        "& .MuiIconButton-edgeEnd": {
          visibility: "hidden",
        },
        "&:hover .MuiIconButton-edgeEnd": {
          visibility: "inherit",
        },
      }}
    >
      <ListItemIcon>
        <CollectionsIcon />
      </ListItemIcon>
      <ListItemText
        primary={collection.title}
        sx={{
          color: isSelectedCollection ? "#0078F1" : "rgba(0, 0, 0, 0.8)",
        }}
      />
    </ListItem>
  );
};

export default CollectionComponent;
