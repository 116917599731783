import { Link } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@meetin/uicore";
import { AllNotesIcon } from "@meetin/uicore/icons";

const AllPostsButton = (): JSX.Element => (
  <ListItemButton
    component={Link}
    to="/collections/all-notes"
    sx={{
      background: "transparent",
      borderRadius: 1,
    }}
  >
    <ListItemIcon>
      <AllNotesIcon />
    </ListItemIcon>
    <ListItemText primary="All Notes" sx={{ color: "#007AFF" }} />
  </ListItemButton>
);

export default AllPostsButton;
