import { useMemo } from "react";
import { Collection, Profile } from "@meetin/shared";
import { useGetUserCollectionsQuery } from "./rtkCollectionsQueries";

type Props = {
  userId?: Profile["user_id"];
  collectionId?: Collection["id"];
};

const useCollections = ({ userId, collectionId }: Props) => {
  const {
    data: collections,
    isFetching,
    isSuccess,
  } = useGetUserCollectionsQuery(
    { userId },
    {
      skip: !userId,
      // for getting latest data
      refetchOnFocus: true,
      // we dont need polling because we do refetchOnFocus for inactive tabs and for active tabs, we do refetch while creating collection
      pollingInterval: 0,
    }
  );

  const selectedCollection = useMemo(
    () => collections?.find((c) => c.id === collectionId),
    [collections, collectionId]
  );

  return { collections, isFetching, selectedCollection, isFetched: isSuccess };
};

export default useCollections;
