import { Features } from "@meetin/shared";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type InitialState = {
  features: Record<string, boolean>;
};

const initialState: InitialState = {
  features: {},
};

const featureFlagsSlice = createSlice({
  name: "featureFlags",
  initialState,
  reducers: {
    updateFeatureFlagValue: (
      state,
      action: PayloadAction<{ featureName: Features; isOn: boolean }>
    ) => {
      state.features[action.payload.featureName] = action.payload.isOn;
    },
  },
});

export const { updateFeatureFlagValue } = featureFlagsSlice.actions;

export const featureFlagsReducer = featureFlagsSlice.reducer;
