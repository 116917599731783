import { AllPostsView } from "@meetin/components";
import { Grid, CircularProgress } from "@meetin/uicore";
import { AppContext } from "../app/AppProvider";
import { useContext } from "react";
import { useAppDispatch } from "store/hooks";

const AllPostsPage = () => {
  const { user } = useContext(AppContext);
  const dispatch = useAppDispatch();

  if (!user?.user_id) {
    return (
      <Grid>
        <CircularProgress size={20} />
      </Grid>
    );
  }
  return (
    <AllPostsView userId={user.user_id} dispatch={dispatch} ignorePostClick />
  );
};

export default AllPostsPage;
