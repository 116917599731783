import { useParams } from "react-router-dom";
import { Box, Button, CircularProgress, Typography } from "@meetin/uicore";
import {
  AddNoteToCollection,
  getSupabaseErrorMessage,
  useGetCollectionByIdQuery,
} from "@meetin/components";
import { PostsInCollection } from "@meetin/components";
import { clientLogger } from "modules/logger";
import { AppContext } from "modules/app/AppProvider";
import { useContext, useEffect } from "react";
import { useAppDispatch } from "store/hooks";
import { Collection } from "@meetin/shared";

const CollectionView = (): JSX.Element => {
  const { user, supabaseClient } = useContext(AppContext);
  const { collectionId } = useParams();
  const dispatch = useAppDispatch();

  const {
    data: collection,
    isSuccess,
    error,
    refetch,
  } = useGetCollectionByIdQuery(
    { collectionId },
    { skip: !collectionId || !supabaseClient }
  );

  const isFetched = isSuccess || Boolean(error);

  useEffect(() => {
    if (isFetched && user?.user_id) {
      refetch();
    }
  }, [refetch, user?.user_id, isFetched]);

  const errorMessage = getSupabaseErrorMessage(error);

  useEffect(() => {
    if (!errorMessage) {
      return;
    }
    clientLogger.error(`error while getting collection: ${collectionId}`, {
      error: errorMessage,
    });
  }, [errorMessage, collectionId]);

  if (!isFetched) {
    return (
      <Box sx={{ textAlign: "center", mt: 2 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box>
        <Typography variant="h6">
          The collection is not accessible for public viewing.
        </Typography>
        <Button onClick={refetch}>Try again</Button>
      </Box>
    );
  }

  if (!collection) {
    return (
      <Box>
        <Typography variant="h6">Please try again later</Typography>
      </Box>
    );
  }

  return (
    <Box>
      {collectionId ? (
        <AddNoteToCollection
          collection={{ id: collectionId, title: "" } as Collection}
        />
      ) : null}

      <PostsInCollection
        collection={collection}
        dispatch={dispatch}
        userId={user?.user_id || ""}
        ignorePostClick
      />
    </Box>
  );
};

export default CollectionView;
