import {
  REALTIME_LISTEN_TYPES,
  RealtimePostgresChangesPayload,
} from "@supabase/supabase-js";
import {
  SupabaseClientHelper,
  SupabaseSubscriberHelper,
  clientLogger,
} from "@meetin/components";

// invokes background script to subscribe
const subscribe = async (
  args: Parameters<typeof SupabaseSubscriberHelper.subscribe>["0"]
) => {
  clientLogger.info(`realtime subscribe`, { args });
  return SupabaseClientHelper.getSupabaseClient()
    .channel(args.channel)
    .on(
      // @ts-expect-error valid
      REALTIME_LISTEN_TYPES.POSTGRES_CHANGES,
      args.filter,
      (payload: RealtimePostgresChangesPayload<{ [key: string]: unknown }>) => {
        SupabaseSubscriberHelper.onUpdate({ payload, channel: args.channel });
      }
    )
    .subscribe((status, err) => {
      if (err) {
        clientLogger.error(
          `${args.channel} subscription error`,
          {
            status,
          },
          err
        );
      }
    });
};

const unsubscribe = async (
  args: Parameters<typeof SupabaseSubscriberHelper.unsubscribe>["0"]
) => {
  clientLogger.info(`realtime unsubscribe`, { args });
  const client = SupabaseClientHelper.getSupabaseClient();

  clientLogger.debug(`unsubscribing from ${args.channel}`);
  const response = await client.channel(args.channel).unsubscribe();

  clientLogger.info(`realtime unsubscribe response`, { args, response });
};

// initialize the helper to hold subscribe, unsubscribe handlers
SupabaseSubscriberHelper.initializeHelper({ subscribe, unsubscribe, tabId: 0 });
