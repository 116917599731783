import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AiMessage, SearchStatus } from "../types";
import { rtkAskLayerApi } from "../rtkAskLayerQueries";
import { rtkPdfApi } from "../../../pdf/rtkPdf";

export type AskLayerInitialState = {
  markdown: string;
  url: string;
  documentMimeType: string;
  activeCollectionId: string | null;
  messages: Record<AiMessage["requestId"], AiMessage>;
  embeddingsResult: Record<string, boolean>;
  anonymousUsage: number;
  isPdfProcessing: boolean;
};

const initialState: AskLayerInitialState = {
  markdown: "",
  url: "",
  documentMimeType: "",
  activeCollectionId: null,
  messages: {},
  embeddingsResult: {},
  anonymousUsage: 0,
  isPdfProcessing: false,
};

const askLayerSlice = createSlice({
  name: "askLayer",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      rtkAskLayerApi.endpoints.upsertEmbedding.matchFulfilled,
      (state, { meta, payload }) => {
        state.embeddingsResult[meta.arg.originalArgs.url] = payload.isSuccess;
      }
    );
    builder.addMatcher(
      rtkPdfApi.endpoints.getPdfContent.matchPending,
      (state) => {
        state.isPdfProcessing = true;
      }
    );
    builder.addMatcher(
      rtkPdfApi.endpoints.getPdfContent.matchFulfilled,
      (state) => {
        state.isPdfProcessing = false;
      }
    );
    builder.addMatcher(
      rtkPdfApi.endpoints.getPdfContent.matchRejected,
      (state) => {
        state.isPdfProcessing = false;
      }
    );
  },
  reducers: {
    setMarkdown: (state, action: PayloadAction<string>) => {
      state.markdown = action.payload;
    },
    setMarkdownUrl: (state, action: PayloadAction<string>) => {
      state.url = action.payload;
    },
    setDocumentMimeType: (state, action: PayloadAction<string>) => {
      state.documentMimeType = action.payload;
    },
    setMessages: (
      state,
      action: PayloadAction<
        Partial<AiMessage> & { requestId: AiMessage["requestId"] }
      >
    ) => {
      state.messages[action.payload.requestId] = {
        ...state.messages[action.payload.requestId],
        ...action.payload,
      };
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [action.payload]: toRemove, ...rest } = state.messages;
      state.messages = rest;
    },
    resetMessageOpertation: (
      state,
      action: PayloadAction<AiMessage["requestId"]>
    ) => {
      state.messages[action.payload].operations = [];
    },
    updateMessageOperation: (
      state,
      action: PayloadAction<
        SearchStatus & { requestId: AiMessage["requestId"] }
      >
    ) => {
      /**
       * could update
       * - new url operation
       * - add url operation status
       * - update url operation status
       */
      const { operation, status, link, requestId } = action.payload;

      const message = state.messages[requestId];
      message.operations = message.operations || [];

      const operationsForUrl = message.operations?.filter(
        (m) => m.link?.link === link?.link
      );

      // If new URL, add operation
      if (!operationsForUrl) {
        message.operations.push({ operation, status, link });
        return;
      }

      const currentOperation = operationsForUrl.find(
        (o) => o.operation === operation
      );

      // If new operation, add operation
      if (!currentOperation) {
        message.operations.push({ operation, status, link });
        return;
      }

      // If existing operation, update status
      currentOperation.status = status;
    },
    setActiveCollectionId: (state, action: PayloadAction<string | null>) => {
      state.activeCollectionId = action.payload;
    },
    setAnonymousUsage: (state, action: PayloadAction<number>) => {
      state.anonymousUsage = action.payload;
    },
    clearMessages: (state) => {
      state.messages = {};
    },
  },
});

export const {
  setMarkdown,
  setMarkdownUrl,
  setDocumentMimeType,
  setMessages,
  removeMessage,
  setActiveCollectionId,
  updateMessageOperation,
  resetMessageOpertation,
  setAnonymousUsage,
  clearMessages,
} = askLayerSlice.actions;

export const askLayerReducer = askLayerSlice.reducer;
