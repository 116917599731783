import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const SubscriptionExisting = (): JSX.Element => {
  const [searchParams] = useSearchParams();

  const sessionId = searchParams.get("session_id");

  useEffect(() => {
    if (!window.opener) {
      return;
    }
    window.opener.postMessage(
      { type: "checkout-complete", message: { sessionId } },
      "*"
    );
    return;
  }, [sessionId]);
  return <>Redirecting...</>;
};

export default SubscriptionExisting;
