import { Button, Stack, Typography } from "@meetin/uicore";
import { useSelector } from "react-redux";
import useWebsiteSearcher from "./useWebsiteSearcher";
import { AiMessage, SearchOperation } from "./types";
import {
  selectLatestOperationInMessage,
  selectSearchAllPages,
  selectSearchAllPagesByMessage,
} from "./redux/askLayerSelectors";
import WebsiteSearcherStatus from "./WebsiteSearcherStatus";

const WebsiteSearcher = ({
  chat: { question: query, url, requestId, searchQuery },
}: {
  chat: AiMessage;
}) => {
  const searchAllPages = useSelector(selectSearchAllPages);
  const lastStatus = useSelector(selectLatestOperationInMessage(requestId));
  const searchAllPagesByMessage = useSelector(
    selectSearchAllPagesByMessage(requestId)
  );
  const enableSearch = searchAllPagesByMessage || searchAllPages;

  const { answer, onCancel } = useWebsiteSearcher({
    query,
    searchAllPages: enableSearch,
    requestId,
    url,
  });

  if (!enableSearch) {
    return null;
  }

  return (
    <Stack sx={{ px: 2, mb: 2 }}>
      <Stack direction="row" justifyContent="space-between" mb={1}>
        <WebsiteSearcherStatus
          requestId={requestId}
          searchQuery={searchQuery}
          answer={answer}
        />
        {lastStatus?.operation !== SearchOperation.COMPLETED ? (
          <Button onClick={onCancel}>Cancel</Button>
        ) : null}
      </Stack>
      {answer ? <Typography>{answer}</Typography> : null}
    </Stack>
  );
};

export default WebsiteSearcher;
