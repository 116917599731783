import { createSelector } from "@reduxjs/toolkit";
import { InitialState } from "./slackSlice";

const selectSlackState = (state: { slack: InitialState }) => state.slack;

export const selectMembers = createSelector(
  selectSlackState,
  (appState) => appState.members
);

export const selectChannels = createSelector(
  selectSlackState,
  (appState) => appState.channels
);

export const selectChannelIds = createSelector(selectChannels, (channels) =>
  Object.keys(channels).join(",")
);

export const selectSlackDataLoaded = createSelector(
  selectChannels,
  selectMembers,
  (channels, members) =>
    Object.keys(channels).length > 0 && Object.keys(members).length > 0
);
