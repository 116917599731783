import { datadogRum } from "@datadog/browser-rum";
import { camelCase } from "@meetin/shared";
import PerformanceLogger from "./performanceHelper";
import { Actions } from "./actions";
import { CustomAttributes, MonitoredEventTypes } from "./types";

export const trackAction = (
  action: Actions,
  context?: CustomAttributes
): void => {
  datadogRum.addAction(action, {
    ...context,
    type: MonitoredEventTypes.ACTION,
  });
  datadogRum.addTiming(camelCase(action)); // default sanitization method for datadog.
};

export const trackActionStart = (startAction: Actions, id?: string): void => {
  PerformanceLogger.start(startAction, id);
};

export const trackActionEnd = (
  startAction: Actions,
  action?: Actions,
  context?: CustomAttributes,
  id?: string
): void => {
  const duration = PerformanceLogger.end(startAction, id);

  const recordedAction = action || startAction;

  if (duration) {
    datadogRum.addAction(recordedAction, {
      ...context,
      [camelCase(recordedAction)]: duration,
      type: MonitoredEventTypes.TIMING,
    });
    datadogRum.addTiming(camelCase(action));
  }
};
