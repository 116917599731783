import { ReactNode } from "react";
import { Toaster as ReactHotToastToaster, toast, Toast } from "react-hot-toast";
import { PaperPlane, GreenTick, Error, Info } from "../assets";
import NotificationWrapper from "./NotificationWrapper";

type NotificationType = {
  message: string | ReactNode;
  dismiss?: { duration: number };
  icon: "check" | "paper-plane" | "error" | "info";
};

export const Toaster = (): JSX.Element => (
  <ReactHotToastToaster
    containerStyle={{ zIndex: 2147483647 }}
    toastOptions={{
      position: "top-center",
      style: {
        border: "1px solid rgba(0, 0, 0, 0.1)",
        background: "rgba(255, 255, 255, 0.8)",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.28)",
        padding: "14px",
        backdropFilter: "blur(20px)",
        color: "#000000",
        borderRadius: "25px",
        minWidth: "200px",
      },
    }}
  />
);

const notify = (
  message: unknown,
  icon?: NotificationType["icon"],
  opts?: Partial<
    Pick<
      Toast,
      | "id"
      | "icon"
      | "duration"
      | "ariaProps"
      | "className"
      | "style"
      | "position"
      | "iconTheme"
    >
  >
): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const notificationIcon = (() => {
    switch (icon) {
      case "check":
        return <GreenTick />;
      case "paper-plane":
        return <PaperPlane />;
      case "error":
        return <Error />;
      case "info":
        return <Info />;
      default:
        return null;
    }
  })();
  return toast(
    <NotificationWrapper icon={notificationIcon}>
      {message as NotificationType["message"]}
    </NotificationWrapper>,
    opts
  );
};

export const showSuccessNotification = (
  options: Partial<NotificationType>
): string =>
  notify(options.message, "paper-plane", {
    duration: options.dismiss?.duration || 5000,
  });

export const showInfoNotification = (
  options: Partial<NotificationType>
): string =>
  notify(options.message, "info", {
    duration: options.dismiss?.duration || 5000,
  });

export const showErrorNotification = (
  options: Partial<NotificationType>
): string =>
  notify(options.message, "error", {
    duration: options.dismiss?.duration || 5000,
  });
