import { CaptureIcon } from "@meetin/uicore/icons";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
} from "@meetin/uicore";
import { useState } from "react";
import { Collection } from "@meetin/shared";
import PostTextForm from "../PostTextForm";

type Props = { collection: Collection };
const AddNoteToCollection = ({ collection }: Props): JSX.Element | null => {
  const [showForm, setShowForm] = useState(false);

  const toggleForm = () => setShowForm((show) => !show);

  if (!collection?.id) {
    return null;
  }

  if (showForm) {
    return (
      <Box sx={{ border: "1px solid #ccc", borderRadius: 2, p: 1 }}>
        <PostTextForm
          afterSubmit={toggleForm}
          newPostEvent={{}}
          onClose={toggleForm}
          hideCollectionsSelection
          ignorePageUrl
          collections={[collection]}
        />
      </Box>
    );
  }

  return (
    <ListItemButton
      role="button"
      onClick={toggleForm}
      sx={{
        "&.MuiListItemButton-root": { color: "#007AFF" },
        "& img": { mr: 1 },
      }}
    >
      <ListItemIcon>
        <CaptureIcon />
      </ListItemIcon>

      <ListItemText primary="Add new note" />
    </ListItemButton>
  );
};

export default AddNoteToCollection;
