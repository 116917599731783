import { Database } from "@meetin/supabase";

export type Collaborator =
  Database["public"]["Tables"]["collection_collaborators"]["Row"];

export enum CollaboratorInviteStatus {
  Accepted = "Accepted",
  Pending = "Pending",
  Rejected = "Rejected",
}
