import { Button } from "@meetin/uicore";
import { useGetUserSubscriptionSettingsQuery } from "@meetin/components";
import { useState, useMemo } from "react";
import ApiKeyForm from "./ApiKeyForm";

type Props = {
  showButton?: boolean;
};

enum States {
  UNINITIALIZED,
  INITIALIZED,
  CLOSED,
}
const PerpetualLicenseChecker = ({ showButton }: Props) => {
  const { data: subscriptionSettings } = useGetUserSubscriptionSettingsQuery(
    undefined,
    {}
  );

  const planNeedsApiKey =
    subscriptionSettings?.subscription?.plan.custom_api_key;

  const missingApiKey = planNeedsApiKey
    ? !subscriptionSettings?.subscription?.api_key
    : false;

  const [state, setState] = useState(States.UNINITIALIZED);

  const showApiKeyForm = useMemo(() => {
    if (state === States.CLOSED) return false;
    if (showButton) {
      return state === States.INITIALIZED;
    }
    return missingApiKey;
  }, [missingApiKey, showButton, state]);

  if (!showApiKeyForm) {
    if (planNeedsApiKey && showButton) {
      return (
        <Button onClick={() => setState(States.INITIALIZED)}>
          Update Api Key
        </Button>
      );
    }
    return null;
  }

  return (
    <ApiKeyForm
      onClose={() => setState(States.CLOSED)}
      hideOnLoading={!showButton}
    />
  );
};

export default PerpetualLicenseChecker;
