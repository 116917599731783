import { datadogRum } from "@datadog/browser-rum";
import { Errors } from "./errors";

type ErrorContext = {
  label: Errors;
  [x: string]: unknown;
};
export const trackError = (error: unknown, context?: ErrorContext): void => {
  datadogRum.addError(error, context);
};
