import { useSelector } from "react-redux";
import { Features, Profile } from "@meetin/shared";
import {
  ReactNode,
  createContext,
  useMemo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { usePageVisibility } from "./hooks";
import { selectFeatureFlagValue } from "../splitio/redux";
import { useSplitFeatureFlagInitialization } from "../splitio/useSplitFeatureFlagInitialization";
import { SupabaseClientHelper } from "../supabase";
import { clientLogger } from "../logger";
import { getCurrentPageUrl } from "../posts";
import { UserPreferences } from "../app";

type Props = {
  user: Profile | null;
  webAppUrl: string;
  subscriptionsEnabled: boolean;
  pollingInterval: number;
  currentPageUrl: string;
  updateCurrentPageUrl?: () => void;
  userPreferences: UserPreferences;
};
export const ComponentsContext = createContext<Props>({
  user: null,
  webAppUrl: "",
  subscriptionsEnabled: false,
  pollingInterval: 0,
  currentPageUrl: "",
  updateCurrentPageUrl: undefined,
  userPreferences: {},
});

type ContextProps = {
  children: ReactNode;
  user: Profile | null;
  webAppUrl: string;
  userPreferences: UserPreferences;
};

const PollingInterval = 6000;

/**
 * context for storing common values for components in components package
 */
const ComponentsProvider = ({
  children,
  user,
  webAppUrl,
  userPreferences,
}: ContextProps): JSX.Element => {
  const isActive = usePageVisibility();
  const [currentPageUrl, setCurrentPageUrl] = useState("");
  const subscriptionsEnabled = useSelector(
    selectFeatureFlagValue(Features.REALTIME_SUBSCRIPTIONS)
  );

  useSplitFeatureFlagInitialization([Features.REALTIME_SUBSCRIPTIONS]);

  const updateCurrentPageUrl = useCallback(() => {
    getCurrentPageUrl()
      .then((url) => {
        clientLogger.info("page url updated", { url });
        setCurrentPageUrl(url || "");
        return null;
      })
      .catch((err) =>
        clientLogger.error(
          "error while getting current page url",
          undefined,
          err
        )
      );
  }, []);

  useEffect(() => {
    updateCurrentPageUrl();
  }, [updateCurrentPageUrl]);

  useEffect(() => {
    if (!subscriptionsEnabled && SupabaseClientHelper.client) {
      clientLogger.info("[ComponentsProvider] removing all channels");
      SupabaseClientHelper.client.removeAllChannels();
    }
  }, [subscriptionsEnabled]);

  const getPollingInterval = useCallback((): number => {
    if (subscriptionsEnabled) {
      return 0;
    }

    if (!isActive) {
      return 0;
    }

    return PollingInterval;
  }, [subscriptionsEnabled, isActive]);

  const values = useMemo(
    () => ({
      webAppUrl,
      user,
      subscriptionsEnabled,
      pollingInterval: getPollingInterval(),
      currentPageUrl,
      updateCurrentPageUrl,
      userPreferences,
    }),
    [
      webAppUrl,
      user,
      subscriptionsEnabled,
      getPollingInterval,
      currentPageUrl,
      updateCurrentPageUrl,
      userPreferences,
    ]
  );

  return (
    <ComponentsContext.Provider value={values}>
      {children}
    </ComponentsContext.Provider>
  );
};

export default ComponentsProvider;
