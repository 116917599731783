import { OAuthQueryResponse } from "@meetin/shared";
import { clientLogger } from "modules/logger";
import { useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

const OAuthHandler = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { provider } = useParams();

  const data = searchParams.get("data");

  const queryParams = window.location.hash.replace("#", "");
  const queryData: OAuthQueryResponse = useMemo(
    () =>
      queryParams
        ? JSON.parse(
            '{"' +
              decodeURI(queryParams)
                .replace(/"/g, '\\"')
                .replace(/&/g, '","')
                .replace(/=/g, '":"') +
              '"}'
          )
        : {},
    [queryParams]
  );
  clientLogger.info("oauth handler", { queryData, provider, data });

  useEffect(() => {
    if (!window.opener) {
      return;
    }
    if (provider === "google") {
      window.opener.postMessage(
        { type: "auth-complete", message: queryData },
        "*"
      );
      return;
    }
    if (data) {
      window.opener.postMessage(JSON.parse(data), "*");
    }
  }, [data, provider, queryData]);
  return <>Redirecting...</>;
};

export default OAuthHandler;
