import { useEffect } from "react";
import { Profile, SupabaseUser } from "@meetin/shared";

interface SplitAttributeBasis {
  user: Profile | null;
  supabaseUser: SupabaseUser | null;
}

export const useSplitAttributes = ({
  user,
  supabaseUser,
}: SplitAttributeBasis) => {
  useEffect(() => {
    if (supabaseUser && user) {
      chrome.runtime.sendMessage({
        type: "SPLITIO_SET_USER_ATTRIBUTES",
        message: { email: supabaseUser.email, user },
      });
    }
  }, [supabaseUser, user]);
};
