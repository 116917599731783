import { Box, Typography } from "@meetin/uicore";
import { theme } from "@meetin/uicore/src/theme/theme";
import { ListAltIcon } from "@meetin/uicore/icons";
import { InitialFindingLinks } from "../types";
import { LinkItem } from "./LinkItem";
import { MAX_LINKS } from "../constants";

interface Props {
  links?: InitialFindingLinks[];
}

export const RelevantLinks = ({ links }: Props) => {
  if (!links) {
    return (
      <Box
        sx={{
          backgroundColor: "#F0F1F4",
          borderRadius: "8px",
          padding: "4px 12px",
          margin: "8px 24px",
        }}
      >
        <Typography variant="body1">Loading links...</Typography>
      </Box>
    );
  }

  return (
    <>
      {links.length > 0 && (
        <Typography
          variant="body1"
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "17px",
            lineHeight: "15px",
            letterSpacing: "-0.5px",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.9)",
            marginBottom: "20px",
            marginLeft: "12px",
          }}
        >
          <ListAltIcon
            style={{
              marginRight: "8px",
            }}
          />
          Sources to start your research
        </Typography>
      )}
      <div
        style={{
          display: "flex",
          gap: "8px",
          padding: "0px 12px",
          marginBottom: theme.spacing(2),
          flexWrap: "wrap",
        }}
      >
        {links.slice(0, MAX_LINKS).map((link) => (
          <LinkItem link={link} />
        ))}
      </div>
    </>
  );
};
