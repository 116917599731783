import { ConfirmProvider } from "material-ui-confirm";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { CssBaseline, ThemeProvider as MUIThemeProvider } from "@mui/material";
import { ReactNode } from "react";
import { Toaster } from "../notifications";
import { theme } from "./theme";

export const MEETIN_CSS_PREFIX = "meetin-css";
// Fix for https://linear.app/meetin-collab/issue/DEV-61
// setting `key` for generating css classnames with custom prefix and setting the custom value for `data-emotion` attribute in style tags
// setting `speedy` as false to disable few optimizations in @emotion library which prevented printing style rules inside style tags
// These styles rules are needed for rewriting the rules to include container element as implemented in `useRewriteStylesheet`
const emotionCache = createCache({
  key: MEETIN_CSS_PREFIX,
  speedy: false,
});

const ThemeProvider = ({
  children,
  disableCSSBaseline,
}: {
  children: ReactNode;
  disableCSSBaseline?: boolean;
}): JSX.Element => {
  if (!disableCSSBaseline) {
    return (
      <MUIThemeProvider theme={theme}>
        <ConfirmProvider>
          <CssBaseline />
          <Toaster />

          {children}
        </ConfirmProvider>
      </MUIThemeProvider>
    );
  }
  return (
    <CacheProvider value={emotionCache}>
      <MUIThemeProvider theme={theme}>
        <ConfirmProvider>
          <Toaster />

          {children}
        </ConfirmProvider>
      </MUIThemeProvider>
    </CacheProvider>
  );
};

export default ThemeProvider;
